import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";
import FieldNameDialog from "./FieldNameDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Departure Report" : "Báo cáo tàu đến cảng - DR";
const fieldLabels_EN = {
  VoyageCode: "REF",
  ReportTypeID: "ReportTypeID",
  ReportTime: "Time",
  RobFODD: "ROB-DD:FO/DO",
  RobDODD: "",
  RobSumpDD: "LO",
  RobSysDD: "",
  RobCylDD: "",
  RobAEDD: "",
  RobHydDD: "",
  RobFWDD: "FW",
  RobFODC: "ROB-DC:FO/DO",
  RobDODC: "",
  RobSumpDC: "LO",
  RobSysDC: "",
  RobCylDC: "",
  RobAEDC: "",
  RobHydDC: "",
  RobFWDC: "FW",
  RpmCounterMEDD: "DD: M/E RPM CTR",
  RpmCounterMEDC: "DC: M/E RPM CTR",
  FlowMEDD: "FM: ME/AE/BLR/ALL",
  FlowAEDD: "",
  FlowBLRDD: "",
  FlowTempDD: "T.FM/T.ME",
  METempDD: "",
  FlowMEDC: "FM: ME/AE/BLR/ALL",
  FlowAEDC: "",
  FlowBLRDC: "",
  FlowTempDC: "T.FM/T.ME",
  METempDC: "",
  ConsFOBLRDD: "BLR(FO/DO)",
  ConsDOBLRDD: "",
  ConsFOAEDD: "CONS(NOON-DD): AE(FO/DO)",
  ConsDOAEDD: "",
  ConsCylDD: "LO",
  ConsSysDD: "",
  ConsAEDD: "",
  ConsHydDD: "",
  ConsFOMEDC: "ME (FO/DO)",
  ConsDOMEDC: "",
  ConsFOBLRDC: "BLR(FO/DO)",
  ConsDOBLRDC: "",
  ConsFOAEDC: "CONS(DD-DC): AE(FO/DO)",
  ConsDOAEDC: "",
  ConsCylDC: "LO",
  ConsSysDC: "",
  ConsAEDC: "",
  ConsHydDC: "",
  RunhAE1DD: "Rnh (BF-DD): AE1/AE2/AE3",
  RunhAE2DD: "",
  RunhAE3DD: "",
  RunhIGDD: "IG",
  RunhMEDC: "Rnh (DD-DC): ME",
  RunhAE1DC: "AE1/AE2/AE3",
  RunhAE2DC: "",
  RunhAE3DC: "",
  RunhIGDC: "IG",
  MaterialLog: "Material log"
};
const fieldLabels_VN = {
  VoyageCode: "REF",
  ReportTypeID: "ReportTypeID",
  ReportTime: "Time",
  RobFODD: "ROB-DD:FO/DO",
  RobDODD: "",
  RobSumpDD: "LO",
  RobSysDD: "",
  RobCylDD: "",
  RobAEDD: "",
  RobHydDD: "",
  RobFWDD: "FW",
  RobFODC: "ROB-DC:FO/DO",
  RobDODC: "",
  RobSumpDC: "LO",
  RobSysDC: "",
  RobCylDC: "",
  RobAEDC: "",
  RobHydDC: "",
  RobFWDC: "FW",
  RpmCounterMEDD: "DD: M/E RPM CTR",
  RpmCounterMEDC: "DC: M/E RPM CTR",
  FlowMEDD: "FM: ME/AE/BLR/ALL",
  FlowAEDD: "",
  FlowBLRDD: "",
  FlowTempDD: "T.FM/T.ME",
  METempDD: "",
  FlowMEDC: "FM: ME/AE/BLR/ALL",
  FlowAEDC: "",
  FlowBLRDC: "",
  FlowTempDC: "T.FM/T.ME",
  METempDC: "",
  ConsFOBLRDD: "BLR(FO/DO)",
  ConsDOBLRDD: "",
  ConsFOAEDD: "CONS(NOON-DD): AE(FO/DO)",
  ConsDOAEDD: "",
  ConsCylDD: "LO",
  ConsSysDD: "",
  ConsAEDD: "",
  ConsHydDD: "",
  ConsFOMEDC: "ME (FO/DO)",
  ConsDOMEDC: "",
  ConsFOBLRDC: "BLR(FO/DO)",
  ConsDOBLRDC: "",
  ConsFOAEDC: "CONS(DD-DC): AE(FO/DO)",
  ConsDOAEDC: "",
  ConsCylDC: "LO",
  ConsSysDC: "",
  ConsAEDC: "",
  ConsHydDC: "",
  RunhAE1DD: "Rnh (BF-DD): AE1/AE2/AE3",
  RunhAE2DD: "",
  RunhAE3DD: "",
  RunhIGDD: "IG",
  RunhMEDC: "Rnh (DD-DC): ME",
  RunhAE1DC: "AE1/AE2/AE3",
  RunhAE2DC: "",
  RunhAE3DC: "",
  RunhIGDC: "IG",
  MaterialLog: "Material log"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnLoadRandomSample: "Dữ liệu ngẫu nhiên",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}


/*------------------------------------FORM--------------------------------------*/
class CEDepartureReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 1,
        ReportTime: null,
        RobFODD: null,
        RobDODD: null,
        RobSumpDD: null,
        RobSysDD: null,
        RobCylDD: null,
        RobAEDD: null,
        RobHydDD: null,
        RobFWDD: null,
        RobFODC: null,
        RobDODC: null,
        RobSumpDC: null,
        RobSysDC: null,
        RobCylDC: null,
        RobAEDC: null,
        RobHydDC: null,
        RobFWDC: null,
        RpmCounterMEDD: null,
        RpmCounterMEDC: null,
        FlowMEDD: null,
        FlowAEDD: null,
        FlowBLRDD: null,
        FlowTempDD: null,
        METempDD: null,
        FlowMEDC: null,
        FlowAEDC: null,
        FlowBLRDC: null,
        FlowTempDC: null,
        METempDC: null,
        ConsFOBLRDD: null,
        ConsDOBLRDD: null,
        ConsFOAEDD: null,
        ConsDOAEDD: null,
        ConsCylDD: null,
        ConsSysDD: null,
        ConsAEDD: null,
        ConsHydDD: null,
        ConsFOMEDC: null,
        ConsDOMEDC: null,
        ConsFOBLRDC: null,
        ConsDOBLRDC: null,
        ConsFOAEDC: null,
        ConsDOAEDC: null,
        ConsCylDC: null,
        ConsSysDC: null,
        ConsAEDC: null,
        ConsHydDC: null,
        RunhAE1DD: null,
        RunhAE2DD: null,
        RunhAE3DD: null,
        RunhIGDD: null,
        RunhMEDC: null,
        RunhAE1DC: null,
        RunhAE2DC: null,
        RunhAE3DC: null,
        RunhIGDC: null,
        MaterialLog: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
    };
  }

  componentDidMount() {
    this.loadBunkerReports();
  }

  loadBunkerReports() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Search",
        ThamSo: {
          PageSize: 1000000, // TODO: need to no limit
        },
      })
      .then((response) => {
        if (Array.isArray(response.DataList)) {

          this.setState({
            dataChanged: true,
          });
        }
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }
  componentDidMount() {
    this.loadRandomSample();
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 1,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 1; //departure report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;

    this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      console.log(this.state.after, check);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.TimeDD,
              ReportStatus: reportStatus,
              ReportContent: JSON.stringify(this.state.after),
              AttachedReportID: null, // TODO: select bunker port
              ParentReportID:
                this.props.edittingRecord !== undefined
                  ? this.props.edittingRecord.ParentReportID
                  : null, // when replace an existing report
            },
          })
          .then((response) => {
            this.state.after.DailyReportID =
              response.OS_DailyReport[0].DailyReportID;
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
                reportStatus: reportStatus,
              },
              () => {
                //upload
                if (reportStatus == 1) {
                  this.doUpload();
                } else {
                  alert("Cập nhật thành công!");
                }
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "VoyageCode",
                    })
                  }
                >
                  {fieldLabels.VoyageCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ReportTime",
                    })
                  }
                >
                  {fieldLabels.ReportTime}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFODD",
                    })
                  }
                >
                  {fieldLabels.RobFODD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFODD"
                  key="RobFODD"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.RobFODD}
                  onChange={(value) => {
                    this.saveField("RobFODD", value);
                  }}
                  error={this.state.validateStatus.RobFODD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDODD"
                  key="RobDODD"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.RobDODD}
                  onChange={(value) => {
                    this.saveField("RobDODD", value);
                  }}
                  error={this.state.validateStatus.RobDODD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobSumpDD",
                    })
                  }
                >
                  {fieldLabels.RobSumpDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpDD"
                  key="RobSumpDD"
                  fullWidth

                  placeholder="Sump"
                  value={this.state.after.RobSumpDD}
                  onChange={(value) => {
                    this.saveField("RobSumpDD", value);
                  }}
                  error={this.state.validateStatus.RobSumpDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysDD"
                  key="RobSysDD"
                  fullWidth

                  placeholder="Sys"
                  value={this.state.after.RobSysDD}
                  onChange={(value) => {
                    this.saveField("RobSysDD", value);
                  }}
                  error={this.state.validateStatus.RobSysDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylDD"
                  key="RobCylDD"
                  fullWidth

                  placeholder="Cyl"
                  value={this.state.after.RobCylDD}
                  onChange={(value) => {
                    this.saveField("RobCylDD", value);
                  }}
                  error={this.state.validateStatus.RobCylDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEDD"
                  key="RobAEDD"
                  fullWidth

                  placeholder="AE"
                  value={this.state.after.RobAEDD}
                  onChange={(value) => {
                    this.saveField("RobAEDD", value);
                  }}
                  error={this.state.validateStatus.RobAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydDD"
                  key="RobHydDD"
                  fullWidth

                  placeholder="Hyd"
                  value={this.state.after.RobHydDD}
                  onChange={(value) => {
                    this.saveField("RobHydDD", value);
                  }}
                  error={this.state.validateStatus.RobHydDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFWDD",
                    })
                  }
                >
                  {fieldLabels.RobFWDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWDD"
                  key="RobFWDD"
                  fullWidth

                  value={this.state.after.RobFWDD}
                  onChange={(value) => {
                    this.saveField("RobFWDD", value);
                  }}
                  error={this.state.validateStatus.RobFWDD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFODC",
                    })
                  }
                >
                  {fieldLabels.RobFODC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFODC"
                  key="RobFODC"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.RobFODC}
                  onChange={(value) => {
                    this.saveField("RobFODC", value);
                  }}
                  error={this.state.validateStatus.RobFODC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDODC"
                  key="RobDODC"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.RobDODC}
                  onChange={(value) => {
                    this.saveField("RobDODC", value);
                  }}
                  error={this.state.validateStatus.RobDODC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobSumpDC",
                    })
                  }
                >
                  {fieldLabels.RobSumpDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpDC"
                  key="RobSumpDC"
                  fullWidth

                  placeholder="Sump"
                  value={this.state.after.RobSumpDC}
                  onChange={(value) => {
                    this.saveField("RobSumpDC", value);
                  }}
                  error={this.state.validateStatus.RobSumpDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysDC"
                  key="RobSysDC"
                  fullWidth

                  placeholder="Sys"
                  value={this.state.after.RobSysDC}
                  onChange={(value) => {
                    this.saveField("RobSysDC", value);
                  }}
                  error={this.state.validateStatus.RobSysDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylDC"
                  key="RobCylDC"
                  fullWidth

                  placeholder="Cyl"
                  value={this.state.after.RobCylDC}
                  onChange={(value) => {
                    this.saveField("RobCylDC", value);
                  }}
                  error={this.state.validateStatus.RobCylDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEDC"
                  key="RobAEDC"
                  fullWidth

                  placeholder="AE"
                  value={this.state.after.RobAEDC}
                  onChange={(value) => {
                    this.saveField("RobAEDC", value);
                  }}
                  error={this.state.validateStatus.RobAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydDC"
                  key="RobHydDC"
                  fullWidth

                  placeholder="Hyd"
                  value={this.state.after.RobHydDC}
                  onChange={(value) => {
                    this.saveField("RobHydDC", value);
                  }}
                  error={this.state.validateStatus.RobHydDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RobFWDC",
                    })
                  }
                >
                  {fieldLabels.RobFWDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWDC"
                  key="RobFWDC"
                  fullWidth

                  value={this.state.after.RobFWDC}
                  onChange={(value) => {
                    this.saveField("RobFWDC", value);
                  }}
                  error={this.state.validateStatus.RobFWDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RpmCounterMEDD",
                    })
                  }
                >
                  {fieldLabels.RpmCounterMEDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEDD"
                  key="RpmCounterMEDD"
                  fullWidth
                  value={this.state.after.RpmCounterMEDD}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEDD", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowMEDD",
                    })
                  }
                >
                  {fieldLabels.FlowMEDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEDD"
                  key="FlowMEDD"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.FlowMEDD}
                  onChange={(value) => {
                    this.saveField("FlowMEDD", value);
                  }}
                  error={this.state.validateStatus.FlowMEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEDD"
                  key="FlowAEDD"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAEDD}
                  onChange={(value) => {
                    this.saveField("FlowAEDD", value);
                  }}
                  error={this.state.validateStatus.FlowAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRDD"
                  key="FlowBLRDD"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLRDD}
                  onChange={(value) => {
                    this.saveField("FlowBLRDD", value);
                  }}
                  error={this.state.validateStatus.FlowBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3DD"
                  key="Flow3DD"
                  fullWidth
                  placeholder="ALL"
                  value={this.state.after.Flow3DD}
                  onChange={(value) => {
                    this.saveField("Flow3DD", value);
                  }}
                  error={this.state.validateStatus.Flow3DD !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowTempDD",
                    })
                  }
                >
                  {fieldLabels.FlowTempDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempDD"
                  key="FlowTempDD"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempDD}
                  onChange={(value) => {
                    this.saveField("FlowTempDD", value);
                  }}
                  error={this.state.validateStatus.FlowTempDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempDD"
                  key="METempDD"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempDD}
                  onChange={(value) => {
                    this.saveField("METempDD", value);
                  }}
                  error={this.state.validateStatus.METempDD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RpmCounterMEDC",
                    })
                  }
                >
                  {fieldLabels.RpmCounterMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEDC"
                  key="RpmCounterMEDC"
                  fullWidth
                  value={this.state.after.RpmCounterMEDC}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEDC", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowMEDC",
                    })
                  }
                >
                  {fieldLabels.FlowMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEDC"
                  key="FlowMEDC"
                  fullWidth

                  placeholder="ME"
                  value={this.state.after.FlowMEDC}
                  onChange={(value) => {
                    this.saveField("FlowMEDC", value);
                  }}
                  error={this.state.validateStatus.FlowMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEDC"
                  key="FlowAEDC"
                  fullWidth

                  placeholder="AE"
                  value={this.state.after.FlowAEDC}
                  onChange={(value) => {
                    this.saveField("FlowAEDC", value);
                  }}
                  error={this.state.validateStatus.FlowAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRDC"
                  key="FlowBLRDC"
                  fullWidth

                  placeholder="BLR"
                  value={this.state.after.FlowBLRDC}
                  onChange={(value) => {
                    this.saveField("FlowBLRDC", value);
                  }}
                  error={this.state.validateStatus.FlowBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3DC"
                  key="Flow3DC"
                  fullWidth

                  placeholder="ALL"
                  value={this.state.after.Flow3DC}
                  onChange={(value) => {
                    this.saveField("Flow3DC", value);
                  }}
                  error={this.state.validateStatus.Flow3DC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "FlowTempDC",
                    })
                  }
                >
                  {fieldLabels.FlowTempDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempDC"
                  key="FlowTempDC"
                  fullWidth

                  placeholder="F.M"
                  value={this.state.after.FlowTempDC}
                  onChange={(value) => {
                    this.saveField("FlowTempDC", value);
                  }}
                  error={this.state.validateStatus.FlowTempDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempDC"
                  key="METempDC"
                  fullWidth

                  placeholder="ME"
                  value={this.state.after.METempDC}
                  onChange={(value) => {
                    this.saveField("METempDC", value);
                  }}
                  error={this.state.validateStatus.METempDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOAEDD",
                    })
                  }
                >
                  {fieldLabels.ConsFOAEDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEDD"
                  key="ConsFOAEDD"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.ConsFOAEDD}
                  onChange={(value) => {
                    this.saveField("ConsFOAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEDD"
                  key="ConsDOAEDD"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.ConsDOAEDD}
                  onChange={(value) => {
                    this.saveField("ConsDOAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOBLRDD",
                    })
                  }
                >
                  {fieldLabels.ConsFOBLRDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRDD"
                  key="ConsFOBLRDD"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.ConsFOBLRDD}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRDD", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRDD"
                  key="ConsDOBLRDD"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.ConsDOBLRDD}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRDD", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRDD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsCylDD",
                    })
                  }
                >
                  {fieldLabels.ConsCylDD}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylDD"
                  key="ConsCylDD"
                  fullWidth

                  placeholder="Cyl"
                  value={this.state.after.ConsCylDD}
                  onChange={(value) => {
                    this.saveField("ConsCylDD", value);
                  }}
                  error={this.state.validateStatus.ConsCylDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysDD"
                  key="ConsSysDD"
                  fullWidth

                  placeholder="Sys"
                  value={this.state.after.ConsSysDD}
                  onChange={(value) => {
                    this.saveField("ConsSysDD", value);
                  }}
                  error={this.state.validateStatus.ConsSysDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEDD"
                  key="ConsAEDD"
                  fullWidth

                  placeholder="AE"
                  value={this.state.after.ConsAEDD}
                  onChange={(value) => {
                    this.saveField("ConsAEDD", value);
                  }}
                  error={this.state.validateStatus.ConsAEDD !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydDD"
                  key="ConsHydDD"
                  fullWidth

                  placeholder="Hyd"
                  value={this.state.after.ConsHydDD}
                  onChange={(value) => {
                    this.saveField("ConsHydDD", value);
                  }}
                  error={this.state.validateStatus.ConsHydDD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOAEDC",
                    })
                  }
                >
                  {fieldLabels.ConsFOAEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEDC"
                  key="ConsFOAEDC"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.ConsFOAEDC}
                  onChange={(value) => {
                    this.saveField("ConsFOAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEDC"
                  key="ConsDOAEDC"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.ConsDOAEDC}
                  onChange={(value) => {
                    this.saveField("ConsDOAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOBLRDC",
                    })
                  }
                >
                  {fieldLabels.ConsFOBLRDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRDC"
                  key="ConsFOBLRDC"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.ConsFOBLRDC}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRDC"
                  key="ConsDOBLRDC"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.ConsDOBLRDC}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsCylDC",
                    })
                  }
                >
                  {fieldLabels.ConsCylDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylDC"
                  key="ConsCylDC"
                  fullWidth

                  placeholder="Cyl"
                  value={this.state.after.ConsCylDC}
                  onChange={(value) => {
                    this.saveField("ConsCylDC", value);
                  }}
                  error={this.state.validateStatus.ConsCylDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysDC"
                  key="ConsSysDC"
                  fullWidth

                  placeholder="Sys"
                  value={this.state.after.ConsSysDC}
                  onChange={(value) => {
                    this.saveField("ConsSysDC", value);
                  }}
                  error={this.state.validateStatus.ConsSysDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEDC"
                  key="ConsAEDC"
                  fullWidth

                  placeholder="AE"
                  value={this.state.after.ConsAEDC}
                  onChange={(value) => {
                    this.saveField("ConsAEDC", value);
                  }}
                  error={this.state.validateStatus.ConsAEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydDC"
                  key="ConsHydDC"
                  fullWidth

                  placeholder="Hyd"
                  value={this.state.after.ConsHydDC}
                  onChange={(value) => {
                    this.saveField("ConsHydDC", value);
                  }}
                  error={this.state.validateStatus.ConsHydDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ConsFOMEDC",
                    })
                  }
                >
                  {fieldLabels.ConsFOMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEDC"
                  key="ConsFOMEDC"
                  fullWidth

                  placeholder="FO"
                  value={this.state.after.ConsFOMEDC}
                  onChange={(value) => {
                    this.saveField("ConsFOMEDC", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEDC"
                  key="ConsDOMEDC"
                  fullWidth

                  placeholder="DO"
                  value={this.state.after.ConsDOMEDC}
                  onChange={(value) => {
                    this.saveField("ConsDOMEDC", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhAE1DD",
                    })
                  }
                >
                  {fieldLabels.RunhAE1DD}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE1DD"
                  key="RunhAE1DD"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1DD}
                  onChange={(value) => {
                    this.saveField("RunhAE1DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE1DD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE2DD"
                  key="RunhAE2DD"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2DD}
                  onChange={(value) => {
                    this.saveField("RunhAE2DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE2DD !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE3DD"
                  key="RunhAE3DD"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3DD}
                  onChange={(value) => {
                    this.saveField("RunhAE3DD", value);
                  }}
                  error={this.state.validateStatus.RunhAE3DD !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhIGDD",
                    })
                  }
                >
                  {fieldLabels.RunhIGDD}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhIGDD"
                  key="RunhIGDD"
                  fullWidth
                  value={this.state.after.RunhIGDD}
                  onChange={(value) => {
                    this.saveField("RunhIGDD", value);
                  }}
                  error={this.state.validateStatus.RunhIGDD !== undefined}
                />
              </Grid>
              <Grid item xs={13}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhMEDC",
                    })
                  }
                >
                  {fieldLabels.RunhMEDC}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhMEDC"
                  key="RunhMEDC"
                  fullWidth

                  value={this.state.after.RunhMEDC}
                  onChange={(value) => {
                    this.saveField("RunhMEDC", value);
                  }}
                  error={this.state.validateStatus.RunhMEDC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhAE1DC",
                    })
                  }
                >
                  {fieldLabels.RunhAE1DC}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE1DC"
                  key="RunhAE1DC"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1DC}
                  onChange={(value) => {
                    this.saveField("RunhAE1DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE1DC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE2DC"
                  key="RunhAE2DC"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2DC}
                  onChange={(value) => {
                    this.saveField("RunhAE2DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE2DC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhAE3DC"
                  key="RunhAE3DC"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3DC}
                  onChange={(value) => {
                    this.saveField("RunhAE3DC", value);
                  }}
                  error={this.state.validateStatus.RunhAE3DC !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "RunhIGDC",
                    })
                  }
                >
                  {fieldLabels.RunhIGDC}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="RunhIGDC"
                  key="RunhIGDC"
                  fullWidth
                  value={this.state.after.RunhIGDC}
                  onChange={(value) => {
                    this.saveField("RunhIGDC", value);
                  }}
                  error={this.state.validateStatus.RunhIGDC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>H-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaterialLog",
                    })
                  }
                >
                  {fieldLabels.MaterialLog}
                </FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="MaterialLog"
                  key="MaterialLog"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.MaterialLog}
                  onChange={(event) => {
                    this.saveField("MaterialLog", event.target.value);
                  }}
                  error={this.state.validateStatus.MaterialLog !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="CEDepartureReportEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CEDepartureReportEditDialog
);
