/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import {
  filterStyles,
  loadDataError,
  handleServerError,
  loadGridColumns,
  ReactDataGrid_i18n,
  showError,
  formStyles,
} from "../components/common";
import ReportFormFieldEditDialog from "./ReportFormFieldEditDialog";
import TableFieldEditDialog from "./TableFieldEditDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const columns_filter = [
  {
    name: "FieldName",
    header: "Tên tiêu chí",
    headerAlign: "center",
    defaultWidth: 220,
  },
  {
    name: "Label",
    header: "Nhãn",
    headerAlign: "center",
    defaultWidth: 220,
  },
  {
    name: "DataType",
    header: "Kiểu dữ liệu",
    headerAlign: "center",
    defaultWidth: 150,
  },
  {
    name: "SkipValue",
    header: "Bỏ qua",
    headerAlign: "center",
    defaultWidth: 100,
  },
  {
    name: "Compare",
    header: "So sánh",
    headerAlign: "center",
    defaultWidth: 120,
  },
  {
    name: "SourceName",
    header: "Danh mục",
    headerAlign: "center",
    defaultWidth: 220,
  },
];

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["ReportFormCode", "ReportTitle"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

class ReportFormEditDialog extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    var defines = props.edittingRecord
      ? JSON.parse(props.edittingRecord.ReportFormConfig)
      : {
          FilterList: [], //danh sach tieu chi loc
          ReportDataName: "",
          ReportDataTables: ["ThamSo"],
        };

    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {
        Report_Data_Defines: [],
      },
      after: {
        ReportFormID: 0,
        ReportFormCode: "",
        ReportTitle: "",
        ReportDescription: "",
        ReportDataName: "",
        ReportDataTables: "",
        ...props.edittingRecord,
        ReportFormConfig: defines,
      },
      DataTableName: "<Chưa có>",
      NewDataTable: "",
      DataTables: [], //danh sach truong` cua table duoc chon
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  loadDanhMuc() {
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "Report_Data_Defines",
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
          },
          () => {
            if (this.props.edittingRecord !== undefined) {
              this.loadDataSetFields(); //load de edit
            }
          }
        );
      })
      .catch((error) => {
        showError(error);
      });
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value, callback) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    if (callback) {
      this.setState(s, () => callback());
    } else {
      this.setState(s);
    }
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  loadDataSetFields() {
    //load danh sach fields theo dataset duoc chon
    var dataSet = this.state.after.ReportFormConfig.ReportDataName;
    if (!myLib.isEmpty(dataSet)) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Report_Data_Defines_Get",
          ThamSo: {
            ReportDataName: dataSet,
          },
        })
        .then((response) => {
          //xu ly response
          if (
            response.Report_Data_Defines &&
            response.Report_Data_Defines.length > 0
          ) {
            this.doChonKhoData(response.Report_Data_Defines[0]);
          }
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  doChonKhoData(dataSet) {
    //thuc hien lay thong tin dinh nghia tu kho du lieu de chuyen thanh tham so report
    var define = JSON.parse(dataSet.ReportFilterDefine);
    var fields = define.FilterFields; //chua danh sach truong thong tin
    var list = [];
    fields.map((item) => {
      //convert tham so cua kho du lieu thanh tham so report
      var fieldName = item.FieldName;
      var dataType = item.DataType;
      var skipValue = item.SkipValue;
      var compare = item.Compare;
      var thamso = {};
      thamso.FieldName = fieldName;
      thamso.DataType = dataType;
      thamso.Label = fieldName;
      thamso.SourceName = dataType.indexOf("Array") >= 0 ? "XXXX" : "";
      thamso.SourceLabel = "";
      thamso.SourceValue = "";
      thamso.SkipValue = skipValue;
      thamso.LabelWidth = 4;
      thamso.FieldInputWidth = 8;
      thamso.Visible = true;
      thamso.DefaultValue = null;
      list.push(thamso);
    });
    //danh sach tieu chi tim kiem
    if(this.props.edittingRecord == undefined) {
      this.state.after.ReportFormConfig.FilterList = list;
    }
    //danh sach data table
    var dataSet = define.DataSet;
    var listTables = ["ThamSo"];
    dataSet.map((item) => {
      listTables.push(
        item.DataTable.substring(
          (
            "Report_DataTable_" +
            this.state.after.ReportFormConfig.ReportDataName
          ).length + 1
        )
      );
    });
    this.setState({ saving: false, DataTables: listTables });
  }
  getDataToSave = () => {
    //tra ve du lieu theo cau truc table
    return null;
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ReportFormID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_Report_Form_Defines_Update",
            ThamSo: this.state.after,
          })
          .then((response) => {
            var before = {};
            var after = {
              ...this.state.after,
              ReportFormID: response.ReportFormID,
            };
            Object.assign(before, after);
            this.setState({
              before: before,
              after: after,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, after);
            }
            alert("Cập nhật thành công!");
            this.props.close(after);
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  //create table lien quan
  doCreateTables = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.ReportFormID > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_Report_Data_Defines_CreateTable",
            ThamSo: this.state.after,
          })
          .then((response) => {
            var before = {};
            var after = {
              ...this.state.after,
              ReportFormID: response.ReportFormID,
            };
            Object.assign(before, after);
            this.setState({
              before: before,
              after: after,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            alert("Cập nhật thành công!");
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  //cap nhat tieu chi tim kiem
  updateFilterField(field) {
    var find = this.state.after.ReportFormConfig.FilterList.find(
      (item) => item.FieldName == field.FieldName
    );
    if (find) {
      myLib.assign(field, find, [
        "FieldName",
        "DataType",
        "SkipValue",
        "Compare",
        "Label",
        "SourceName",
        "SourceLabel",
        "SourceValue",
        "LabelWidth",
        "FieldInputWidth",
        "Visible",
        "DefaultValue",
      ]);
    } else {
      this.state.after.ReportFormConfig.FilterList.push(field);
    }
    //hien thi lai
    this.setState({ saving: false });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Mẫu báo cáo</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Mã số:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  disabled
                  value={this.state.after.ReportFormID}
                />
              </Grid>
              <Grid item xs={16}></Grid>
              <Grid item xs={4}>
                <FormLabel>Ký hiệu báo cáo (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportFormCode}
                  onChange={(event) => {
                    this.saveField("ReportFormCode", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportFormCode !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Tên báo cáo:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.ReportTitle}
                  onChange={(event) => {
                    this.saveField("ReportTitle", event.target.value);
                  }}
                  error={this.state.validateStatus.ReportTitle !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Mô tả:</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.ReportDescription}
                  onChange={(event) => {
                    this.saveField("ReportDescription", event.target.value);
                  }}
                  error={
                    this.state.validateStatus.ReportDescription !== undefined
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Dùng kho dữ liệu(*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                {this.state.DanhMuc.Report_Data_Defines.length == 0 ? (
                  <OutlinedInput
                    fullWidth
                    value={this.state.after.ReportDataName}
                    onChange={(event) =>
                      this.saveField("ReportDataName", event.target.value)
                    }
                  />
                ) : (
                  <SelectFilter
                    id="cboQG_T"
                    fullWidth
                    backspaceRemovesValue
                    options={this.state.DanhMuc.Report_Data_Defines}
                    placeholder=""
                    styles={filterStyles}
                    menuPosition={"fixed"}
                    value={{
                      ReportDataName:
                        this.state.after.ReportFormConfig.ReportDataName,
                    }}
                    getOptionLabel={(item) => item.ReportDataName}
                    getOptionValue={(item) => item.ReportDataName}
                    isClearable
                    onChange={(item) => {
                      this.state.after.ReportDataID = item ? item.ReportDataID : 0;
                      this.state.after.ReportFormConfig.ReportDataName = item
                        ? item.ReportDataName
                        : null;
                      this.setState({ saving: false }, () =>
                        this.loadDataSetFields()
                      );
                    }}
                    error={
                      this.state.validateStatus.ReportDataName !== undefined
                    }
                  ></SelectFilter>
                )}
              </Grid>
              <Grid item xs={24}>
                <FormLabel>Danh sách tiêu chí lọc: </FormLabel>
              </Grid>
              <Grid item xs={20}>
                <ReactDataGrid
                  style={{ height: 200, fontSize: 12 }}
                  showZebraRows={true}
                  i18n={ReactDataGrid_i18n}
                  columns={columns_filter}
                  pagination={false}
                  multiSelect={false}
                  showHoverRows={false}
                  checkboxColumn={{
                    render: (props) => (
                      <input
                        type="checkbox"
                        readOnly
                        checked={props.rowSelected}
                      ></input>
                    ),
                  }}
                  dataSource={this.state.after.ReportFormConfig.FilterList}
                  idProperty="FieldName"
                  headerHeight={30}
                  rowHeight={30}
                  allowUnsort={true}
                  skipLoadOnMount={true}
                  enableSelection={true}
                  onSelectionChange={({ data, selected, unselected }) => {
                    var now = new Date().getTime();
                    var diff = now - this.lastClick;
                    if (diff > 300) {
                      this.setState({ rowSelected_1: data });
                    } else {
                      //double click
                      this.setState({
                        rowSelected_1: data,
                      });
                    }
                    this.lastClick = now;
                  }}
                ></ReactDataGrid>
              </Grid>
              <Grid item xs={4}>
                <Button
                  disabled={
                    this.state.saving || this.state.rowSelected_1 == undefined
                  }
                  onClick={() => {
                    this.setState({
                      open_edit_filter_field: true,
                      edittingRecord: this.state.rowSelected_1,
                    });
                  }}
                  variant="contained"
                >
                  Sửa
                </Button>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Sử dụng dữ liệu(*):</FormLabel>
              </Grid>

              <Grid item xs={2}>
                {this.state.DataTables.map((item) => (
                  <FormControlLabel
                    label={item}
                    labelPlacement="end"
                    fullWidth
                    control={
                      <Checkbox
                        size="medium"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                        checked={this.state.after.ReportFormConfig.ReportDataTables.find(
                          (d) => d == item
                        )}
                        onChange={(event) => {
                          var findIndex =
                            this.state.after.ReportFormConfig.ReportDataTables.findIndex(
                              (d) => d == item
                            );
                          if (event.target.checked) {
                            if (findIndex < 0) {
                              this.state.after.ReportFormConfig.ReportDataTables.push(
                                item
                              );
                            }
                          } else {
                            if (findIndex >= 0) {
                              this.state.after.ReportFormConfig.ReportDataTables.splice(
                                findIndex,
                                1
                              );
                            }
                          }
                        }}
                      />
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            disabled={this.state.saving || this.state.after.ReportFormID == 0}
            onClick={() => {
              this.doCreateTables();
            }}
            variant="contained"
          >
            Create tables
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_edit_filter_field ? (
          <ReportFormFieldEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={(record) => {
              this.setState({
                open_edit_filter_field: false,
                edittingRecord: undefined,
              });
              if (record) {
                this.updateFilterField(record);
              }
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ReportFormEditDialog
);
