import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import SyncIcon from "@mui/icons-material/Sync";
import MySelect from "../components/MySelect";
import LoggerDataDialog from "./LoggerDataDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Shifting - Start Report"
  : "Báo cáo tàu di chuyển";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time:",
      TimeSS: "Time SS:",
      TimeSN: "Time Noon:",
      PilotTime: "Pilot on:",
      PilotTime2: "Pilot Of:",
      Position: "Position:",
      VoyCode: "VOY No.",
      VoyTime: "Finished at",
      VoyRobFO: "ROB",
      VoyRobDO: "",
      ReceiveDOFO: "Receive:",
      Tugs: "Tug:",
      TugTime: "Hrs:",
      TugPower: "Power:",
      PortName: "From:",
      PortName2: "To:",
      ForeDraft: "Draft F/A",
      AftDraft: "",
      CargoMT: "Cargo:",
      ETA: "E.T.A",
      Distance: "Distance:",
      AvgSpeed: "Speed:",
      DisToGo: "Distance:",
      RobSS: "ROB - SS:",
      RobFOSS: "FO",
      RobDOSS: "DO",
      RobFWSS: "FW",

      RobSN: "ROB - N:",
      RobFOSN: "FO",
      RobDOSN: "DO",
      RobFWSN: "FW",
      RpmCounterMESS: "BF - SS",
      FlowMESS: "F.M:",
      FlowAESS: "",
      FlowBLRSS: "",
      Flow3SS: "",
      FlowTempSS: "T.FM/ME:",
      METempSS: "T.ME:",
      RpmCounterMESN: "SS - N:",
      FlowMESN: "F.M:",
      FlowAESN: "",
      FlowBLRSN: "",
      Flow3SN: "",
      FlowTempSN: "T.FM/ME:",
      METempSN: "",
      ConsFOAESS: "BF - SS:",
      ConsDOAESS: "",
      ConsFOBLRSS: "BLR",
      ConsDOBLRSS: "",
      ConsCylSS: "LO:",
      ConsSysSS: "",
      ConsAESS: "",
      ConsHydSS: "",
      FWPureSS: "FW Pur",
      ConsFOMESN: "SS - N:",
      ConsDOMESN: "DO/ME",
      ConsFOAESN: "AE",
      ConsDOAESN: "AE/DO",
      ConsFOBLRSN: "BLR",
      ConsDOBLRSN: "BLR/DO",
      FWPureSN: "FW Pur",
      ConsCylSN: "LO:",
      ConsSysSN: "",
      ConsAESN: "",
      ConsHydSN: "",

      RpmMESN: "RPM ME:",
      BCASN: "Pr:",
      TaSN: "Ta:",
      LoadSN: "Hand load:",
      TminSN: "Tmin:",
      TmaxSN: "Tmax:",
      PaMESN: "Pa:",
      AirMESN: "Air drop:",
      TerSN: "Ter:",
      SlipSN: "Slip:",
      RunhMESS: "ME",
      RunhAE1SS: "BF - SS:",
      RunhAE2SS: "",
      RunhAE3SS: "",
      RunhIGSS: "IG",
      RunhBLRSS: "BLR",
      RunhMESN: "ME",
      RunhAE1SN: "SS - N:",
      RunhAE2SN: "",
      RunhAE3SN: "",
      RunhIGSN: "IG",
      RunhBLRSN: "BLR",
      Remark: "Remark",
      Bunker: "B/R file",
    }
  : {
      VoyageCode: "REF",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeSS: "Time SS",
      TimeSN: "Time Noon",
      Position: "Position",
      Tugs: "Tugs used/hours/power",
      TugTime: "",
      TugPower: "",
      PortName: "From",
      PortName2: "To",
      VoyCode: "VOY No.",
      VoyTime: "Finished at",
      VoyRobFO: "ROB",
      VoyRobDO: "",
      ForeDraft: "Draft F/A",
      AftDraft: "",
      CargoMT: "Cargo",
      ETA: "ETA",
      AvgSpeed: "SPD",
      Distance: "Distance",
      DisToGo: "DTG",
      RobSS: "ROB-SS:",
      RobFOSS: "FO",
      RobDOSS: "DO",
      RobFWSS: "FW",
      RobSN: "ROB-Noon:",
      RobFOSN: "FO",
      RobDOSN: "DO",
      RobFWSN: "FW",
      RpmCounterMESS: "M/E RPM CTR - SS",
      FlowMESS: "F.M ME/AE/BLR/ALL",
      FlowAESS: "",
      FlowBLRSS: "",
      Flow3SS: "",
      FlowTempSS: "Temp F.M/ME",
      METempSS: "",
      RpmCounterMESN: "M/E RPM CTR - Noon",
      FlowMESN: "F.M ME/AE/BLR/ALL",
      FlowAESN: "",
      FlowBLRSN: "",
      Flow3SN: "",
      FlowTempSN: "Temp F.M/ME",
      METempSN: "",
      ConsFOAESS: "Cons(BF-S.ST): AE",
      ConsDOAESS: "",
      ConsFOBLRSS: "BLR",
      ConsDOBLRSS: "",
      ConsCylSS: "LO",
      ConsSysSS: "",
      ConsAESS: "",
      ConsHydSS: "",
      FWPureSS: "FW Pur",
      ConsFOMESN: "Cons(S.ST-N):ME",
      ConsDOMESN: "DO/ME",
      ConsFOAESN: "AE",
      ConsDOAESN: "AE/DO",
      ConsFOBLRSN: "BLR",
      ConsDOBLRSN: "BLR/DO",
      ConsCylSN: "LO",
      ConsSysSN: "",
      ConsAESN: "",
      ConsHydSN: "",
      FWPureSN: "FW Pur",
      RunhMESS: "ME",
      RunhAE1SS: "Running.Hr(BF-S.ST): AE1/AE2/AE3",
      RunhAE2SS: "",
      RunhAE3SS: "",
      RunhIGSS: "IG",
      RunhBLRSS: "BLR",
      RunhMESN: "ME",
      RunhAE1SN: "Running.Hr(S.ST-N): ME/AE1/AE2/AE3",
      RunhAE2SN: "",
      RunhAE3SN: "",
      RunhIGSN: "IG",
      RunhBLRSN: "BLR",
      Remark: "Remark",
    };
const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.ShiftingStartReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }
  if (Object.keys(ret).length == 0) {
    //kiem tra thoi gian
    if (new Date(data.TimeSN).getTime() < new Date(data.TimeSS).getTime()) {
      ret.TimeSN = true;
    }
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class ShiftingStartReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 9,
        ReportTime: null,
        TimeSS: null,
        TimeSN: null,
        Position: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        PortName: null,
        PortName2: null,
        VoyCode: null,
        VoyTime: null,
        VoyRobFO: null,
        VoyRobDO: null,
        ForeDraft: null,
        AftDraft: null,
        CargoMT: null,
        CargoTon: null,
        CargoName: null,
        DisToGo: null,
        RobFOSN: null,
        RobDOSN: null,
        RobLOSN: null,
        RobFWSN: null,
        RpmCounterMESS: null,
        FlowMESS: null,
        FlowAESS: null,
        FlowBLRSS: null,
        Flow3SS: null,
        Flow2SS: null,
        FlowTempSS: null,
        METempSS: null,
        RpmCounterMESN: null,
        FlowMESN: null,
        FlowAESN: null,
        FlowBLRSN: null,
        Flow3SN: null,
        Flow2SN: null,
        FlowTempSN: null,
        METempSN: null,
        ConsFOMESS: null,
        ConsDOMESS: null,
        ConsFOAESS: null,
        ConsDOAESS: null,
        ConsFOBLRSS: null,
        ConsDOBLRSS: null,
        ConsCylSS: null,
        ConsSysSS: null,
        ConsAESS: null,
        ConsHydSS: null,
        FWPureSS: null,
        ConsFOMESN: null,
        ConsDOMESN: null,
        ConsFOAESN: null,
        ConsDOAESN: null,
        ConsFOBLRSN: null,
        ConsDOBLRSN: null,
        ConsCylSN: null,
        ConsSysSN: null,
        ConsAESN: null,
        ConsHydSN: null,
        FWPureSN: null,
        RunhMESS: null,
        RunhAE1SS: null,
        RunhAE2SS: null,
        RunhAE3SS: null,
        RunhIGSS: null,
        RunhBLRSS: null,
        RunhMESN: null,
        RunhAE1SN: null,
        RunhAE2SN: null,
        RunhAE3SN: null,
        RunhIGSN: null,
        RunhBLRSN: null,
        Remark: null,
        ReceiveDOFO: [this.newRecord()],
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined
          ? []
          : props.edittingRecord.Attachs
          ? props.edittingRecord.Attachs
          : [], // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.SS")),
      open_logger_data_dialog: false, //hien thi form hien thi data tu logger
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tra ve luong nhap vao
  getReceive(name) {
    var num = 0;
    this.state.after.ReceiveDOFO.map(
      (item) => (num += item.ReceiveDate && item[name] ? item[name] : 0)
    );
    return isNaN(num) ? 0 : num;
  }
  //tinh lai ROB
  calculateROB() {
    var s = this.state;
    let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
    if (!myLib.isEmpty(robFO)) {
      s.after.RobFOSS =
        robFO -
        this.getConsValue(s.after.ConsFOAESS) -
        this.getConsValue(s.after.ConsFOBLRSS) +
        this.getReceive("FO");

      s.after.RobFOSN =
        s.after.RobFOSS -
        this.getConsValue(s.after.ConsFOMESN) -
        this.getConsValue(s.after.ConsFOAESN) -
        this.getConsValue(s.after.ConsFOBLRSN);
    }
    let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
    if (!myLib.isEmpty(robDO)) {
      s.after.RobDOSS =
        robDO -
        this.getConsValue(s.after.ConsDOAESS) -
        this.getConsValue(s.after.ConsDOPPSS) -
        this.getConsValue(s.after.ConsDOIGSS) -
        this.getConsValue(s.after.ConsDOBLRSS) +
        this.getReceive("DO");

      s.after.RobDOSN =
        s.after.RobDOSS -
        this.getConsValue(s.after.ConsDOMESN) -
        this.getConsValue(s.after.ConsDOAESN) -
        this.getConsValue(s.after.ConsDOPPSN) -
        this.getConsValue(s.after.ConsDOIGSN) -
        this.getConsValue(s.after.ConsDOBLRSN);
    }
    if (!myLib.isEmpty(s.after.RobFOSS)) {
      s.after.RobFOSN =
        s.after.RobFOSS -
        this.getConsValue(s.after.ConsFOMESN) -
        this.getConsValue(s.after.ConsFOAESN) -
        this.getConsValue(s.after.ConsFOBLRSN);
    }
    if (!myLib.isEmpty(s.after.RobDOSS)) {
      s.after.RobDOSN =
        s.after.RobDOSS -
        this.getConsValue(s.after.ConsDOMESN) -
        this.getConsValue(s.after.ConsDOAESN) -
        this.getConsValue(s.after.ConsDOPPSN) -
        this.getConsValue(s.after.ConsDOIGSN) -
        this.getConsValue(s.after.ConsDOBLRSN);
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.SS");
      if (json && json !== "") {
        var draft = JSON.parse(json);
        console.log(draft);
        if (draft.VoyageCode == this.state.after.VoyageCode) {
          this.state.after = draft;
          this.state.after.ReportID = uuid();
          this.state.after.DailyReportID = 0; //dam bao la report moi

          this.setState({ saving: false });
        }
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 9,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 9; //shifting report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            report.VoyCode = null;
            report.VoyTime = null;
            report.VoyRobDO = report.VoyRobFO = null;
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
            report.TimeSN = myLib.calculateNoon(new Date(report.TimeSS));
          } else {
            report.TimeSN = myLib.calculateNoon(new Date(report.TimeSS));
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value
    s.after[fieldName] = value;

    switch (fieldName) {
      case "TimeSS":
        if (value) {
          s.after.TimeSN = myLib.calculateNoon(value);
        }
        break;
      case "VoyRobFO":
      case "ConsFOAESS":
      case "ConsFOBLRSS":
        let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
        if (!myLib.isEmpty(robFO)) {
          s.after.RobFOSS =
            robFO -
            this.getConsValue(s.after.ConsFOAESS) -
            this.getConsValue(s.after.ConsFOBLRSS) +
            this.getReceive("FO");

          s.after.RobFOSN =
            s.after.RobFOSS -
            this.getConsValue(s.after.ConsFOMESN) -
            this.getConsValue(s.after.ConsFOAESN) -
            this.getConsValue(s.after.ConsFOBLRSN);
        }
        break;
      case "VoyRobDO":
      case "ConsDOAESS":
      case "ConsDOBLRSS":
      case "ConsDOPPSS":
      case "ConsDOIGSS":
        let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
        if (!myLib.isEmpty(robDO)) {
          s.after.RobDOSS =
            robDO -
            this.getConsValue(s.after.ConsDOAESS) -
            this.getConsValue(s.after.ConsDOPPSS) -
            this.getConsValue(s.after.ConsDOIGSS) -
            this.getConsValue(s.after.ConsDOBLRSS) +
            this.getReceive("DO");

          s.after.RobDOSN =
            s.after.RobDOSS -
            this.getConsValue(s.after.ConsDOMESN) -
            this.getConsValue(s.after.ConsDOAESN) -
            this.getConsValue(s.after.ConsDOPPSN) -
            this.getConsValue(s.after.ConsDOIGSN) -
            this.getConsValue(s.after.ConsDOBLRSN);
        }
        break;
    }
    switch (fieldName) {
      case "ConsFOMESN":
      case "ConsFOAESN":
      case "ConsFOBLRSN":
        if (!myLib.isEmpty(s.after.RobFOSS)) {
          s.after.RobFOSN =
            s.after.RobFOSS -
            this.getConsValue(s.after.ConsFOMESN) -
            this.getConsValue(s.after.ConsFOAESN) -
            this.getConsValue(s.after.ConsFOBLRSN);
        }
        break;
      case "ConsDOMESN":
      case "ConsDOAESN":
      case "ConsDOBLRSN":
      case "ConsDOPPSN":
      case "ConsDOIGSN":
        if (!myLib.isEmpty(s.after.RobDOSS)) {
          s.after.RobDOSN =
            s.after.RobDOSS -
            this.getConsValue(s.after.ConsDOMESN) -
            this.getConsValue(s.after.ConsDOAESN) -
            this.getConsValue(s.after.ConsDOPPSN) -
            this.getConsValue(s.after.ConsDOIGSN) -
            this.getConsValue(s.after.ConsDOBLRSN);
        }
        break;
      case "RpmCounterMESN":
      case "RunhMESN":
      case "Distance":
        //tinh slip, rpm
        if (true) {
          let counter1 = s.after.RpmCounterMESS;
          let counter2 = s.after.RpmCounterMESN;
          if (counter2) {
            let runh = s.after.RunhMESN;
            if (runh) {
              s.after.RpmMESN =
                Math.round(((counter2 - counter1) * 10) / (runh * 60)) / 10;
            }

            if (this.state.FMEnable.SlipH && s.after.Distance) {
              let distance2 = 0;
              let distance1 = s.after.Distance;
              let dis = distance1 - distance2;
              if (!isNaN(dis) && dis && counter1 !== counter2) {
                //quang duong ly thuyet
                let range =
                  ((counter2 - counter1) * this.state.FMEnable.SlipH) / 1852.0;
                s.after.SlipSN = myLib.round(((range - dis) / range) * 100, 2);
              }
            }
          }
        }
        //tinh toc do trung binh
        if (s.after.Distance && s.after.RunhMESN) {
          let dis = s.after.Distance;
          let hours = s.after.RunhMESN;
          if (hours) {
            s.after.AvgSpeed = Math.round((dis * 10) / hours) / 10;
          }
        }
        break;
    }
    s.dataChanged = true;

    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;
    if (field.indexOf("FO") >= 0 || field == "ReceiveDate") {
      console.log(record);
      this.saveField("ConsFOAESS", s.after.ConsFOAESS);
    } else if (field.indexOf("DO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsDOAESS", s.after.ConsDOAESS);
    } else this.setState(s);
  };
  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      LOAE: null,
      LOHyd: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };
  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.SS", JSON.stringify(this.state.after));
      }
      var check = validate_Data(this.state.after);
      console.log(this.state.after, check);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });

        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeSS,
                ReportDataTime2: this.state.after.TimeSN,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-SS";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {
                <Button
                  color="success"
                  variant="contained"
                  disabled={myLib.isEmpty(this.state.after.TimeSS)}
                  onClick={() =>
                    this.setState({ open_logger_data_dialog: true })
                  }
                >
                  {buttonLabels.btnLogger}
                </Button>
              }
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeSS)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeSS}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeSS"
                  key="TimeSS"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeSS}
                  onChange={(value) => {
                    this.saveField("TimeSS", value);
                  }}
                  error={this.state.validateStatus.TimeSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}></Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime"
                  key="PilotTime"
                  fullWidth
                  value={this.state.after.PilotTime}
                  onChange={(value) => {
                    this.saveField("PilotTime", value);
                  }}
                  error={this.state.validateStatus.PilotTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime2}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime2"
                  key="PilotTime2"
                  fullWidth
                  value={this.state.after.PilotTime2}
                  onChange={(value) => {
                    this.saveField("PilotTime2", value);
                  }}
                  error={this.state.validateStatus.PilotTime2 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  placeholder="From"
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField("PortName", event.target.value);
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName2}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName2"
                  key="PortName2"
                  fullWidth
                  placeholder="to"
                  value={this.state.after.PortName2}
                  onChange={(event) => {
                    this.saveField("PortName2", event.target.value);
                  }}
                  error={this.state.validateStatus.PortName2 !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Tugs}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder="hours"
                  value={this.state.after.TugTime}
                  onChange={(value) => {
                    this.saveField("TugTime", value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugPower}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  placeholder="power"
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETA}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETA"
                  key="ETA"
                  fullWidth
                  value={this.state.after.ETA}
                  onChange={(value) => {
                    this.saveField("ETA", value);
                  }}
                  error={this.state.validateStatus.ETA !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.after.ReceiveDOFO.map((item, index) => (
              <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
                {index == 0 ? (
                  <>
                    <Grid item xs={2}>
                      <FormLabel
                        className={classes.quantrong}
                        onClick={() =>
                          this.setState({
                            show_thongtin_field: true,
                            Show_FieldName: "ReceiveDOFO",
                          })
                        }
                      >
                        {fieldLabels.ReceiveDOFO}
                      </FormLabel>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={2}></Grid>
                )}

                <Grid item xs={4}>
                  <MyDateEditor
                    id="ReceiveDOFODate"
                    key="ReceiveDOFODate"
                    fullWidth
                    value={item.ReceiveDate}
                    onChange={(value) => {
                      this.saveRecord(item, index, "ReceiveDate", value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFO"
                    key="ReceiveFO"
                    fullWidth
                    placeholder="FO"
                    value={item.FO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FO", value);
                    }}
                    error={this.state.validateStatus.ReceiveFO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveDO"
                    key="ReceiveDO"
                    fullWidth
                    placeholder="DO"
                    value={item.DO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "DO", value);
                    }}
                    error={this.state.validateStatus.ReceiveDO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOCyl"
                    key="ReceiveLOCyl"
                    fullWidth
                    placeholder="LO-Cyl"
                    decimalScale={0}
                    value={item.LOCyl}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOCyl", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOCyl !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOSys"
                    key="ReceiveLOSys"
                    fullWidth
                    placeholder="LO-Sys"
                    decimalScale={0}
                    value={item.LOSys}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOSys", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOSys !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOAE"
                    key="ReceiveLOAE"
                    fullWidth
                    placeholder="LO-AE"
                    decimalScale={0}
                    value={item.LOAE}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOAE", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOAE !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOHyd"
                    key="ReceiveLOHyd"
                    fullWidth
                    placeholder="LO-Hyd"
                    decimalScale={0}
                    value={item.LOHyd}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOHyd", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOHyd !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFW"
                    key="ReceiveFW"
                    fullWidth
                    placeholder="FW"
                    decimalScale={0}
                    value={item.FW}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FW", value);
                    }}
                    error={this.state.validateStatus.ReceiveFW !== undefined}
                  />
                </Grid>

                {index == 0 ? (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.addRecord();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.removeRecord(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder="Fore Draft"
                  value={this.state.after.ForeDraft}
                  onChange={(value) => {
                    this.saveField("ForeDraft", value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder="Aft. Draft"
                  value={this.state.after.AftDraft}
                  onChange={(value) => {
                    this.saveField("AftDraft", value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.CargoMT}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoTon"
                  key="CargoTon"
                  fullWidth
                  placeholder="MTs"
                  value={this.state.after.CargoTon}
                  onChange={(value) => {
                    this.saveField("CargoTon", value);
                  }}
                  error={this.state.validateStatus.CargoTon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="CargoName"
                  key="CargoName"
                  fullWidth
                  placeholder="name"
                  value={this.state.after.CargoName}
                  onChange={(event) => {
                    this.saveField("CargoName", event.target.value);
                  }}
                  error={this.state.validateStatus.CargoName !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.DisToGo}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Distance"
                  key="Distance"
                  fullWidth
                  placeholder="BF-N"
                  value={this.state.after.Distance}
                  onChange={(value) => {
                    this.saveField("Distance", value);
                  }}
                  error={this.state.validateStatus.Distance !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AvgSpeed}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AvgSpeed"
                  key="AvgSpeed"
                  fullWidth
                  value={this.state.after.AvgSpeed}
                  onChange={(value) => {
                    this.saveField("AvgSpeed", value);
                  }}
                  error={this.state.validateStatus.AvgSpeed !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMESS"
                  key="RpmCounterMESS"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMESS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMESS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMESS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMESS"
                  key="FlowMESS"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMESS}
                  onChange={(value) => {
                    this.saveField("FlowMESS", value);
                  }}
                  error={this.state.validateStatus.FlowMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAESS"
                  key="FlowAESS"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAESS}
                  onChange={(value) => {
                    this.saveField("FlowAESS", value);
                  }}
                  error={this.state.validateStatus.FlowAESS !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2SS"
                      key="Flow2SS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2SS}
                      onChange={(value) => {
                        this.saveField("Flow2SS", value);
                      }}
                      error={this.state.validateStatus.Flow2SS !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSS"
                      key="FlowBLRSS"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRSS}
                      onChange={(value) => {
                        this.saveField("FlowBLRSS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSS !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSS"
                      key="FlowBLRSS"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRSS}
                      onChange={(value) => {
                        this.saveField("FlowBLRSS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSS !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3SS"
                      key="Flow3SS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3SS}
                      onChange={(value) => {
                        this.saveField("Flow3SS", value);
                      }}
                      error={this.state.validateStatus.Flow3SS !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempSS"
                  key="FlowTempSS"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempSS}
                  onChange={(value) => {
                    this.saveField("FlowTempSS", value);
                  }}
                  error={this.state.validateStatus.FlowTempSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempSS"
                  key="METempSS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempSS}
                  onChange={(value) => {
                    this.saveField("METempSS", value);
                  }}
                  error={this.state.validateStatus.METempSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.RpmCounterMESN}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMESN"
                  key="RpmCounterMESN"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMESN}
                  onChange={(value) => {
                    this.saveField("RpmCounterMESN", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMESN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMESN"
                  key="FlowMESN"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMESN}
                  onChange={(value) => {
                    this.saveField("FlowMESN", value);
                  }}
                  error={this.state.validateStatus.FlowMESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAESN"
                  key="FlowAESN"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAESN}
                  onChange={(value) => {
                    this.saveField("FlowAESN", value);
                  }}
                  error={this.state.validateStatus.FlowAESN !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2SN"
                      key="Flow2SN"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2SN}
                      onChange={(value) => {
                        this.saveField("Flow2SN", value);
                      }}
                      error={this.state.validateStatus.Flow2SN !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSN"
                      key="FlowBLRSN"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRSN}
                      onChange={(value) => {
                        this.saveField("FlowBLRSN", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSN !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSN"
                      key="FlowBLRSN"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRSN}
                      onChange={(value) => {
                        this.saveField("FlowBLRSN", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSN !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3SN"
                      key="Flow3SN"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3SN}
                      onChange={(value) => {
                        this.saveField("Flow3SN", value);
                      }}
                      error={this.state.validateStatus.Flow3SN !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempSN"
                  key="FlowTempSN"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempSN}
                  onChange={(value) => {
                    this.saveField("FlowTempSN", value);
                  }}
                  error={this.state.validateStatus.FlowTempSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempSN"
                  key="METempSN"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempSN}
                  onChange={(value) => {
                    this.saveField("METempSN", value);
                  }}
                  error={this.state.validateStatus.METempSN !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1SS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMESS"
                  key="RunhMESS"
                  fullWidth
                  readOnly
                  placeholder="ME"
                  value={this.state.after.RunhMESS}
                  onChange={(value) => {
                    this.saveField("RunhMESS", value);
                  }}
                  error={this.state.validateStatus.RunhMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1SS"
                  key="RunhAE1SS"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1SS}
                  onChange={(value) => {
                    this.saveField("RunhAE1SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2SS"
                  key="RunhAE2SS"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2SS}
                  onChange={(value) => {
                    this.saveField("RunhAE2SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3SS"
                  key="RunhAE3SS"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3SS}
                  onChange={(value) => {
                    this.saveField("RunhAE3SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3SS !== undefined}
                />
              </Grid>

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1SS"
                    key="RunhPP1SS"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1SS}
                    onChange={(value) => {
                      this.saveField("RunhPP1SS", value);
                    }}
                    error={this.state.validateStatus.RunhPP1SS !== undefined}
                  />
                </Grid>
              ) : null}

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2SS"
                    key="RunhPP2SS"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2SS}
                    onChange={(value) => {
                      this.saveField("RunhPP2SS", value);
                    }}
                    error={this.state.validateStatus.RunhPP2SS !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGSS"
                    key="RunhIGSS"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGSS}
                    onChange={(value) => {
                      this.saveField("RunhIGSS", value);
                    }}
                    error={this.state.validateStatus.RunhIGSS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRSS"
                    key="RunhBLRSS"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRSS}
                    onChange={(value) => {
                      this.saveField("RunhBLRSS", value);
                    }}
                    error={this.state.validateStatus.RunhBLRSS !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.RunhAE1SN}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMESN"
                  key="RunhMESN"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMESN}
                  onChange={(value) => {
                    this.saveField("RunhMESN", value);
                  }}
                  error={this.state.validateStatus.RunhMESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1SN"
                  key="RunhAE1SN"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1SN}
                  onChange={(value) => {
                    this.saveField("RunhAE1SN", value);
                  }}
                  error={this.state.validateStatus.RunhAE1SN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2SN"
                  key="RunhAE2SN"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2SN}
                  onChange={(value) => {
                    this.saveField("RunhAE2SN", value);
                  }}
                  error={this.state.validateStatus.RunhAE2SN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3SN"
                  key="RunhAE3SN"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3SN}
                  onChange={(value) => {
                    this.saveField("RunhAE3SN", value);
                  }}
                  error={this.state.validateStatus.RunhAE3SN !== undefined}
                />
              </Grid>

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1SN"
                    key="RunhPP1SN"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1SN}
                    onChange={(value) => {
                      this.saveField("RunhPP1SN", value);
                    }}
                    error={this.state.validateStatus.RunhPP1SN !== undefined}
                  />
                </Grid>
              ) : null}

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2SN"
                    key="RunhPP2SN"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2SN}
                    onChange={(value) => {
                      this.saveField("RunhPP2SN", value);
                    }}
                    error={this.state.validateStatus.RunhPP2SN !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGSN"
                    key="RunhIGSN"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGSN}
                    onChange={(value) => {
                      this.saveField("RunhIGSN", value);
                    }}
                    error={this.state.validateStatus.RunhIGSN !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRSN"
                    key="RunhBLRSN"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRSN}
                    onChange={(value) => {
                      this.saveField("RunhBLRSN", value);
                    }}
                    error={this.state.validateStatus.RunhBLRSN !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  ENGINE
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMESN"
                  key="RpmMESN"
                  fullWidth
                  value={this.state.after.RpmMESN}
                  onChange={(value) => {
                    this.saveField("RpmMESN", value);
                  }}
                  error={this.state.validateStatus.RpmMESN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCASN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCASN"
                  key="BCASN"
                  fullWidth
                  value={this.state.after.BCASN}
                  onChange={(value) => {
                    this.saveField("BCASN", value);
                  }}
                  error={this.state.validateStatus.BCASN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="LoadSN"
                  key="LoadSN"
                  fullWidth
                  value={this.state.after.LoadSN}
                  onChange={(value) => {
                    this.saveField("LoadSN", value);
                  }}
                  error={this.state.validateStatus.LoadSN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TmaxSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TmaxSN"
                  key="TmaxSN"
                  fullWidth
                  placeholder="Tmax"
                  value={this.state.after.TmaxSN}
                  onChange={(value) => {
                    this.saveField("TmaxSN", value);
                  }}
                  error={this.state.validateStatus.TmaxSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TminSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TminSN"
                  key="TminSN"
                  fullWidth
                  placeholder="Tmin"
                  value={this.state.after.TminSN}
                  onChange={(value) => {
                    this.saveField("TminSN", value);
                  }}
                  error={this.state.validateStatus.TminSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.PaMESN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="PaMESN"
                  key="PaMESN"
                  fullWidth
                  value={this.state.after.PaMESN}
                  onChange={(value) => {
                    this.saveField("PaMESN", value);
                  }}
                  error={this.state.validateStatus.PaMESN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TaSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TaSN"
                  key="TaSN"
                  fullWidth
                  value={this.state.after.TaSN}
                  onChange={(value) => {
                    this.saveField("TaSN", value);
                  }}
                  error={this.state.validateStatus.TaSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirMESN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="AirMESN"
                  key="AirMESN"
                  fullWidth
                  value={this.state.after.AirMESN}
                  onChange={(value) => {
                    this.saveField("AirMESN", value);
                  }}
                  error={this.state.validateStatus.AirMESN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TerSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TerSN"
                  key="TerSN"
                  fullWidth
                  value={this.state.after.TerSN}
                  onChange={(value) => {
                    this.saveField("TerSN", value);
                  }}
                  error={this.state.validateStatus.TerSN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.SlipSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="SlipSN"
                  key="SlipSN"
                  fullWidth
                  value={this.state.after.SlipSN}
                  onChange={(value) => {
                    this.saveField("SlipSN", value);
                  }}
                  error={this.state.validateStatus.SlipSN !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMESS"
                  key="ConsFOMESS"
                  fullWidth
                  placeholder="ME/FO"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMESS"
                  key="ConsDOMESS"
                  fullWidth
                  placeholder="ME/DO"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAESS"
                  key="ConsFOAESS"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAESS}
                  onChange={(value) => {
                    this.saveField("ConsFOAESS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAESS"
                  key="ConsDOAESS"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAESS}
                  onChange={(value) => {
                    this.saveField("ConsDOAESS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAESS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRSS"
                  key="ConsFOBLRSS"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRSS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRSS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRSS"
                  key="ConsDOBLRSS"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRSS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRSS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRSS !== undefined}
                />
              </Grid>
              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOPPSS"
                    key="ConsDOPPSS"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPSS}
                    onChange={(value) => {
                      this.saveField("ConsDOPPSS", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPSS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOIGSS"
                    key="ConsDOIGSS"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGSS}
                    onChange={(value) => {
                      this.saveField("ConsDOIGSS", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGSS !== undefined}
                  />
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylSS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylSS"
                  key="ConsCylSS"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylSS}
                  onChange={(value) => {
                    this.saveField("ConsCylSS", value);
                  }}
                  error={this.state.validateStatus.ConsCylSS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysSS"
                  key="ConsSysSS"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysSS}
                  onChange={(value) => {
                    this.saveField("ConsSysSS", value);
                  }}
                  error={this.state.validateStatus.ConsSysSS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAESS"
                  key="ConsAESS"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAESS}
                  onChange={(value) => {
                    this.saveField("ConsAESS", value);
                  }}
                  error={this.state.validateStatus.ConsAESS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydSS"
                  key="ConsHydSS"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydSS}
                  onChange={(value) => {
                    this.saveField("ConsHydSS", value);
                  }}
                  error={this.state.validateStatus.ConsHydSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.ConsFOMESN}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMESN"
                  key="ConsFOMESN"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMESN}
                  onChange={(value) => {
                    this.saveField("ConsFOMESN", value);
                  }}
                  error={this.state.validateStatus.ConsFOMESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMESN"
                  key="ConsDOMESN"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMESN}
                  onChange={(value) => {
                    this.saveField("ConsDOMESN", value);
                  }}
                  error={this.state.validateStatus.ConsDOMESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAESN"
                  key="ConsFOAESN"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAESN}
                  onChange={(value) => {
                    this.saveField("ConsFOAESN", value);
                  }}
                  error={this.state.validateStatus.ConsFOAESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAESN"
                  key="ConsDOAESN"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAESN}
                  onChange={(value) => {
                    this.saveField("ConsDOAESN", value);
                  }}
                  error={this.state.validateStatus.ConsDOAESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRSN"
                  key="ConsFOBLRSN"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRSN}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRSN", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRSN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRSN"
                  key="ConsDOBLRSN"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRSN}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRSN", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRSN !== undefined}
                />
              </Grid>
              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOPPSN"
                    key="ConsDOPPSN"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPSN}
                    onChange={(value) => {
                      this.saveField("ConsDOPPSN", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPSN !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOIGSN"
                    key="ConsDOIGSN"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGSN}
                    onChange={(value) => {
                      this.saveField("ConsDOIGSN", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGSN !== undefined}
                  />
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylSN"
                  key="ConsCylSN"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylSN}
                  onChange={(value) => {
                    this.saveField("ConsCylSN", value);
                  }}
                  error={this.state.validateStatus.ConsCylSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysSN"
                  key="ConsSysSN"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysSN}
                  onChange={(value) => {
                    this.saveField("ConsSysSN", value);
                  }}
                  error={this.state.validateStatus.ConsSysSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAESN"
                  key="ConsAESN"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAESN}
                  onChange={(value) => {
                    this.saveField("ConsAESN", value);
                  }}
                  error={this.state.validateStatus.ConsAESN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydSN"
                  key="ConsHydSN"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydSN}
                  onChange={(value) => {
                    this.saveField("ConsHydSN", value);
                  }}
                  error={this.state.validateStatus.ConsHydSN !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSS}</FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOSS"
                  key="RobFOSS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOSS}
                  onChange={(value) => {
                    this.saveField("RobFOSS", value);
                  }}
                  error={this.state.validateStatus.RobFOSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOSS"
                  key="RobDOSS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOSS}
                  onChange={(value) => {
                    this.saveField("RobDOSS", value);
                  }}
                  error={this.state.validateStatus.RobDOSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWSS"
                  key="RobFWSS"
                  fullWidth
                  placeholder={fieldLabels.RobFWSS}
                  value={this.state.after.RobFWSS}
                  onChange={(value) => {
                    this.saveField("RobFWSS", value);
                  }}
                  error={this.state.validateStatus.RobFWSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.RobSN}
                </FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOSN"
                  key="RobFOSN"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOSN}
                  onChange={(value) => {
                    this.saveField("RobFOSN", value);
                  }}
                  error={this.state.validateStatus.RobFOSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOSN"
                  key="RobDOSN"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOSN}
                  onChange={(value) => {
                    this.saveField("RobDOSN", value);
                  }}
                  error={this.state.validateStatus.RobDOSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWSN"
                  key="RobFWSN"
                  fullWidth
                  decimalScale={0}
                  placeholder={fieldLabels.RobFWSN}
                  value={this.state.after.RobFWSN}
                  onChange={(value) => {
                    this.saveField("RobFWSN", value);
                  }}
                  error={this.state.validateStatus.RobFWSN !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureSN"
                  key="FWPureSN"
                  fullWidth
                  decimalScale={1}
                  value={this.state.after.FWPureSN}
                  onChange={(value) => {
                    this.saveField("FWPureSN", value);
                  }}
                  error={this.state.validateStatus.FWPureSN !== undefined}
                />
              </Grid>
              <Grid item xs={20}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Bunker}</FormLabel>
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.open_logger_data_dialog ? (
          <LoggerDataDialog
            open={true}
            Data={{
              ThoiGian1: this.state.after.TimeSS,
              ThoiGian2: this.state.after.TimeSN,
              PickDistanceFromTime: true,
            }}
            close={(data) => {
              if (data) {
                //ghi nhan gia tri lay tu logger
                var after = this.state.after;
                after.Position =
                  data.Lat2 != 0 && data.Lon2 != 0
                    ? geoLib.latToDMS(data.Lat2) +
                      ", " +
                      geoLib.lonToDMS(data.Lon2)
                    : "";
                after.RpmCounterMESS = data.RpmCounter1;
                after.RpmCounterMESN = data.RpmCounter2;
                after.RpmMESN = data.Rpm2;
                after.FlowMESS = data.FlowmeterME1;
                after.FlowMESN = data.FlowmeterME2;
                after.FlowAESS = data.FlowmeterAE1;
                after.FlowAESN = data.FlowmeterAE2;
                after.FlowBLRSS = data.FlowmeterBLR1;
                after.FlowBLRSN = data.FlowmeterBLR2;
                after.FlowTempSS = data.FlowTemp1;
                after.FlowTempSN = data.FlowTemp2;
                after.METempSS = data.TempOilME1;
                after.METempSN = data.TempOilME2;
                after.Distance = data.Distance2
                  ? data.Distance2
                  : after.Distance;
              }
              this.setState({ open_logger_data_dialog: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ShiftingStartReportEditDialog
);
