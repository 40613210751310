import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Arrival Report" : "Báo cáo tàu đến cảng";
const fieldLabels = englishUI
  ? {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RpmMEAC: "RPM ME",
    BCAAC: "Pr",
    LoadAC: "Hand load",
    TminAC: "Tmin",
    TmaxAC: "Tmax",
    PaMEAC: "Pa",
    AirMEAC: "Air drop",
    TerAC: "Ter",
    RobFOAC: "ROB-AC:FO/DO",
    RobDOAC: "",
    RobSumpAC: "LO",
    RobSysAC: "",
    RobCylAC: "",
    RobAEAC: "",
    RobHydAC: "",
    RobFWAC: "FW",
    RobFOAA: "ROB-AA:FO/DO",
    RobDOAA: "",
    RobSumpAA: "LO",
    RobSysAA: "",
    RobCylAA: "",
    RobAEAA: "",
    RobHydAA: "",
    RobFWAA: "FW",
    RpmCounterMEAC: "M/E RPM CTR AC",
    FlowMEAC: "FM",
    FlowAEAC: "",
    FlowBLRAC: "",
    Flow3AC: "",
    FlowTempAC: "Temp F.M/ME",
    METempAC: "",
    RpmCounterMEAA: "M/E RPM CTR AA",
    FlowMEAA: "FM",
    FlowAEAA: "",
    FlowBLRAA: "",
    FlowTempAA: "Temp F.M/ME",
    METempAA: "",
    ConsFOMEAC: "Cons(AC): ME(FO/DO)",
    ConsDOMEAC: "",
    ConsFOAEAC: "AE(FO/DO)",
    ConsDOAEAC: "",
    ConsFOBLRAC: "BLR(FO/DO)",
    ConsDOBLRAC: "",
    ConsCylAC: "LO(Cyl/Sys/AE/Hyd)",
    ConsSysAC: "",
    ConsAEAC: "",
    ConsHydAC: "",
    ConsFOMEAA: "Cons(AA): ME(FO/DO)",
    ConsDOMEAA: "",
    ConsFOAEAA: "AE(FO/DO)",
    ConsDOAEAA: "",
    ConsFOBLRAA: "BLR(FO/DO)",
    ConsDOBLRAA: "",
    ConsCylAA: "LO(Cyl/Sys/AE/Hyd)",
    ConsSysAA: "",
    ConsAEAA: "",
    ConsHydAA: "",
    FWPureAC: "FW Pur",
    RunhMEAC: "Running.Hr (BF-AC): ME",
    RunhAE1AC: "AE1/AE2/AE3",
    RunhAE2AC: "",
    RunhAE3AC: "",
    RunhIGAC: "IG",
    RunhMEAA: "Running.Hr (AC-AA): ME",
    RunhAE1AA: "AE1/AE2/AE3",
    RunhAE2AA: "",
    RunhAE3AA: "",
    RunhIGAA: "IG",
    ConsFODCAC: "FO",
    BHPDCAC: "BHP",
    LoadDCAC: "Load",
    RpmMEDCAC: "RPM",
    AvgSpeedDCAC: "Speed"
  }
  : {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RpmMEAC: "RPM ME",
    BCAAC: "Pr",
    LoadAC: "Hand load",
    TminAC: "Tmin",
    TmaxAC: "Tmax",
    PaMEAC: "Pa",
    AirMEAC: "Air drop",
    TerAC: "Ter",
    RobFOAC: "ROB-AC:FO/DO",
    RobDOAC: "",
    RobSumpAC: "LO",
    RobSysAC: "",
    RobCylAC: "",
    RobAEAC: "",
    RobHydAC: "",
    RobFWAC: "FW",
    RobFOAA: "ROB-AA:FO/DO",
    RobDOAA: "",
    RobSumpAA: "LO",
    RobSysAA: "",
    RobCylAA: "",
    RobAEAA: "",
    RobHydAA: "",
    RobFWAA: "FW",
    RpmCounterMEAC: "M/E RPM CTR AC",
    FlowMEAC: "FM",
    FlowAEAC: "",
    FlowBLRAC: "",
    Flow3AC: "",
    FlowTempAC: "Temp F.M/ME",
    METempAC: "",
    RpmCounterMEAA: "M/E RPM CTR AA",
    FlowMEAA: "FM",
    FlowAEAA: "",
    FlowBLRAA: "",
    FlowTempAA: "Temp F.M/ME",
    METempAA: "",
    ConsFOMEAC: "Cons(AC): ME(FO/DO)",
    ConsDOMEAC: "",
    ConsFOAEAC: "AE(FO/DO)",
    ConsDOAEAC: "",
    ConsFOBLRAC: "BLR(FO/DO)",
    ConsDOBLRAC: "",
    ConsCylAC: "LO(Cyl/Sys/AE/Hyd)",
    ConsSysAC: "",
    ConsAEAC: "",
    ConsHydAC: "",
    ConsFOMEAA: "Cons(AA): ME(FO/DO)",
    ConsDOMEAA: "",
    ConsFOAEAA: "AE(FO/DO)",
    ConsDOAEAA: "",
    ConsFOBLRAA: "BLR(FO/DO)",
    ConsDOBLRAA: "",
    ConsCylAA: "LO(Cyl/Sys/AE/Hyd)",
    ConsSysAA: "",
    ConsAEAA: "",
    ConsHydAA: "",
    FWPureAC: "FW Pur",
    RunhMEAC: "Running.Hr (BF-AC): ME",
    RunhAE1AC: "AE1/AE2/AE3",
    RunhAE2AC: "",
    RunhAE3AC: "",
    RunhIGAC: "IG",
    RunhMEAA: "Running.Hr (AC-AA): ME",
    RunhAE1AA: "AE1/AE2/AE3",
    RunhAE2AA: "",
    RunhAE3AA: "",
    RunhIGAA: "IG",
    ConsFODCAC: "FO",
    BHPDCAC: "BHP",
    LoadDCAC: "Load",
    RpmMEDCAC: "RPM",
    AvgSpeedDCAC: "Speed"
  };

const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnLoadRandomSample: "Random sample",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class CEArrivalReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu duoc save hay chua
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 3,
        ReportTime: null,
        RpmMEAC: null,
        BCAAC: null,
        LoadAC: null,
        TminAC: null,
        TmaxAC: null,
        PaMEAC: null,
        AirMEAC: null,
        TerAC: null,
        RobFOAC: null,
        RobDOAC: null,
        RobSumpAC: null,
        RobSysAC: null,
        RobCylAC: null,
        RobAEAC: null,
        RobHydAC: null,
        RobFWAC: null,
        RobFOAA: null,
        RobDOAA: null,
        RobSumpAA: null,
        RobSysAA: null,
        RobCylAA: null,
        RobAEAA: null,
        RobHydAA: null,
        RobFWAA: null,
        RpmCounterMEAC: null,
        FlowMEAC: null,
        FlowAEAC: null,
        FlowBLRAC: null,
        Flow3AC: null,
        FlowTempAC: null,
        METempAC: null,
        RpmCounterMEAA: null,
        FlowMEAA: null,
        FlowAEAA: null,
        FlowBLRAA: null,
        Flow3AA: null,
        FlowTempAA: null,
        METempAA: null,
        ConsFOMEAC: null,
        ConsDOMEAC: null,
        ConsFOAEAC: null,
        ConsDOAEAC: null,
        ConsFOBLRAC: null,
        ConsDOBLRAC: null,
        ConsCylAC: null,
        ConsSysAC: null,
        ConsAEAC: null,
        ConsHydAC: null,
        ConsFOMEAA: null,
        ConsDOMEAA: null,
        ConsFOAEAA: null,
        ConsDOAEAA: null,
        ConsFOBLRAA: null,
        ConsDOBLRAA: null,
        ConsCylAA: null,
        ConsSysAA: null,
        ConsAEAA: null,
        ConsHydAA: null,
        FWPureAC: null,
        RunhMEAC: null,
        RunhAE1AC: null,
        RunhAE2AC: null,
        RunhAE3AC: null,
        RunhIGAC: null,
        RunhMEAA: null,
        RunhAE1AA: null,
        RunhAE2AA: null,
        RunhAE3AA: null,
        RunhIGAA: null,
        ConsFODCAC: null,
        BHPDCAC: null,
        LoadDCAC: null,
        RpmMEDCAC: null,
        AvgSpeedDCAC: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 3,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 3; //arrival report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.TimeAA,
              ReportStatus: reportStatus,
              ReportContent: JSON.stringify(this.state.after),
              AttachedReportID: null, // TODO: select bunker port
              ParentReportID:
                this.props.edittingRecord !== undefined
                  ? this.props.edittingRecord.ParentReportID
                  : null, // when replace an existing report
            },
          })
          .then((response) => {
            this.state.after.DailyReportID =
              response.OS_DailyReport[0].DailyReportID;
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
                reportStatus: reportStatus,
              },
              () => {
                //upload
                if (reportStatus == 1) {
                  this.doUpload();
                } else {
                  alert("Cập nhật thành công!");
                }
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  readOnly
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMEAC"
                  key="RpmMEAC"
                  fullWidth
                  value={this.state.after.RpmMEAC}
                  onChange={(value) => {
                    this.saveField("RpmMEAC", value);
                  }}
                  error={this.state.validateStatus.RpmMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCAAC}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCAAC"
                  key="BCAAC"
                  fullWidth
                  value={this.state.after.BCAAC}
                  onChange={(value) => {
                    this.saveField("BCAAC", value);
                  }}
                  error={this.state.validateStatus.BCAAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.LoadAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="LoadAC"
                  key="LoadAC"
                  fullWidth
                  value={this.state.after.LoadAC}
                  onChange={(value) => {
                    this.saveField("LoadAC", value);
                  }}
                  error={this.state.validateStatus.LoadAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TminAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TminAC"
                  key="TminAC"
                  fullWidth
                  value={this.state.after.TminAC}
                  onChange={(value) => {
                    this.saveField("TminAC", value);
                  }}
                  error={this.state.validateStatus.TminAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TmaxAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TmaxAC"
                  key="TmaxAC"
                  fullWidth
                  value={this.state.after.TmaxAC}
                  onChange={(value) => {
                    this.saveField("TmaxAC", value);
                  }}
                  error={this.state.validateStatus.TmaxAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.PaMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="PaMEAC"
                  key="PaMEAC"
                  fullWidth
                  value={this.state.after.PaMEAC}
                  onChange={(value) => {
                    this.saveField("PaMEAC", value);
                  }}
                  error={this.state.validateStatus.PaMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.AirMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AirMEAC"
                  key="AirMEAC"
                  fullWidth
                  value={this.state.after.AirMEAC}
                  onChange={(value) => {
                    this.saveField("AirMEAC", value);
                  }}
                  error={this.state.validateStatus.AirMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TerAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TerAC"
                  key="TerAC"
                  fullWidth
                  value={this.state.after.TerAC}
                  onChange={(value) => {
                    this.saveField("TerAC", value);
                  }}
                  error={this.state.validateStatus.TerAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFOAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOAC"
                  key="RobFOAC"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOAC}
                  onChange={(value) => {
                    this.saveField("RobFOAC", value);
                  }}
                  error={this.state.validateStatus.RobFOAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOAC"
                  key="RobDOAC"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOAC}
                  onChange={(value) => {
                    this.saveField("RobDOAC", value);
                  }}
                  error={this.state.validateStatus.RobDOAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobSumpAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpAC"
                  key="RobSumpAC"
                  fullWidth
                  placeholder="Sump"
                  value={this.state.after.RobSumpAC}
                  onChange={(value) => {
                    this.saveField("RobSumpAC", value);
                  }}
                  error={this.state.validateStatus.RobSumpAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysAC"
                  key="RobSysAC"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.RobSysAC}
                  onChange={(value) => {
                    this.saveField("RobSysAC", value);
                  }}
                  error={this.state.validateStatus.RobSysAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylAC"
                  key="RobCylAC"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.RobCylAC}
                  onChange={(value) => {
                    this.saveField("RobCylAC", value);
                  }}
                  error={this.state.validateStatus.RobCylAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEAC"
                  key="RobAEAC"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.RobAEAC}
                  onChange={(value) => {
                    this.saveField("RobAEAC", value);
                  }}
                  error={this.state.validateStatus.RobAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydAC"
                  key="RobHydAC"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.RobHydAC}
                  onChange={(value) => {
                    this.saveField("RobHydAC", value);
                  }}
                  error={this.state.validateStatus.RobHydAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobFWAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWAC"
                  key="RobFWAC"
                  fullWidth
                  value={this.state.after.RobFWAC}
                  onChange={(value) => {
                    this.saveField("RobFWAC", value);
                  }}
                  error={this.state.validateStatus.RobFWAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFOAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOAA"
                  key="RobFOAA"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOAA}
                  onChange={(value) => {
                    this.saveField("RobFOAA", value);
                  }}
                  error={this.state.validateStatus.RobFOAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOAA"
                  key="RobDOAA"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOAA}
                  onChange={(value) => {
                    this.saveField("RobDOAA", value);
                  }}
                  error={this.state.validateStatus.RobDOAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobSumpAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSumpAA"
                  key="RobSumpAA"
                  fullWidth
                  placeholder="Sump"
                  value={this.state.after.RobSumpAA}
                  onChange={(value) => {
                    this.saveField("RobSumpAA", value);
                  }}
                  error={this.state.validateStatus.RobSumpAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobSysAA"
                  key="RobSysAA"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.RobSysAA}
                  onChange={(value) => {
                    this.saveField("RobSysAA", value);
                  }}
                  error={this.state.validateStatus.RobSysAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobCylAA"
                  key="RobCylAA"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.RobCylAA}
                  onChange={(value) => {
                    this.saveField("RobCylAA", value);
                  }}
                  error={this.state.validateStatus.RobCylAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobAEAA"
                  key="RobAEAA"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.RobAEAA}
                  onChange={(value) => {
                    this.saveField("RobAEAA", value);
                  }}
                  error={this.state.validateStatus.RobAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobHydAA"
                  key="RobHydAA"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.RobHydAA}
                  onChange={(value) => {
                    this.saveField("RobHydAA", value);
                  }}
                  error={this.state.validateStatus.RobHydAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobFWAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWAA"
                  key="RobFWAA"
                  fullWidth
                  value={this.state.after.RobFWAA}
                  onChange={(value) => {
                    this.saveField("RobFWAA", value);
                  }}
                  error={this.state.validateStatus.RobFWAA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEAC"
                  key="RpmCounterMEAC"
                  fullWidth
                  value={this.state.after.RpmCounterMEAC}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEAC", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAC !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEAC"
                  key="FlowMEAC"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.FlowMEAC}
                  onChange={(value) => {
                    this.saveField("FlowMEAC", value);
                  }}
                  error={this.state.validateStatus.FlowMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEAC"
                  key="FlowAEAC"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAEAC}
                  onChange={(value) => {
                    this.saveField("FlowAEAC", value);
                  }}
                  error={this.state.validateStatus.FlowAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRAC"
                  key="FlowBLRAC"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLRAC}
                  onChange={(value) => {
                    this.saveField("FlowBLRAC", value);
                  }}
                  error={this.state.validateStatus.FlowBLRAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3AC"
                  key="Flow3AC"
                  fullWidth
                  placeholder="ALL"
                  value={this.state.after.Flow3AC}
                  onChange={(value) => {
                    this.saveField("Flow3AC", value);
                  }}
                  error={this.state.validateStatus.Flow3AC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowTempAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempAC"
                  key="FlowTempAC"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempAC}
                  onChange={(value) => {
                    this.saveField("FlowTempAC", value);
                  }}
                  error={this.state.validateStatus.FlowTempAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempAC"
                  key="METempAC"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempAC}
                  onChange={(value) => {
                    this.saveField("METempAC", value);
                  }}
                  error={this.state.validateStatus.METempAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEAA"
                  key="RpmCounterMEAA"
                  fullWidth
                  value={this.state.after.RpmCounterMEAA}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEAA", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAA !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEAA"
                  key="FlowMEAA"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.FlowMEAA}
                  onChange={(value) => {
                    this.saveField("FlowMEAA", value);
                  }}
                  error={this.state.validateStatus.FlowMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEAA"
                  key="FlowAEAA"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAEAA}
                  onChange={(value) => {
                    this.saveField("FlowAEAA", value);
                  }}
                  error={this.state.validateStatus.FlowAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRAA"
                  key="FlowBLRAA"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLRAA}
                  onChange={(value) => {
                    this.saveField("FlowBLRAA", value);
                  }}
                  error={this.state.validateStatus.FlowBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3AA"
                  key="Flow3AA"
                  fullWidth
                  placeholder="ALL"
                  value={this.state.after.Flow3AA}
                  onChange={(value) => {
                    this.saveField("Flow3AA", value);
                  }}
                  error={this.state.validateStatus.Flow3AA !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowTempAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempAA"
                  key="FlowTempAA"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempAA}
                  onChange={(value) => {
                    this.saveField("FlowTempAA", value);
                  }}
                  error={this.state.validateStatus.FlowTempAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempAA"
                  key="METempAA"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempAA}
                  onChange={(value) => {
                    this.saveField("METempAA", value);
                  }}
                  error={this.state.validateStatus.METempAA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEAC"
                  key="ConsFOMEAC"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOMEAC}
                  onChange={(value) => {
                    this.saveField("ConsFOMEAC", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEAC"
                  key="ConsDOMEAC"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOMEAC}
                  onChange={(value) => {
                    this.saveField("ConsDOMEAC", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEAC"
                  key="ConsFOAEAC"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAEAC}
                  onChange={(value) => {
                    this.saveField("ConsFOAEAC", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEAC"
                  key="ConsDOAEAC"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAEAC}
                  onChange={(value) => {
                    this.saveField("ConsDOAEAC", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOBLRAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRAC"
                  key="ConsFOBLRAC"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRAC}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRAC", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRAC"
                  key="ConsDOBLRAC"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRAC}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRAC", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid iten xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsCylAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylAC"
                  key="ConsCylAC"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylAC}
                  onChange={(value) => {
                    this.saveField("ConsCylAC", value);
                  }}
                  error={this.state.validateStatus.ConsCylAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysAC"
                  key="ConsSysAC"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysAC}
                  onChange={(value) => {
                    this.saveField("ConsSysAC", value);
                  }}
                  error={this.state.validateStatus.ConsSysAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEAC"
                  key="ConsAEAC"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAEAC}
                  onChange={(value) => {
                    this.saveField("ConsAEAC", value);
                  }}
                  error={this.state.validateStatus.ConsAEAC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydAC"
                  key="ConsHydAC"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydAC}
                  onChange={(value) => {
                    this.saveField("ConsHydAC", value);
                  }}
                  error={this.state.validateStatus.ConsHydAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEAA"
                  key="ConsFOMEAA"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOMEAA}
                  onChange={(value) => {
                    this.saveField("ConsFOMEAA", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEAA"
                  key="ConsDOMEAA"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOMEAA}
                  onChange={(value) => {
                    this.saveField("ConsDOMEAA", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEAA"
                  key="ConsFOAEAA"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAEAA}
                  onChange={(value) => {
                    this.saveField("ConsFOAEAA", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEAA"
                  key="ConsDOAEAA"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAEAA}
                  onChange={(value) => {
                    this.saveField("ConsDOAEAA", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOBLRAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRAA"
                  key="ConsFOBLRAA"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRAA}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRAA", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRAA"
                  key="ConsDOBLRAA"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRAA}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRAA", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid iten xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsCylAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylAA"
                  key="ConsCylAA"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylAA}
                  onChange={(value) => {
                    this.saveField("ConsCylAA", value);
                  }}
                  error={this.state.validateStatus.ConsCylAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysAA"
                  key="ConsSysAA"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysAA}
                  onChange={(value) => {
                    this.saveField("ConsSysAA", value);
                  }}
                  error={this.state.validateStatus.ConsSysAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEAA"
                  key="ConsAEAA"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAEAA}
                  onChange={(value) => {
                    this.saveField("ConsAEAA", value);
                  }}
                  error={this.state.validateStatus.ConsAEAA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydAA"
                  key="ConsHydAA"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydAA}
                  onChange={(value) => {
                    this.saveField("ConsHydAA", value);
                  }}
                  error={this.state.validateStatus.ConsHydAA !== undefined}
                />
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FWPureAC}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="FWPureAC"
                  key="FWPureAC"
                  fullWidth
                  value={this.state.after.FWPureAC}
                  onChange={(value) => {
                    this.saveField("FWPureAC", value);
                  }}
                  error={this.state.validateStatus.FWPureAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhMEAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEAC"
                  key="RunhMEAC"
                  fullWidth
                  value={this.state.after.RunhMEAC}
                  onChange={(value) => {
                    this.saveField("RunhMEAC", value);
                  }}
                  error={this.state.validateStatus.RunhMEAC !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhAE1AC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1AC"
                  key="RunhAE1AC"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1AC}
                  onChange={(value) => {
                    this.saveField("RunhAE1AC", value);
                  }}
                  error={this.state.validateStatus.RunhAE1AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2AC"
                  key="RunhAE2AC"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2AC}
                  onChange={(value) => {
                    this.saveField("RunhAE2AC", value);
                  }}
                  error={this.state.validateStatus.RunhAE2AC !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3AC"
                  key="RunhAE3AC"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3AC}
                  onChange={(value) => {
                    this.saveField("RunhAE3AC", value);
                  }}
                  error={this.state.validateStatus.RunhAE3AC !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGAC"
                  key="RunhIGAC"
                  fullWidth
                  value={this.state.after.RunhIGAC}
                  onChange={(value) => {
                    this.saveField("RunhIGAC", value);
                  }}
                  error={this.state.validateStatus.RunhIGAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhMEAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEAA"
                  key="RunhMEAA"
                  fullWidth
                  value={this.state.after.RunhMEAA}
                  onChange={(value) => {
                    this.saveField("RunhMEAA", value);
                  }}
                  error={this.state.validateStatus.RunhMEAA !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhAE1AA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1AA"
                  key="RunhAE1AA"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1AA}
                  onChange={(value) => {
                    this.saveField("RunhAE1AA", value);
                  }}
                  error={this.state.validateStatus.RunhAE1AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2AA"
                  key="RunhAE2AA"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2AA}
                  onChange={(value) => {
                    this.saveField("RunhAE2AA", value);
                  }}
                  error={this.state.validateStatus.RunhAE2AA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3AA"
                  key="RunhAE3AA"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3AA}
                  onChange={(value) => {
                    this.saveField("RunhAE3AA", value);
                  }}
                  error={this.state.validateStatus.RunhAE3AA !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGAA}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGAA"
                  key="RunhIGAA"
                  fullWidth
                  value={this.state.after.RunhIGAA}
                  onChange={(value) => {
                    this.saveField("RunhIGAA", value);
                  }}
                  error={this.state.validateStatus.RunhIGAA !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>G-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFODCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFODCAC"
                  key="ConsFODCAC"
                  fullWidth
                  value={this.state.after.ConsFODCAC}
                  onChange={(value) => {
                    this.saveField("ConsFODCAC", value);
                  }}
                  error={this.state.validateStatus.ConsFODCAC !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.BHPDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BHPDCAC"
                  key="BHPDCAC"
                  fullWidth
                  value={this.state.after.BHPDCAC}
                  onChange={(value) => {
                    this.saveField("BHPDCAC", value);
                  }}
                  error={this.state.validateStatus.BHPDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.LoadDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="LoadDCAC"
                  key="LoadDCAC"
                  fullWidth
                  value={this.state.after.LoadDCAC}
                  onChange={(value) => {
                    this.saveField("LoadDCAC", value);
                  }}
                  error={this.state.validateStatus.LoadDCAC !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmMEDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMEDCAC"
                  key="RpmMEDCAC"
                  fullWidth
                  value={this.state.after.RpmMEDCAC}
                  onChange={(value) => {
                    this.saveField("RpmMEDCAC", value);
                  }}
                  error={this.state.validateStatus.RpmMEDCAC !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.AvgSpeedDCAC}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AvgSpeedDCAC"
                  key="AvgSpeedDCAC"
                  fullWidth
                  value={this.state.after.AvgSpeedDCAC}
                  onChange={(value) => {
                    this.saveField("AvgSpeedDCAC", value);
                  }}
                  error={this.state.validateStatus.AvgSpeedDCAC !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CEArrivalReportEditDialog
);
