/**Nhap danh co quan */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import {
  filterStyles,
  showError,
  handleServerError,
} from "../components/common";
import MyNumberEditor from "../components/MyNumberEditor";

const dataStruct = require("../data/DataStruct");
const myLib = require("../lib/MyLib");
const server = require("../lib/server");

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["FieldName", "DataType", "Scale", "Precision"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

export default class TableFieldEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {},
      after: {
        FieldName: "",
        DataType: "varchar", //kieu du lieu
        Scale: 0,
        Precision: 0,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    //this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.props.close(this.state.after);
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"md"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Trường dữ liệu</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Tên trường(*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  readOnly={this.props.edittingRecord !== undefined}
                  value={this.state.after.FieldName}
                  onChange={(event) => {
                    this.saveField("FieldName", event.target.value);
                  }}
                  error={this.state.validateStatus.FieldName !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Kiểu dữ liệu (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <Select
                  fullWidth
                  value={this.state.after.DataType}
                  onChange={(event) =>
                    this.saveData({
                      DataType: event.target.value
                    })
                  }
                >
                  <MenuItem value={"varchar"}>varchar</MenuItem>
                  <MenuItem value={"nvarchar"}>nvarchar</MenuItem>
                  <MenuItem value={"int"}>int</MenuItem>
                  <MenuItem value={"decimal"}>decimal</MenuItem>
                  <MenuItem value={"date"}>date</MenuItem>
                  <MenuItem value={"datetime"}>datetime</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Length/Scale(*):</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  fullWidth
                  value={this.state.after.Scale}
                  onValueChange={(value) => {
                    this.saveField("Scale", value);
                  }}
                  error={this.state.validateStatus.Scale !== undefined}
                />
              </Grid>
              <Grid item xs={18}></Grid>
              <Grid item xs={4}>
                <FormLabel>Precision(*):</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  fullWidth
                  value={this.state.after.Precision}
                  onValueChange={(value) => {
                    this.saveField("Precision", value);
                  }}
                  error={this.state.validateStatus.Precision !== undefined}
                />
              </Grid>
              <Grid item xs={18}></Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.saving}
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
