/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "white",
  },
  menuIcon: {
    color: "white",
  },
}));

export default function MenuShip(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <>
      <a style={{ cursor: "pointer" }} onClick={handleClick}>
        <img
          src="../logo.png"
          alt="Maritime Solution"
          style={{
            width: 65,
            height: 65,
          }}
        ></img>
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.DM_Ship.map((item) => (
          <>
            <MenuItem
              onClick={() => {
                if (props.onClick) {
                  props.onClick(item);
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoatIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{item.ShipName}</ListItemText>
            </MenuItem>
            <Divider />
          </>
        ))}
      </Menu>
    </>
  );
}
