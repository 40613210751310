import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "All Fast Report" : "Báo cáo tàu cập cảng";
const fieldLabels = englishUI
  ? {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RobFOAF: "ROB AF: FO/DO/FW",
    RobDOAF: "",
    RobFWAF: "",
    RunhMEAF: "Running.Hr: ME",
    RunhAE1AF: "AE1/AE2/AE3",
    RunhAE2AF: "",
    RunhAE3AF: "",
    RunhIGAF: "IG",
    ConsFOMEAF: "Cons (AC): ME(FO/DO)",
    ConsDOMEAF: "",
    ConsFOAEAF: "AE(FO/DO)",
    ConsDOAEAF: "",
    ConsFOBLRAF: "BRL(FO/DO)",
    ConsDOBLRAF: "",
    ConsCylAF: "LO",
    ConsSysAF: "",
    ConsAEAF: "",
    ConsHydAF: "",
    FWPureAF: "FW Pur",
    FlowMEAF: "F.M ME/AE/BLR/ALL",
    FlowAEAF: "",
    FlowBLRAF: "",
    Flow3AF: "",
    FlowTempAF: "Temp F.M/ME",
    METempAF: "",
    RpmCounterMEAF: "M/E RPM CTR"
  }
  : {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RobFOAF: "ROB AF: FO/DO/FW",
    RobDOAF: "",
    RobFWAF: "",
    RunhMEAF: "Running.Hr: ME",
    RunhAE1AF: "AE1/AE2/AE3",
    RunhAE2AF: "",
    RunhAE3AF: "",
    RunhIGAF: "IG",
    ConsFOMEAF: "Cons (AC): ME(FO/DO)",
    ConsDOMEAF: "",
    ConsFOAEAF: "AE(FO/DO)",
    ConsDOAEAF: "",
    ConsFOBLRAF: "BRL(FO/DO)",
    ConsDOBLRAF: "",
    ConsCylAF: "LO",
    ConsSysAF: "",
    ConsAEAF: "",
    ConsHydAF: "",
    FWPureAF: "FW Pur",
    FlowMEAF: "F.M ME/AE/BLR/ALL",
    FlowAEAF: "",
    FlowBLRAF: "",
    Flow3AF: "",
    FlowTempAF: "Temp F.M/ME",
    METempAF: "",
    RpmCounterMEAF: "M/E RPM CTR"
  };

const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnLoadRandomSample: "Random sample",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class CEAllfastReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 4,
        ReportTime: null,
        RobFOAF: null,
        RobDOAF: null,
        RobFWAF: null,
        RunhMEAF: null,
        RunhAE1AF: null,
        RunhAE2AF: null,
        RunhAE3AF: null,
        RunhIGAF: null,
        ConsFOMEAF: null,
        ConsDOMEAF: null,
        ConsFOAEAF: null,
        ConsDOAEAF: null,
        ConsFOBLRAF: null,
        ConsDOBLRAF: null,
        ConsCylAF: null,
        ConsSysAF: null,
        ConsAEAF: null,
        ConsHydAF: null,
        FWPureAF: null,
        FlowMEAF: null,
        FlowAEAF: null,
        FlowBLRAF: null,
        FlowTempAF: null,
        METempAF: null,
        RpmCounterMEAF: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 4,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 4; //allfast report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.TimeAF,
              ReportStatus: reportStatus,
              ReportContent: JSON.stringify(this.state.after),
              AttachedReportID: null, // TODO: select bunker port
              ParentReportID:
                this.props.edittingRecord !== undefined
                  ? this.props.edittingRecord.ParentReportID
                  : null, // when replace an existing report
            },
          })
          .then((response) => {
            this.state.after.DailyReportID =
              response.OS_DailyReport[0].DailyReportID;
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
                reportStatus: reportStatus,
              },
              () => {
                //upload
                if (reportStatus == 1) {
                  this.doUpload();
                } else {
                  alert("Cập nhật thành công!");
                }
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>C-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFOAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOAF"
                  key="RobFOAF"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOAF}
                  onChange={(value) => {
                    this.saveField("RobFOAF", value);
                  }}
                  error={this.state.validateStatus.RobFOAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOAF"
                  key="RobDOAF"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOAF}
                  onChange={(value) => {
                    this.saveField("RobDOAF", value);
                  }}
                  error={this.state.validateStatus.RobDOAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWAF"
                  key="RobFWAF"
                  fullWidth
                  placeholder="FW"
                  value={this.state.after.RobFWAF}
                  onChange={(value) => {
                    this.saveField("RobFWAF", value);
                  }}
                  error={this.state.validateStatus.RobFWAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEAF"
                  key="RunhMEAF"
                  fullWidth
                  value={this.state.after.RunhMEAF}
                  onChange={(value) => {
                    this.saveField("RunhMEAF", value);
                  }}
                  error={this.state.validateStatus.RunhMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhAE1AF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1AF"
                  key="RunhAE1AF"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1AF}
                  onChange={(value) => {
                    this.saveField("RunhAE1AF", value);
                  }}
                  error={this.state.validateStatus.RunhAE1AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2AF"
                  key="RunhAE2AF"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2AF}
                  onChange={(value) => {
                    this.saveField("RunhAE2AF", value);
                  }}
                  error={this.state.validateStatus.RunhAE2AF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3AF"
                  key="RunhAE3AF"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3AF}
                  onChange={(value) => {
                    this.saveField("RunhAE3AF", value);
                  }}
                  error={this.state.validateStatus.RunhAE3AF !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGAF"
                  key="RunhIGAF"
                  fullWidth
                  readOnly={!this.state.FMEnable.IG}
                  value={this.state.after.RunhIGAF}
                  onChange={(value) => {
                    this.saveField("RunhIGAF", value);
                  }}
                  error={this.state.validateStatus.RunhIGAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEAF"
                  key="ConsFOMEAF"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOMEAF}
                  onChange={(value) => {
                    this.saveField("ConsFOMEAF", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEAF"
                  key="ConsDOMEAF"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOMEAF}
                  onChange={(value) => {
                    this.saveField("ConsDOMEAF", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEAF"
                  key="ConsFOAEAF"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAEAF}
                  onChange={(value) => {
                    this.saveField("ConsFOAEAF", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEAF"
                  key="ConsDOAEAF"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAEAF}
                  onChange={(value) => {
                    this.saveField("ConsDOAEAF", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOBLRAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRAF"
                  key="ConsFOBLRAF"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRAF}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRAF", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRAF"
                  key="ConsDOBLRAF"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRAF}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRAF", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsCylAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylAF"
                  key="ConsCylAF"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylAF}
                  onChange={(value) => {
                    this.saveField("ConsCylAF", value);
                  }}
                  error={this.state.validateStatus.ConsCylAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysAF"
                  key="ConsSysAF"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysAF}
                  onChange={(value) => {
                    this.saveField("ConsSysAF", value);
                  }}
                  error={this.state.validateStatus.ConsSysAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEAF"
                  key="ConsAEAF"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAEAF}
                  onChange={(value) => {
                    this.saveField("ConsAEAF", value);
                  }}
                  error={this.state.validateStatus.ConsAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydAF"
                  key="ConsHydAF"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydAF}
                  onChange={(value) => {
                    this.saveField("ConsHydAF", value);
                  }}
                  error={this.state.validateStatus.ConsHydAF !== undefined}
                />
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FWPureAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureAF"
                  key="FWPureAF"
                  fullWidth
                  value={this.state.after.FWPureAF}
                  onChange={(value) => {
                    this.saveField("FWPureAF", value);
                  }}
                  error={this.state.validateStatus.FWPureAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowMEAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEAF"
                  key="FlowMEAF"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.FlowMEAF}
                  onChange={(value) => {
                    this.saveField("FlowMEAF", value);
                  }}
                  error={this.state.validateStatus.FlowMEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEAF"
                  key="FlowAEAF"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAEAF}
                  onChange={(value) => {
                    this.saveField("FlowAEAF", value);
                  }}
                  error={this.state.validateStatus.FlowAEAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRAF"
                  key="FlowBLRAF"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLRAF}
                  onChange={(value) => {
                    this.saveField("FlowBLRAF", value);
                  }}
                  error={this.state.validateStatus.FlowBLRAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3AF"
                  key="Flow3AF"
                  fullWidth
                  placeholder="ALL"
                  value={this.state.after.Flow3AF}
                  onChange={(value) => {
                    this.saveField("Flow3AF", value);
                  }}
                  error={this.state.validateStatus.Flow3AF !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowTempAF}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempAF"
                  key="FlowTempAF"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempAF}
                  onChange={(value) => {
                    this.saveField("FlowTempAF", value);
                  }}
                  error={this.state.validateStatus.FlowTempAF !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempAF"
                  key="METempAF"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempAF}
                  onChange={(value) => {
                    this.saveField("METempAF", value);
                  }}
                  error={this.state.validateStatus.METempAF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>G-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMEAF}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="RpmCounterMEAF"
                  key="RpmCounterMEAF"
                  fullWidth
                  value={this.state.after.RpmCounterMEAF}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEAF", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEAF !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CEAllfastReportEditDialog
);
