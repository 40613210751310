import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
import MySelect from "../components/MySelect";
import SyncIcon from "@mui/icons-material/Sync";
import moment from "moment";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import LoggerDataDialog from "./LoggerDataDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Shifting - Noon Report"
  : "Báo cáo buổi trưa trên biển";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time:",
      TimeSN: "Noon:",
      Position: "Position:",
      DisToGo: "D.T.G:",
      Distance: "Distance:",
      AvgSpeed: "Speed:",
      ETA: "E.T.A",
      PortName: "Port:",
      WeatherSea: "Weather:",
      WeatherWind: "",
      ShipCourse: "Course:",
      LacanWater: "Lacan:",
      SlopWater: "Slop:",
      RpmMESN: "RPM ME:",
      BCASN: "Pr:",
      TaSN: "Ta:",
      LoadSN: "Hand load:",
      TminSN: "Tmin:",
      TmaxSN: "Tmax:",
      PaMESN: "Pa:",
      AirMESN: "Air drop:",
      TerSN: "Ter:",
      SlipSN: "Slip:",
      RunhMESN: "N - N:",
      RunhAESN: "AE:",
      RunhAE1SN: "AE1",
      RunhAE2SN: "AE2",
      RunhAE3SN: "AE3",
      RunhIGSN: "IG",
      RunhBLRSN: "BLR",
      RpmCounterMESN: "N - N:",
      FlowMESN: "FM:",
      FlowAESN: "AE FM",
      FlowBLRSN: "BLR FM",
      Flow3SN: "ALL FM",
      Flow2SN: "ME+GE FM",
      FlowTempSN: "T.FM/ME:",

      METempSN: "T.ME:",
      ConsFOMESN: "N - N:",
      ConsDOMESN: "",
      ConsFOAESN: "AE",
      ConsDOAESN: "",
      ConsFOBLRSN: "BLR",
      ConsDOBLRSN: "",
      ConsCylSN: "LO:",
      ConsSysSN: "",
      ConsAESN: "",
      ConsHydSN: "",
      FWPureSN: "FW Pur:",
      RobFOSN: "ROB - N:",
      RobDOSN: "DO",
      RobFWSN: "FW:",
      TimeChanged: "Zone Time Adjust:",
      TimeChangeValue: "",
      Floating: "Drifting:",
      Remark: "Remark:",
    }
  : {
      VoyageCode: "REF",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeSN: "Noon",
      Position: "PSN",
      DisToGo: "DTG",
      AvgSpeed: "SPD",
      ETA: "ETA",
      PortName: "Port",
      WeatherSea: "Weather (Sea/Wind)",
      WeatherWind: "",
      ShipCourse: "Course",
      LacanWater: "Lacan",
      SlopWater: "Slop",
      RpmMESN: "RPM ME",
      BCASN: "Pr",
      TaSN: "Ta",
      LoadSN: "Hand load",
      TminSN: "Tmin",
      TmaxSN: "Tmax",
      PaMESN: "Pa",
      AirMESN: "Air drop",
      TerSN: "Ter",
      SlipSN: "Slip",
      RunhMESN: "Rnh: ME",
      RunhAESN: "AE:",
      RunhAE1SN: "AE1",
      RunhAE2SN: "AE2",
      RunhAE3SN: "AE3",
      RunhIGSN: "IG",
      RunhBLRSN: "BLR",
      RpmCounterMESN: "M/E RPM CTR",
      FlowMESN: "ME FM",
      FlowAESN: "AE FM",
      FlowBLRSN: "BLR FM",
      Flow3SN: "ALL FM",
      FlowTempSN: "T. FM",
      METempSN: "T. ME",
      ConsFOMESN: "Cons: ME",
      ConsDOMESN: "",
      ConsFOAESN: "AE",
      ConsDOAESN: "",
      ConsFOBLRSN: "BLR",
      ConsDOBLRSN: "",
      ConsCylSN: "Cons: LO",
      ConsSysSN: "",
      ConsAESN: "",
      ConsHydSN: "",
      FWPureSN: "FW Pur",
      RobFOSN: "ROB: FO",
      RobDOSN: "DO",
      RobFWSN: "FW",
      TimeChanged: "Timezone adjust",
      TimeChangeValue: "",
      Floating: "Floating",
      Remark: "Remark",
    };
const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.ShiftingNoonReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

const TimeAdjusmentTypes = [
  {
    value: 0,
    label: "NIL",
  },
  {
    value: -1,
    label: "Retard",
  },
  {
    value: 1,
    label: "Add",
  },
];

function ParseTimeAdjusmentType(value) {
  return TimeAdjusmentTypes.find((t) => t.value == value);
}

/*------------------------------------FORM--------------------------------------*/
class ShiftingNoonReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 10,
        ReportTime: null,
        TimeSN: null,
        Position: null,
        DisToGo: null,
        AvgSpeed: null,
        ETA: null,
        PortName: null,
        WeatherSea: null,
        WeatherWind: null,
        ShipCourse: null,
        LacanWater: null,
        SlopWater: null,
        RpmMESN: null,
        BCASN: null,
        LoadSN: null,
        TminSN: null,
        TmaxSN: null,
        PaMESN: null,
        AirMESN: null,
        TerSN: null,
        SlipSN: null,
        RunhMESN: null,
        RunhAE1SN: null,
        RunhAE2SN: null,
        RunhAE3SN: null,
        RunhIGSN: null,
        RpmCounterMESN: null,
        FlowMESN: null,
        FlowAESN: null,
        FlowBLRSN: null,
        Flow3SN: null,
        Flow2SN: null,
        FlowTempSN: null,
        ConsFOMESN: null,
        ConsDOMESN: null,
        ConsFOAESN: null,
        ConsDOAESN: null,
        ConsFOBLRSN: null,
        ConsDOBLRSN: null,
        ConsCylSN: null,
        ConsSysSN: null,
        ConsAESN: null,
        FWPure: null,
        RobFOSN: null,
        RobDOSN: null,
        RobFWSN: null,
        TimeChanged: 0,
        TimeChangeValue: null,
        FloatingHours: null,
        Floating: null,
        Remark: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined
          ? []
          : props.edittingRecord.Attachs
          ? props.edittingRecord.Attachs
          : [], // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.SN")),
      open_logger_data_dialog: false,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tinh toan ROB
  calculateROB() {
    var s = this.state;
    if (!myLib.isEmpty(s.LastROB.RobFO)) {
      s.after.RobFOSN =
        s.LastROB.RobFO -
        this.getConsValue(s.after.ConsFOMESN) -
        this.getConsValue(s.after.ConsFOAESN) -
        this.getConsValue(s.after.ConsFOBLRSN);
    }
    if (!myLib.isEmpty(s.LastROB.RobDO)) {
      s.after.RobDOSN =
        s.LastROB.RobDO -
        this.getConsValue(s.after.ConsDOMESN) -
        this.getConsValue(s.after.ConsDOAESN) -
        this.getConsValue(s.after.ConsDOPPSN) -
        this.getConsValue(s.after.ConsDOIGSN) -
        this.getConsValue(s.after.ConsDOBLRSN);
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.SN");
      if (json && json !== "") {
        var draft = JSON.parse(json);
        console.log(draft);
        if (draft.VoyageCode == this.state.after.VoyageCode) {
          this.state.after = draft;
          this.state.after.ReportID = uuid();
          this.state.after.DailyReportID = 0; //dam bao la report moi

          this.setState({ saving: false });
        }
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 10,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 10; // noon at sea report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.TimeChanged = 0;
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          } else {
            //report.TimeSN = myLib.calculateNoonBefore(             new Date(report.ReportTime)           );
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value
    s.after[fieldName] = value;
    switch (fieldName) {
      case "ReportTime": //tinh thoi gian noon
        break;
      case "ConsFOMESN":
      case "ConsFOAESN":
      case "ConsFOBLRSN":
        if (!myLib.isEmpty(s.LastROB.RobFO)) {
          s.after.RobFOSN =
            s.LastROB.RobFO -
            this.getConsValue(s.after.ConsFOMESN) -
            this.getConsValue(s.after.ConsFOAESN) -
            this.getConsValue(s.after.ConsFOBLRSN);
        }
        break;
      case "ConsDOMESN":
      case "ConsDOAESN":
      case "ConsDOBLRSN":
      case "ConsDOPPSN":
      case "ConsDOIGSN":
        if (!myLib.isEmpty(s.LastROB.RobDO)) {
          s.after.RobDOSN =
            s.LastROB.RobDO -
            this.getConsValue(s.after.ConsDOMESN) -
            this.getConsValue(s.after.ConsDOAESN) -
            this.getConsValue(s.after.ConsDOPPSN) -
            this.getConsValue(s.after.ConsDOIGSN) -
            this.getConsValue(s.after.ConsDOBLRSN);
        }
        break;
      case "RpmCounterMESN":
      case "RunhMESN":
      case "Distance":
      case "RunhMESN":
        //tinh slip, rpm
        if (!myLib.isEmpty(s.LastROB.RpmCounterME)) {
          let counter1 = s.LastROB.RpmCounterME;
          let counter2 = s.after.RpmCounterMESN;
          if (counter2) {
            let runh = s.after.RunhMESN;
            if (runh) {
              s.after.RpmMESN =
                Math.round(((counter2 - counter1) * 10) / (runh * 60)) / 10;
            }

            if (this.state.FMEnable.SlipH) {
              let dis = s.after.Distance;
              if (!isNaN(dis) && dis && counter1 !== counter2) {
                //quang duong ly thuyet
                let range =
                  ((counter2 - counter1) * this.state.FMEnable.SlipH) / 1852.0;
                s.after.SlipSN = myLib.round(((range - dis) / range) * 100, 2);
              }
            }
          }
        }
        //tinh toc do trung binh
        if (s.after.Distance && s.after.RunhMESN) {
          let dis = s.after.Distance;
          let hours = s.after.RunhMESN;
          if (hours) {
            s.after.AvgSpeed = Math.round((dis * 10) / hours) / 10;
          }
        }
        break;
    }
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.SN", JSON.stringify(this.state.after));
      }
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      console.log(this.state.after, check);
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeSN,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-SN";
        myLib.downloadBlob(blob, fileName);
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {
                <Button
                  color="success"
                  variant="contained"
                  disabled={myLib.isEmpty(this.state.after.TimeSN)}
                  onClick={() =>
                    this.setState({ open_logger_data_dialog: true })
                  }
                >
                  {buttonLabels.btnLogger}
                </Button>
              }
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeSN)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeSN}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeSN"
                  key="TimeSN"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeSN}
                  onChange={(value) => {
                    this.saveField("TimeSN", value);
                  }}
                  error={this.state.validateStatus.TimeSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Distance}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="Distance"
                  key="Distance"
                  fullWidth
                  value={this.state.after.Distance}
                  onChange={(value) => {
                    this.saveField("Distance", value);
                  }}
                  error={this.state.validateStatus.Distance !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AvgSpeed}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="AvgSpeed"
                  key="AvgSpeed"
                  fullWidth
                  value={this.state.after.AvgSpeed}
                  onChange={(value) => {
                    this.saveField("AvgSpeed", value);
                  }}
                  error={this.state.validateStatus.AvgSpeed !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETA}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETA"
                  key="ETA"
                  fullWidth
                  value={this.state.after.ETA}
                  onChange={(value) => {
                    this.saveField("ETA", value);
                  }}
                  error={this.state.validateStatus.ETA !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  placeholder="Port"
                  inputProps={{
                    textTransform: "uppercase",
                  }}
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={26}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.WeatherSea}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherSea"
                  key="WeatherSea"
                  fullWidth
                  placeholder="Sea"
                  value={this.state.after.WeatherSea}
                  onChange={(event) => {
                    this.saveField("WeatherSea", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherSea !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="WeatherWind"
                  key="WeatherWind"
                  fullWidth
                  placeholder="Wind"
                  value={this.state.after.WeatherWind}
                  onChange={(event) => {
                    this.saveField("WeatherWind", event.target.value);
                  }}
                  error={this.state.validateStatus.WeatherWind !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ShipCourse}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ShipCourse"
                  key="ShipCourse"
                  fullWidth
                  value={this.state.after.ShipCourse}
                  onChange={(value) => {
                    this.saveField("ShipCourse", value);
                  }}
                  error={this.state.validateStatus.ShipCourse !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  placeholder="Lacan"
                  readOnly={this.state.FMEnable.Slop}
                  value={this.state.after.LacanWater}
                  onChange={(value) => {
                    this.saveField("LacanWater", value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  placeholder="Slop"
                  readOnly={!this.state.FMEnable.Slop}
                  value={this.state.after.SlopWater}
                  onChange={(value) => {
                    this.saveField("SlopWater", value);
                  }}
                  error={
                    this.state.validateStatus.LacaSlopWaternWater !== undefined
                  }
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMESN"
                  key="RpmCounterMESN"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMESN}
                  onChange={(value) => {
                    this.saveField("RpmCounterMESN", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMESN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMESN"
                  key="FlowMESN"
                  fullWidth
                  placeholder="ME"
                  readOnly={!this.state.FMEnable.FlowME}
                  value={this.state.after.FlowMESN}
                  onChange={(value) => {
                    this.saveField("FlowMESN", value);
                  }}
                  error={this.state.validateStatus.FlowMESN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAESN"
                  key="FlowAESN"
                  fullWidth
                  placeholder="AE"
                  readOnly={!this.state.FMEnable.FlowAE}
                  value={this.state.after.FlowAESN}
                  onChange={(value) => {
                    this.saveField("FlowAESN", value);
                  }}
                  error={this.state.validateStatus.FlowAESN !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2SN"
                      key="Flow2SN"
                      fullWidth
                      placeholder="ME+AE"
                      readOnly={!this.state.FMEnable.Flow2}
                      value={this.state.after.Flow2SN}
                      onChange={(value) => {
                        this.saveField("Flow2SN", value);
                      }}
                      error={this.state.validateStatus.Flow2SN !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSN"
                      key="FlowBLRSN"
                      fullWidth
                      placeholder="BLR"
                      readOnly={!this.state.FMEnable.FlowBLR}
                      value={this.state.after.FlowBLRSN}
                      onChange={(value) => {
                        this.saveField("FlowBLRSN", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSN !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSN"
                      key="FlowBLRSN"
                      fullWidth
                      placeholder="BLR"
                      readOnly={!this.state.FMEnable.FlowBLR}
                      value={this.state.after.FlowBLRSN}
                      onChange={(value) => {
                        this.saveField("FlowBLRSN", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSN !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3SN"
                      key="Flow3SN"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3SN}
                      onChange={(value) => {
                        this.saveField("Flow3SN", value);
                      }}
                      error={this.state.validateStatus.Flow3SN !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempSN"
                  key="FlowTempSN"
                  fullWidth
                  placeholder="FM"
                  value={this.state.after.FlowTempSN}
                  onChange={(value) => {
                    this.saveField("FlowTempSN", value);
                  }}
                  error={this.state.validateStatus.FlowTempSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempSN"
                  key="METempSN"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempSN}
                  onChange={(value) => {
                    this.saveField("METempSN", value);
                  }}
                  error={this.state.validateStatus.METempSN !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMESN"
                  key="RunhMESN"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMESN}
                  onChange={(value) => {
                    this.saveField("RunhMESN", value);
                  }}
                  error={this.state.validateStatus.RunhMESN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1SN"
                  key="RunhAE1SN"
                  fullWidth
                  placeholder={fieldLabels.RunhAE1SN}
                  value={this.state.after.RunhAE1SN}
                  onChange={(value) => {
                    this.saveField("RunhAE1SN", value);
                  }}
                  error={this.state.validateStatus.RunhAE1SN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2SN"
                  key="RunhAE2SN"
                  fullWidth
                  placeholder={fieldLabels.RunhAE2SN}
                  value={this.state.after.RunhAE2SN}
                  onChange={(value) => {
                    this.saveField("RunhAE2SN", value);
                  }}
                  error={this.state.validateStatus.RunhAE2SN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3SN"
                  key="RunhAE3SN"
                  fullWidth
                  placeholder={fieldLabels.RunhAE3SN}
                  value={this.state.after.RunhAE3SN}
                  onChange={(value) => {
                    this.saveField("RunhAE3SN", value);
                  }}
                  error={this.state.validateStatus.RunhAE3SN !== undefined}
                />
              </Grid>

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1SN"
                    key="RunhPP1SN"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1SN}
                    onChange={(value) => {
                      this.saveField("RunhPP1SN", value);
                    }}
                    error={this.state.validateStatus.RunhPP1SN !== undefined}
                  />
                </Grid>
              ) : null}

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2SN"
                    key="RunhPP2SN"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2SN}
                    onChange={(value) => {
                      this.saveField("RunhPP2SN", value);
                    }}
                    error={this.state.validateStatus.RunhPP2SN !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGSN"
                    key="RunhIGSN"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGSN}
                    onChange={(value) => {
                      this.saveField("RunhIGSN", value);
                    }}
                    error={this.state.validateStatus.RunhIGSN !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRSN"
                    key="RunhBLRSN"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRSN}
                    onChange={(value) => {
                      this.saveField("RunhBLRSN", value);
                    }}
                    error={this.state.validateStatus.RunhBLRSN !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  ENGINE
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMESN"
                  key="RpmMESN"
                  fullWidth
                  value={this.state.after.RpmMESN}
                  onChange={(value) => {
                    this.saveField("RpmMESN", value);
                  }}
                  error={this.state.validateStatus.RpmMESN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCASN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCASN"
                  key="BCASN"
                  fullWidth
                  value={this.state.after.BCASN}
                  onChange={(value) => {
                    this.saveField("BCASN", value);
                  }}
                  error={this.state.validateStatus.BCASN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LoadSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="LoadSN"
                  key="LoadSN"
                  fullWidth
                  value={this.state.after.LoadSN}
                  onChange={(value) => {
                    this.saveField("LoadSN", value);
                  }}
                  error={this.state.validateStatus.LoadSN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TmaxSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TmaxSN"
                  key="TmaxSN"
                  fullWidth
                  value={this.state.after.TmaxSN}
                  onChange={(value) => {
                    this.saveField("TmaxSN", value);
                  }}
                  error={this.state.validateStatus.TmaxSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TminSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TminSN"
                  key="TminSN"
                  fullWidth
                  value={this.state.after.TminSN}
                  onChange={(value) => {
                    this.saveField("TminSN", value);
                  }}
                  error={this.state.validateStatus.TminSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.PaMESN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="PaMESN"
                  key="PaMESN"
                  fullWidth
                  value={this.state.after.PaMESN}
                  onChange={(value) => {
                    this.saveField("PaMESN", value);
                  }}
                  error={this.state.validateStatus.PaMESN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TaSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TaSN"
                  key="TaSN"
                  fullWidth
                  value={this.state.after.TaSN}
                  onChange={(value) => {
                    this.saveField("TaSN", value);
                  }}
                  error={this.state.validateStatus.TaSN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.AirMESN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="AirMESN"
                  key="AirMESN"
                  fullWidth
                  value={this.state.after.AirMESN}
                  onChange={(value) => {
                    this.saveField("AirMESN", value);
                  }}
                  error={this.state.validateStatus.AirMESN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.TerSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="TerSN"
                  key="TerSN"
                  fullWidth
                  value={this.state.after.TerSN}
                  onChange={(value) => {
                    this.saveField("TerSN", value);
                  }}
                  error={this.state.validateStatus.TerSN !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.SlipSN}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="SlipSN"
                  key="SlipSN"
                  fullWidth
                  value={this.state.after.SlipSN}
                  onChange={(value) => {
                    this.saveField("SlipSN", value);
                  }}
                  error={this.state.validateStatus.SlipSN !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOMESN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMESN"
                  key="ConsFOMESN"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMESN}
                  onChange={(value) => {
                    this.saveField("ConsFOMESN", value);
                  }}
                  error={this.state.validateStatus.ConsFOMESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMESN"
                  key="ConsDOMESN"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMESN}
                  onChange={(value) => {
                    this.saveField("ConsDOMESN", value);
                  }}
                  error={this.state.validateStatus.ConsDOMESN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAESN"
                  key="ConsFOAESN"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAESN}
                  onChange={(value) => {
                    this.saveField("ConsFOAESN", value);
                  }}
                  error={this.state.validateStatus.ConsFOAESN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAESN"
                  key="ConsDOAESN"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAESN}
                  onChange={(value) => {
                    this.saveField("ConsDOAESN", value);
                  }}
                  error={this.state.validateStatus.ConsDOAESN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRSN"
                  key="ConsFOBLRSN"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRSN}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRSN", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRSN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRSN"
                  key="ConsDOBLRSN"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRSN}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRSN", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRSN !== undefined}
                />
              </Grid>
              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOPPSN"
                    key="ConsDOPPSN"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPSN}
                    onChange={(value) => {
                      this.saveField("ConsDOPPSN", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPSN !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOIGSN"
                    key="ConsDOIGSN"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGSN}
                    onChange={(value) => {
                      this.saveField("ConsDOIGSN", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGSN !== undefined}
                  />
                </Grid>
              ) : null}
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ConsCylSN}</FormLabel>
              </Grid>

              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylSN"
                  key="ConsCylSN"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylSN}
                  onChange={(value) => {
                    this.saveField("ConsCylSN", value);
                  }}
                  error={this.state.validateStatus.ConsCylSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysSN"
                  key="ConsSysSN"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysSN}
                  onChange={(value) => {
                    this.saveField("ConsSysSN", value);
                  }}
                  error={this.state.validateStatus.ConsSysSN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAESN"
                  key="ConsAESN"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAESN}
                  onChange={(value) => {
                    this.saveField("ConsAESN", value);
                  }}
                  error={this.state.validateStatus.ConsAESN !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydSN"
                  key="ConsHydSN"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydSN}
                  onChange={(value) => {
                    this.saveField("ConsHydSN", value);
                  }}
                  error={this.state.validateStatus.ConsHydSN !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFOSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOSN"
                  key="RobFOSN"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOSN}
                  onChange={(value) => {
                    this.saveField("RobFOSN", value);
                  }}
                  error={this.state.validateStatus.RobFOSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOSN"
                  key="RobDOSN"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOSN}
                  onChange={(value) => {
                    this.saveField("RobDOSN", value);
                  }}
                  error={this.state.validateStatus.RobDOSN !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWSN"
                  key="RobFWSN"
                  fullWidth
                  decimalScale={0}
                  value={this.state.after.RobFWSN}
                  onChange={(value) => {
                    this.saveField("RobFWSN", value);
                  }}
                  error={this.state.validateStatus.RobFWSN !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureSN}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureSN"
                  key="FWPureSN"
                  fullWidth
                  decimalScale={1}
                  value={this.state.after.FWPureSN}
                  onChange={(value) => {
                    this.saveField("FWPureSN", value);
                  }}
                  error={this.state.validateStatus.FWPureSN !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeChanged}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MySelect
                  id="TimeChanged"
                  key="TimeChanged"
                  fullWidth
                  options={TimeAdjusmentTypes}
                  optionValue="value"
                  optionLabel="label"
                  value={this.state.after.TimeChanged}
                  onChange={(event) => {
                    this.saveData({
                      TimeChanged: event.target.value,
                      TimeChangeValue:
                        event.target.value == 0
                          ? null
                          : this.state.after.TimeChangeValue,
                    });
                  }}
                ></MySelect>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TimeChangeValue"
                  key="TimeChangeValue"
                  readOnly={this.state.after.TimeChanged == 0}
                  fullWidth
                  value={this.state.after.TimeChangeValue}
                  onChange={(value) => {
                    this.saveField("TimeChangeValue", value);
                  }}
                  error={
                    this.state.validateStatus.TimeChangeValue !== undefined
                  }
                />
              </Grid>
              <Grid item xs={14}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Floating}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FloatingHours"
                  key="FloatingHours"
                  placeholder="Time"
                  fullWidth
                  value={this.state.after.FloatingHours}
                  onChange={(value) => {
                    this.saveField("FloatingHours", value);
                  }}
                  error={this.state.validateStatus.FloatingHours !== undefined}
                />
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="Floating"
                  key="Floating"
                  fullWidth
                  multiline
                  placeholder="Cause"
                  value={this.state.after.Floating}
                  onChange={(event) => {
                    this.saveField("Floating", event.target.value);
                  }}
                  error={this.state.validateStatus.Floating !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>Attachs:</FormLabel>
              </Grid>
              <Grid item xs={11}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.open_logger_data_dialog ? (
          <LoggerDataDialog
            open={true}
            Data={{
              ThoiGian1: null,
              ThoiGian2: this.state.after.TimeSN,
            }}
            close={(data) => {
              if (data) {
                //ghi nhan gia tri lay tu logger

                var after = this.state.after;
                after.Position =
                  data.Lat2 != 0 && data.Lon2 != 0
                    ? geoLib.latToDMS(data.Lat2) +
                      ", " +
                      geoLib.lonToDMS(data.Lon2)
                    : "";
                after.RpmCounterMESN = data.RpmCounter2;
                after.RpmMESN = data.Rpm2;
                after.FlowMESN = data.FlowmeterME2;
                after.FlowAESN = data.FlowmeterAE2;
                after.FlowBLRSN = data.FlowmeterBLR2;
                after.FlowTempSN = data.FlowTemp2;
                after.METempSN = data.TempOilME2;
                after.Distance = data.Distance ? data.Distance : after.Distance;
              }
              this.setState({ open_logger_data_dialog: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ShiftingNoonReportEditDialog
);
