/*Quan ly danh sach cac form*/
import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import { makeStyles, withStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import RedeemIcon from "@mui/icons-material/Redeem";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";

import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import AsyncSelect from "react-select/async";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MenuButtons from "../components/MenuButtons";

import { ContactPageSharp } from "@mui/icons-material";
import MenuChuyenMoi from "../components/MenuChuyenMoi";

import ButtonTimKiem from "../components/ButtonTimKiem";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import DataEditDialog from "./DataEditDialog";
import { createIsAfterIgnoreDatePart } from "@mui/lab/internal/pickers/time-utils";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");

const dataStruct = require("../data/DataStruct");

const default_columns_grid = [];
const englishUI = window.location.href.indexOf("english") >= 0;

const defaultSortInfo = { name: "id", dir: 0, type: "number" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};
//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
//tao control cho tung field tim kiem
function createTieuChi(field, props, state, saveField, saveData, autoFocus) {
  const danhsach = myLib.isEmpty(field.SourceName)
    ? []
    : state.DanhMuc[field.SourceName]
    ? state.DanhMuc[field.SourceName]
    : [];
  return (
    <>
      <Grid item xs={field.LabelWidth}>
        <FormLabel>{englishUI ? field.LabelEN : field.Label}</FormLabel>
      </Grid>
      <Grid item xs={field.InputWidth}>
        {!myLib.isEmpty(field.SourceName) ? (
          danhsach.length < 100 ? (
            <SelectFilter
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              autoFocus={autoFocus}
              options={danhsach}
              getOptionLabel={(item) => item[field.SourceLabel]}
              getOptionValue={(item) => item[field.SourceValue]}
              placeholder=""
              styles={filterStyles}
              menuPosition={"fixed"}
              value={state.after[field.FieldName]}
              isMulti={field.Compare.indexOf("in") >= 0}
              isClearable
              onChange={(item) => {
                if (field.Compare.indexOf("in") >= 0) {
                  saveField(field.FieldName, item ? item : []);
                } else {
                  saveField(field.FieldName, item ? item : undefined);
                }
              }}
            ></SelectFilter>
          ) : (
            <AsyncSelect
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              cacheOptions
              defaultOptions
              isClearable
              placeholder=""
              styles={filterStyles}
              menuPosition={"fixed"}
              getOptionLabel={(item) => item[field.SourceLabel]}
              getOptionValue={(item) => item[field.SourceValue]}
              value={danhsach}
              isMulti={field.Compare.indexOf("in") >= 0}
              loadOptions={(i, c) =>
                filterDanhSach(
                  state.DanhMuc[field.SourceName],
                  field.SourceLabel,
                  i,
                  c
                )
              }
              onChange={(item) => {
                if (field.Compare.indexOf("in") >= 0) {
                  saveField(field.FieldName, item ? item : []);
                } else {
                  saveField(field.FieldName, item ? item : undefined);
                }
              }}
            ></AsyncSelect>
          )
        ) : field.DataType == "string" ? (
          <OutlinedInput
            id={field.FieldName}
            key={field.FieldName}
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(event) => {
              saveField(field.FieldName, event.target.value);
            }}
          />
        ) : field.DataType == "date" ? (
          <MyDateEditor
            id={field.FieldName}
            key={field.FieldName}
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(value) => {
              saveField(field.FieldName, value);
            }}
          />
        ) : field.DataType == "datetime" ? (
          <MyDateTimeEditor
            id={field.FieldName}
            key={field.FieldName}
            fullWidth
            autoFocus={autoFocus}
            value={state.after[field.FieldName]}
            onChange={(value) => {
              saveField(field.FieldName, value);
            }}
          />
        ) : field.DataType == "Bit" ? (
          <FormControlLabel
            id={field.FieldName}
            key={field.FieldName}
            label={""}
            labelPlacement="end"
            fullWidth
            control={
              <Checkbox
                size="medium"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                checked={state.after[field.FieldName]}
                onChange={(event) => {
                  saveField(field.FieldName, event.target.checked);
                }}
              />
            }
          />
        ) : field.DataType == "Int" ? (
          <MyNumberEditor
            id={field.FieldName}
            key={field.FieldName}
            fullWidth
            value={state.after[field.FieldName]}
            onValueChange={(value) => saveField(field.FieldName, value)}
          />
        ) : null}
      </Grid>
      {field.EmptySpace ? <Grid item xs={field.EmptySpace}></Grid> : null}
    </>
  );
}
//cac thong so mac dinh cua grid
const default_gridConfig = {
  OffsetHeight: 55,
  idColumnName: undefined,
  DefaultLimit: 20,
  DefaultSort: defaultSortInfo,
  GridColumns: [],
};
//tra ve cau hinh columns grid theo dinh nghia
function createGridColumns(gridConfig) {
  var cols = [];
  gridConfig.GridColumns.map((field) => {
    //tao column dua theo dinh nghia
    cols.push({
      ...field,
      header: englishUI ? field.headerEN : field.header,
      render: field.format
        ? field.format == "checkbox"
          ? ({ data }) => (
              <Checkbox
                size="small"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                checked={data[field.name]}
              />
            )
          : field.type == "date"
          ? (props) =>
              props.data[field.name]
                ? moment(props.data[field.name]).format(field.format)
                : null
          : field.type == "number"
          ? (props) => (
              <NumericFormat
                thousandSeparator=","
                value={props.data[field.name]}
                displayType="text"
              />
            )
          : undefined
        : undefined,
    });
  });
  return cols;
}
////////////////////////////////CLASSS///////////////////////////////////
class ManageDataPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon thong tin tau
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = createGridColumns(default_gridConfig);
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      //thong tin khai bao ve form
      FormName: props.FormName, // ten form do form cha truyen vao
      FormConfig: {
        GridConfig: default_gridConfig,
        EditButtons: [],
      },
      canSaved: false, //cho phep save hay khong
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {},
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo,
      after:
        props.value === undefined
          ? {
              Debug: 1,
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep
            }
          : props.value, //thong tin dang edit
      validateStatus: { error: false }, //trang thai validate
      //thong tin tau
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
    };
  }
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  componentDidMount() {
    this.loadForm(true);
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return { FormName: nextProps.FormName };
  }
  shouldComponentUpdate(nextProps, nextState) {
    if(this.state.FormName !== nextProps.FormName) {
      this.state.FormName = nextProps.FormName;
      this.loadForm(true);
    }
    return true;
  }
  //load dinh nghia form neu co
  loadForm(force) {
    
    //load cau hinh form
    if (force || this.state.FormConfig.DataSetName == undefined) {
      //chua co thi tien hanh load tu may chu
      server
        .query("Data/GetFormConfig", {
          FormName: this.state.FormName,
        })
        .then((response) => {
          //lay danh sach danh muc can thiet lap
          var danhsach = "";
          var after = this.state.after; //gan them gia tri after
          response.FilterFields.map((field) => {
            if (!myLib.isEmpty(field.SourceName)) {
              danhsach += (danhsach != "" ? "," : "") + field.SourceName;
            }
            //gan gia tri tieu chi tim kiem
            after[field.FieldName] = field.DefaultValue;
          });
          //tao lai grid theo du lieu nhan duoc
          this.columns_grid = createGridColumns(response.GridConfig);
          if (danhsach != "") {
            this.loadDanhMuc(danhsach, () =>
              this.setState({ FormConfig: response })
            );
          } else {
            this.setState({ FormConfig: response });
          }
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  componentDidUpdate(props, state) {}
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: danhsach,
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      server
        .post("Data/DoRequest", {
          Function: "Proc_" + this.state.FormConfig.DataSetName + "_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //call khi user thay doi trang
  render() {
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            {this.state.FormConfig.DataSetName == undefined
              ? null
              : this.state.FormConfig.FilterFields.map((field) =>
                  createTieuChi(
                    field,
                    this.props,
                    this.state,
                    this.saveField,
                    this.saveData,
                    false
                  )
                )}
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ReactDataGrid
              style={{
                height:
                  parentHeight - this.state.FormConfig.GridConfig.OffsetHeight,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={this.state.FormConfig.GridConfig.DefaultLimit}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty={this.state.FormConfig.GridConfig.idColumnName}
              headerHeight={30}
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={this.state.FormConfig.GridConfig.DefaultSort}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data, selected, unselected }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} columns={24}>
              {this.state.FormConfig.EditButtons.map((button) =>
                button.SubActions !== undefined ? (
                  <Grid item xs={button.Width}>
                    <MenuButtons
                      fullWidth
                      englishUI={englishUI}
                      title={englishUI ? button.LabelEN : button.Label}
                      SubActions={button.SubActions}
                      onClick={(subButton) => {
                        var data = {
                          edittingRecord: this.state.rowSelected,
                        };
                        data["action_show_form"] = true;
                        data["action_" + subButton.Action + "_selected"] = true;
                        data["action_FormName"] = subButton.FormName; //hien thi form nao
                        this.setMyState(data);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={button.Width}>
                    <Button
                      variant="contained"
                      disabled={
                        this.state.rowSelected === undefined && button.IsEdit
                      }
                      fullWidth
                      onClick={() => {
                        var data = {
                          edittingRecord: this.state.rowSelected,
                        };
                        data["action_show_form"] = true;
                        data["action_" + button.Action + "_selected"] = true;
                        data["action_FormName"] = button.FormName; //hien thi form nao
                        this.setMyState(data);
                      }}
                    >
                      {englishUI ? button.LabelEN : button.Label}
                    </Button>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}
        {this.state.open_columns_define ? (
          <SearchFormColumnsDialog
            open={true}
            formName={this.constructor.name}
            columns_default={this.columns_grid}
            close={(record) => {
              if (record) {
                this.columns_grid = myLib.applyGridColumns(
                  default_columns_grid,
                  record
                );
              }
              this.setState({
                open_columns_define: false,
              });
            }}
          />
        ) : null}
        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ action_show_form: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ManageDataPage);
