import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Bunker Report" : "Báo cáo dầu";
const fieldLabels = englishUI
  ? {
    "VoyageCode": "Voyage Code",
    "ReportTypeID": "ReportTypeID",
    "ReportTime": "ReportTime",
    "Port": "Port",
    "Heel": "Heel/Trim",
    "Trim": "",
    "DraftA": "Draft A/F",
    "DraftF": "",
    "TankType": "TYPE",
    "TankName": "TANK",
    "Capacity": "CAPACITY (M3)",
    "Sounding": "SOUNDING (M)",
    "Ullage": "ULLAGE (CM)",
    "Temp": "TEMP (DEG.)",
    "SG": "S.G@15 (T/M3)",
    "Sulphur": "% SULPHUR",
    "Volume": "VOLUME (M3)",
    "Weight": "WEIGHT (M.TON)",
    "Total": "TOTAL",
    "ConditionNote": "NOTE OF CONDITION",
    "Arrival": "ARRIVAL",
    "Departure": "DEPARTURE",
    "Inspection": "INSPECTION",
    "BeforeBunkering": "BEFORE BUNKERING",
    "AfterBunkering": "AFTER BUNKERING",
    "Remarks": "REMARKS",
    "Actual": "ACTUAL",
    "InLog": "IN LOG",
    "Difference": "DIFFERENCE"
  }
  : {
    "VoyageCode": "Voyage Code",
    "ReportTypeID": "ReportTypeID",
    "ReportTime": "ReportTime",
    "Port": "Port",
    "Heel": "Heel/Trim",
    "Trim": "",
    "DraftA": "Draft A/F",
    "DraftF": "",
    "TankType": "TYPE",
    "TankName": "TANK",
    "Capacity": "CAPACITY (M3)",
    "Sounding": "SOUNDING (M)",
    "Ullage": "ULLAGE (CM)",
    "Temp": "TEMP (DEG.)",
    "SG": "S.G@15 (T/M3)",
    "Sulphur": "% SULPHUR",
    "Volume": "VOLUME (M3)",
    "Weight": "WEIGHT (M.TON)",
    "Total": "TOTAL",
    "ConditionNote": "NOTE OF CONDITION",
    "Arrival": "ARRIVAL",
    "Departure": "DEPARTURE",
    "Inspection": "INSPECTION",
    "BeforeBunkering": "BEFORE BUNKERING",
    "AfterBunkering": "AFTER BUNKERING",
    "Remarks": "REMARKS",
    "Actual": "ACTUAL",
    "InLog": "IN LOG",
    "Difference": "DIFFERENCE"
  };
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class BunkerReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 7,
        ReportTime: null,
        HFO: [],
        DO: [],
        Arrival: null,
        Departure: null,
        Inspection: null,
        BeforeBunkering: null,
        AfterBunkering: null,
        Remarks: null,
        HFOInLog: null,
        DOInLog: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus
    };
  }

  componentDidMount() {
    this.loadTanks();
    this.loadRandomSample();
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }

  loadTanks() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: "DM_Tank",
        },
      })
      .then((response) => {
        if (Array.isArray(response.DM_Tank)) {
          this.setState(
            {
              after: {
                ...this.state.after,
                HFO: response.DM_Tank.filter(tank => tank.TankType == 'H.F.O').map(item => ({ TankType: item.TankType, TankName: item.TankName, Capacity: item.Capacity })),
                DO: response.DM_Tank.filter(tank => tank.TankType == 'D.O').map(item => ({ TankType: item.TankType, TankName: item.TankName, Capacity: item.Capacity })),
              }
            }
          );
        }
      })
      .catch((error) => {
        showError(error);
      });
  }

  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 7,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 7;
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode) ? report.VoyageCode : this.props.defaultVoyageCode;

          this.setState({
            after: {
              ...this.state.after,
              ...report
            },
            dataChanged: true,
          });
          console.log(this.state.after);

        })
        .catch((error) => {
          showError(error);
        });
    }
  }

  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveRecord = (tankType, record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after[tankType][index] = record;
    s.dataChanged = true;

    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.ReportTime,
              ReportStatus: 0,
              ReportContent: JSON.stringify(this.state.after),
              ParentReportID: null // when replace an existing report
            },
          })
          .then((response) => {
            this.setState({
              dataSaved: true,
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: ""
            });

            this.saveField("DailyReportID", response.OS_DailyReport[0].DailyReportID);

            alert("Cập nhật thành công!");
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved)
    }
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Port}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="Port"
                  key="Port"
                  fullWidth
                  value={this.state.after.Port}
                  onChange={event => {
                    this.saveField("Port", event.target.value);
                  }}
                  error={this.state.validateStatus.Port !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Heel}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Heel"
                  key="Heel"
                  fullWidth
                  decimalScale={1}
                  placeholder="Heel"
                  value={this.state.after.Heel}
                  onChange={value => {
                    this.saveField("Heel", value);
                  }}
                  error={this.state.validateStatus.Heel !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="Trim"
                  key="Trim"
                  fullWidth
                  decimalScale={2}
                  placeholder="Trim"
                  value={this.state.after.Trim}
                  onChange={value => {
                    this.saveField("Trim", value);
                  }}
                  error={this.state.validateStatus.Trim !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.DraftA}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="DraftA"
                  key="DraftA"
                  fullWidth
                  decimalScale={2}
                  placeholder="Draft A"
                  value={this.state.after.DraftA}
                  onChange={value => {
                    this.saveField("DraftA", value);
                  }}
                  error={this.state.validateStatus.DraftA !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="DraftF"
                  key="DraftF"
                  fullWidth
                  decimalScale={2}
                  placeholder="Draft F"
                  value={this.state.after.DraftF}
                  onChange={value => {
                    this.saveField("DraftF", value);
                  }}
                  error={this.state.validateStatus.DraftF !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TankType}</FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel>{fieldLabels.TankName}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Capacity}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Sounding}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Ullage}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Temp}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SG}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Sulphur}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Volume}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Weight}</FormLabel>
              </Grid>
            </Grid>
            {this.state.after.HFO.map((item, index) => (
              <Grid container spacing={1} columns={24}>
                <Grid item xs={2}>
                  <OutlinedInput
                    readOnly
                    fullWidth
                    value={item.TankType} />
                </Grid>
                <Grid item xs={5}>
                  <OutlinedInput
                    readOnly
                    fullWidth
                    value={item.TankName} />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    readOnly
                    fullWidth
                    decimalScale={2}
                    value={item.Capacity}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Sounding"
                    key="Sounding"
                    fullWidth
                    decimalScale={2}
                    placeholder="Sounding"
                    value={item.Sounding}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "Sounding", value);
                    }}
                    error={this.state.validateStatus.Sounding !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Ullage"
                    key="Ullage"
                    fullWidth
                    decimalScale={2}
                    placeholder="Ullage"
                    value={item.Ullage}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "Ullage", value);
                    }}
                    error={this.state.validateStatus.Ullage !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Temp"
                    key="Temp"
                    fullWidth
                    decimalScale={2}
                    placeholder="Temp"
                    value={item.Temp}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "Temp", value);
                    }}
                    error={this.state.validateStatus.Temp !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="SG"
                    key="SG"
                    fullWidth
                    decimalScale={2}
                    placeholder="SG"
                    value={item.SG}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "SG", value);
                    }}
                    error={this.state.validateStatus.SG !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Sulphur"
                    key="Sulphur"
                    fullWidth
                    decimalScale={2}
                    placeholder="Sulphur"
                    value={item.Sulphur}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "Sulphur", value);
                    }}
                    error={this.state.validateStatus.Sulphur !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Volume"
                    key="Volume"
                    fullWidth
                    decimalScale={2}
                    placeholder="Volume"
                    value={item.Volume}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "Volume", value);
                    }}
                    error={this.state.validateStatus.Volume !== undefined}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyNumberEditor
                    id="Weight"
                    key="Weight"
                    fullWidth
                    decimalScale={2}
                    placeholder="Weight"
                    value={item.Weight}
                    onChange={value => {
                      this.saveRecord('HFO', item, index, "Weight", value);
                    }}
                    error={this.state.validateStatus.Weight !== undefined}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={1} columns={24}>
              <Grid item xs={7}>
                <Grid container justifyContent="flex-end">
                  <FormLabel>{fieldLabels.Total}</FormLabel>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  readOnly
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.HFO.reduce((total, item) => total + item.Capacity, 0)} />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <FormLabel>{fieldLabels.Total}</FormLabel>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <MyNumberEditor
                  readOnly
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.HFO.reduce((total, item) => total + (item.Weight ?? 0), 0)} />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TankType}</FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel>{fieldLabels.TankName}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Capacity}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Sounding}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Ullage}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Temp}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SG}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Sulphur}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Volume}</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Weight}</FormLabel>
              </Grid>
            </Grid>
            {this.state.after.DO.map((item, index) => (
              <Grid container spacing={1} columns={24}>
                <Grid item xs={2}>
                  <OutlinedInput
                    readOnly
                    fullWidth
                    value={item.TankType} />
                </Grid>
                <Grid item xs={5}>
                  <OutlinedInput
                    readOnly
                    fullWidth
                    value={item.TankName} />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    readOnly
                    fullWidth
                    decimalScale={2}
                    value={item.Capacity}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Sounding"
                    key="Sounding"
                    fullWidth
                    decimalScale={2}
                    placeholder="Sounding"
                    value={item.Sounding}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "Sounding", value);
                    }}
                    error={this.state.validateStatus.Sounding !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Ullage"
                    key="Ullage"
                    fullWidth
                    decimalScale={2}
                    placeholder="Ullage"
                    value={item.Ullage}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "Ullage", value);
                    }}
                    error={this.state.validateStatus.Ullage !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Temp"
                    key="Temp"
                    fullWidth
                    decimalScale={2}
                    placeholder="Temp"
                    value={item.Temp}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "Temp", value);
                    }}
                    error={this.state.validateStatus.Temp !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="SG"
                    key="SG"
                    fullWidth
                    decimalScale={2}
                    placeholder="SG"
                    value={item.SG}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "SG", value);
                    }}
                    error={this.state.validateStatus.SG !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Sulphur"
                    key="Sulphur"
                    fullWidth
                    decimalScale={2}
                    placeholder="Sulphur"
                    value={item.Sulphur}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "Sulphur", value);
                    }}
                    error={this.state.validateStatus.Sulphur !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="Volume"
                    key="Volume"
                    fullWidth
                    decimalScale={2}
                    placeholder="Volume"
                    value={item.Volume}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "Volume", value);
                    }}
                    error={this.state.validateStatus.Volume !== undefined}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyNumberEditor
                    id="Weight"
                    key="Weight"
                    fullWidth
                    decimalScale={2}
                    placeholder="Weight"
                    value={item.Weight}
                    onChange={value => {
                      this.saveRecord('DO', item, index, "Weight", value);
                    }}
                    error={this.state.validateStatus.Weight !== undefined}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={1} columns={24}>
              <Grid item xs={7}>
                <Grid container justifyContent="flex-end">
                  <FormLabel>{fieldLabels.Total}</FormLabel>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  readOnly
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.DO.reduce((total, item) => total + item.Capacity, 0)} />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <FormLabel>{fieldLabels.Total}</FormLabel>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <MyNumberEditor
                  readOnly
                  fullWidth
                  decimalScale={2}
                  value={this.state.after.DO.reduce((total, item) => total + (item.Weight ?? 0), 0)} />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={15}>
                <Grid container>
                  <FormLabel>{fieldLabels.ConditionNote}</FormLabel>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={5}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.Arrival}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={19}>
                    <OutlinedInput
                      id="Arrival"
                      key="Arrival"
                      fullWidth
                      value={this.state.after.Arrival}
                      onChange={(event) => {
                        this.saveField("Arrival", event.target.value);
                      }}
                      error={this.state.validateStatus.Arrival !== undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={5}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.Departure}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={19}>
                    <OutlinedInput
                      id="Departure"
                      key="Departure"
                      fullWidth
                      value={this.state.after.Departure}
                      onChange={(event) => {
                        this.saveField("Departure", event.target.value);
                      }}
                      error={this.state.validateStatus.Departure !== undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={5}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.Inspection}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={19}>
                    <OutlinedInput
                      id="Inspection"
                      key="Inspection"
                      fullWidth
                      value={this.state.after.Inspection}
                      onChange={(event) => {
                        this.saveField("Inspection", event.target.value);
                      }}
                      error={this.state.validateStatus.Inspection !== undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={5}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.BeforeBunkering}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={19}>
                    <OutlinedInput
                      id="BeforeBunkering"
                      key="BeforeBunkering"
                      fullWidth
                      value={this.state.after.BeforeBunkering}
                      onChange={(event) => {
                        this.saveField("BeforeBunkering", event.target.value);
                      }}
                      error={this.state.validateStatus.BeforeBunkering !== undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={5}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.AfterBunkering}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={19}>
                    <OutlinedInput
                      id="AfterBunkering"
                      key="AfterBunkering"
                      fullWidth
                      value={this.state.after.AfterBunkering}
                      onChange={(event) => {
                        this.saveField("AfterBunkering", event.target.value);
                      }}
                      error={this.state.validateStatus.AfterBunkering !== undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={5}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.Remarks}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={19}>
                    <OutlinedInput
                      id="Remarks"
                      key="Remarks"
                      fullWidth
                      value={this.state.after.Remarks}
                      onChange={(event) => {
                        this.saveField("Remarks", event.target.value);
                      }}
                      error={this.state.validateStatus.Remarks !== undefined}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={7}>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="center">
                      <FormLabel>H.F.O</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="center">
                      <FormLabel>D.O</FormLabel>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.Actual}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <MyNumberEditor
                        readOnly
                        fullWidth
                        decimalScale={2}
                        value={this.state.after.HFO.reduce((total, item) => total + (item.Weight ?? 0), 0)} />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <MyNumberEditor
                        readOnly
                        fullWidth
                        decimalScale={2}
                        value={this.state.after.DO.reduce((total, item) => total + (item.Weight ?? 0), 0)} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.InLog}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <MyNumberEditor
                        id="HFOInLog"
                        key="HFOInLog"
                        fullWidth
                        decimalScale={2}
                        value={this.state.after.HFOInLog}
                        onChange={value => {
                          this.saveField('HFOInLog', value);
                        }}
                        error={this.state.validateStatus.HFOInLog !== undefined} />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <MyNumberEditor
                        id="DOInLog"
                        key="DOInLog"
                        fullWidth
                        decimalScale={2}
                        value={this.state.after.DOInLog}
                        onChange={value => {
                          this.saveField('DOInLog', value);
                        }}
                        error={this.state.validateStatus.DOInLog !== undefined} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} columns={24}>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <FormLabel>{fieldLabels.Difference}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <MyNumberEditor
                        readOnly
                        fullWidth
                        decimalScale={2}
                        value={this.state.after.HFO.reduce((total, item) => total + (item.Weight ?? 0), 0) - this.state.after.HFOInLog} />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container justifyContent="flex-end">
                      <MyNumberEditor
                        readOnly
                        fullWidth
                        decimalScale={2}
                        value={this.state.after.DO.reduce((total, item) => total + (item.Weight ?? 0), 0) - this.state.after.DOInLog} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved)
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  BunkerReportEditDialog
);
