/*Tao code lien quan de 1 table*/
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import {
  filterStyles,
  handleServerError,
  showError,
} from "../components/common";
import AsyncSelect from "react-select/async";
import { tr } from "date-fns/locale";
import { Cable } from "@mui/icons-material";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const validator = require("../data/DangKy_Validator");

const fields = [
  "MaCang",
  "MaKV",
  "TenCang",
  "TenCang_TA",
  "MoTa",
  "MoTa_TA",
  "LoaiCang",
  "PhiNeo",
  "PhiDV",
  "Website",
  "STT",
  "HoatDong",
];

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate_Data(data) {
  var ret = validate_Empty(data, ["TableName"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}
function getCode_CheckBox(field) {
  var str =
    '<FormControlLabel\n\
    id="chk#ColumnName#"\n\
    label="#ColumnName#"\n\
    labelPlacement="end"\n\
  fullWidth\n\
  control={\n\
    <Checkbox\n\
      size="medium"\n\
      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}\n\
      checked={this.state.after.#ColumnName#}\n\
      onChange={(event) =>\n\
        this.saveField(\n\
          "#ColumnName#",\n\
          event.target.checked)\n\
      }\n\
    />\n\
  }\n\
/>';
  str = str.replaceAll("#ColumnName#", field.ColumnName);
  
  
  str =
    
    "<Grid item xs={1}>" +
    str +
    "</Grid>";
  return str;
}
function getCode_DateTime(field) {
  var str =
    '<MyDateTimeEditor\n\
    id="txt#ColumnName#"\n\
  fullWidth\n\
  value={this.state.after.#ColumnName#}\n\
  onChange={(newValue) => {\n\
    this.saveField("#ColumnName#", newValue);\n\
  }}\n\
  {error}\
/>';
  str = str.replace("#ColumnName#", field.ColumnName);
  str = str.replace("#ColumnName#", field.ColumnName);
  str = str.replace("#ColumnName#", field.ColumnName);
  
  str = str.replace(
    "{error}",
    field.IsNullable
      ? ""
      : "error={this.state.validateStatus.#ColumnName# !== undefined}\n".replace(
          "#ColumnName#",
          field.ColumnName
        )
  );
  str =
    "<Grid item xs={1}>" +
    "<FormLabel>" +
    field.ColumnName +
    ":</FormLabel>" +
    "</Grid>" +
    "<Grid item xs={1}>" +
    str +
    "</Grid>";
  return str;
}
function getCode_Textbox(field, upper) {
  var str =
    '<OutlinedInput\n\
    id="txt#ColumnName#"\n\
  fullWidth\n\
  {maxLength}\
  value={this.state.after.#ColumnName#}\n\
  onChange={(event) => {\n\
    this.saveField("#ColumnName#", event.target.value);\n\
  }}\n\
  {error}\
/>';
  str = str.replace("#ColumnName#", field.ColumnName);
  str = str.replace("#ColumnName#", field.ColumnName);
  str = str.replace("#ColumnName#", field.ColumnName);
  str = str.replace(
    "{maxLength}",
    field.MaximumLength > 0
      ? "inputProps={{\n #upper#\n maxLength: 40,\n }}\n"
          .replace("40", field.MaximumLength)
          .replace(
            "#upper#",
            upper ? 'style: { textTransform: "uppercase" },\n' : ""
          )
      : "multiline\n"
  );
  str = str.replace(
    "{error}",
    field.IsNullable
      ? ""
      : "error={this.state.validateStatus.#ColumnName# !== undefined}\n".replace(
          "#ColumnName#",
          field.ColumnName
        )
  );
  str =
    "<Grid item xs={1}>" +
    "<FormLabel>" +
    field.ColumnName +
    ":</FormLabel>" +
    "</Grid>" +
    "<Grid item xs={1}>" +
    str +
    "</Grid>";
  return str;
}
function getCode_Number_Int(field) {
  var str =
    '<MyNumberEditor\n\
    id="txt#ColumnName#"\n\
  fullWidth\n\
  decimalScale={0}\n\
  value={this.state.after.#ColumnName#}\n\
  onValueChange={(value) => {\n\
    this.saveField("#ColumnName#", value);\n\
  }}\n\
  {error}\
/>';
  str = str.replaceAll("#ColumnName#", field.ColumnName);
  str = str.replace(
    "{maxLength}",
    field.MaximumLength > 0
      ? "inputProps={{\n\
    maxLength: 40,\n\
  }}\n".replace("40", field.MaximumLength)
      : "multiline"
  );
  str = str.replace(
    "{error}",
    field.IsNullable
      ? ""
      : "error={this.state.validateStatus.#ColumnName# !== undefined}\n".replace(
          "#ColumnName#",
          field.ColumnName
        )
  );
  str =
    "<Grid item xs={1}>" +
    "<FormLabel>" +
    field.ColumnName +
    ":</FormLabel>" +
    "</Grid>" +
    "<Grid item xs={1}>" +
    str +
    "</Grid>";
  return str;
}
function getCode_Number_Decimal(field) {
  var str =
    '<MyNumberEditor\n\
    id="txt#ColumnName#"\n\
  fullWidth\n\
  value={this.state.after.#ColumnName#}\n\
  onValueChange={(value) => {\n\
    this.saveField("#ColumnName#", value);\n\
  }}\n\
  {error}\
/>';
  str = str.replaceAll("#ColumnName#", field.ColumnName);
  str = str.replace(
    "{maxLength}",
    field.MaximumLength > 0
      ? "inputProps={{\n\
    maxLength: 40,\n\
  }}\n".replace("40", field.MaximumLength)
      : "multiline"
  );
  str = str.replace(
    "{error}",
    field.IsNullable
      ? ""
      : "error={this.state.validateStatus.#ColumnName# !== undefined}\n".replace(
          "#ColumnName#",
          field.ColumnName
        )
  );
  str =
    "<Grid item xs={1}>" +
    "<FormLabel>" +
    field.ColumnName +
    ":</FormLabel>" +
    "</Grid>" +
    "<Grid item xs={1}>" +
    str +
    "</Grid>";
  return str;
}
function getCode_ComboBox(field) {
  var str =
    '<SelectFilter\n\
    id="cbo#ColumnName#"\n\
  fullWidth\n\
  backspaceRemovesValue\n\
  options={this.state.DanhMuc.#LinkTableName#}\n\
  getOptionLabel={(item) => item.#LinkColumnLabel#}\n\
  getOptionValue={(item) => item.#LinkColumnKey#}\n\
  placeholder=""\n\
  styles={filterStyles}\n\
  menuPosition={"fixed"}\n\
  value={this.state.after}\n\
  isClearable\n\
  value={{#LinkColumnKey# : this.state.after.#ColumnName#, #LinkColumnLabel#: this.state.after.#LinkColumnLabel#}}\n\
  onChange={(item) => {\n\
    this.saveData({#ColumnName# : item ? item.#LinkColumnKey# : "", #LinkColumnLabel# : item ? item.#LinkColumnLabel# : ""});\n\
  }}\n\
  {error}\
/>';
  str = str.replaceAll("#LinkTableName#", field.LinkTableName);
  str = str.replaceAll("#LinkColumnLabel#", field.LinkColumnLabel);
  str = str.replaceAll("#LinkColumnKey#", field.LinkColumnKey);
  str = str.replaceAll("#ColumnName#", field.ColumnName);
  str = str.replace(
    "{maxLength}",
    field.MaximumLength > 0
      ? "inputProps={{\n\
    maxLength: 40,\n\
  }}\n".replace("40", field.MaximumLength)
      : "multiline\n"
  );
  str = str.replace(
    "{error}",
    field.IsNullable
      ? ""
      : "error={this.state.validateStatus.#ColumnName# !== undefined}\n".replace(
          "#ColumnName#",
          field.ColumnName
        )
  );
  str =
    "<Grid item xs={1}>" +
    "<FormLabel>" +
    field.ColumnName +
    ":</FormLabel>" +
    "</Grid>" +
    "<Grid item xs={1}>" +
    str +
    "</Grid>";
  return str;
}
function getCode_ComboBox_Async(field) {
  var str =
    '<AsyncSelect\n\
    id="cbo#ColumnName#"\n\
  fullWidth\n\
  cacheOptions\n\
  backspaceRemovesValue\n\
  loadOptions={(input, callback) => this.filter_#LinkTableName#(input, callback)}\n\
  getOptionLabel={(item) => item.#LinkColumnLabel#}\n\
  getOptionValue={(item) => item.#LinkColumnKey#}\n\
  placeholder=""\n\
  styles={filterStyles}\n\
  menuPosition={"fixed"}\n\
  value={this.state.after}\n\
  isClearable\n\
  value={{#LinkColumnKey# : this.state.after.#ColumnName#, #LinkColumnLabel#: this.state.after.#LinkColumnLabel#}}\n\
  onChange={(item) => {\n\
    this.saveData({#ColumnName# : item ? item.#LinkColumnKey# : "", #LinkColumnLabel# : item ? item.#LinkColumnLabel# : ""});\n\
  }}\n\
  {error}\
/>';
  str = str.replaceAll("#LinkTableName#", field.LinkTableName);
  str = str.replaceAll("#LinkColumnLabel#", field.LinkColumnLabel);
  str = str.replaceAll("#LinkColumnKey#", field.LinkColumnKey);
  str = str.replaceAll("#ColumnName#", field.ColumnName);
  str = str.replace(
    "{maxLength}",
    field.MaximumLength > 0
      ? "inputProps={{\n\
    maxLength: 40,\n\
  }}\n".replace("40", field.MaximumLength)
      : "multiline\n"
  );
  str = str.replace(
    "{error}",
    field.IsNullable
      ? ""
      : "error={this.state.validateStatus.#ColumnName# !== undefined}\n".replace(
          "#ColumnName#",
          field.ColumnName
        )
  );
  str =
    "<Grid item xs={1}>" +
    "<FormLabel>" +
    field.ColumnName +
    ":</FormLabel>" +
    "</Grid>" +
    "<Grid item xs={1}>" +
    "{this.state.DanhMuc.DM_VungBien.length > 0 ? (\n" +
    str +
    "): (<OutlinedInput disabled fullWidth />)}</Grid>";
  return str;
}
export default class GenerateCodeReactDialog extends React.Component {
  constructor(props) {
    super(props);
    this.doGenerate_ReactCode = this.doGenerate_ReactCode.bind(this);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {
        DanhSachTables: [],
        DanhSachFields: [], //danh sach truong thong tin cua table
      },
      after: {
        TableName: "",
        ColumnName: "",
        ScriptCode: "",
        casingUpper: false, //chuyen doi chu hoa thuong
        usingAsync: false, //su dung Async combobox hay khong
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  getColumnsGrid(columns) {
    //hien thi ten cot theo columns cua grid form tim kiem
    var str = "";
    columns.map((col) => {
      str += (str !== "" ? ";" : "") + col.name;
    });
    return str;
  }
  //lay du lieu danh muc
  loadDanhMuc() {
    //lay danh sach table
    server
      .post("Data/DoRequest", {
        Function: "Proc_Tool_Get_Tables",
        ThamSo: {},
      })
      .then((response) => {
        var danhsach = response.DanhSachTables;
        danhsach.map((item) => {
          item.label = item.TABLE_NAME;
          item.vaue = item.TABLE_NAME;
        });
        this.setState({
          source_danhmuc: {
            DanhSachTables: danhsach,
            DanhSachFields: [],
          },
        });
      })
      .catch((error) => handleServerError(error));
  }
  filterDanhSach = (danhsach, inputValue, callback) => {
    inputValue = inputValue ? inputValue.toUpperCase() : "";
    var i = 0;
    var count = 0;
    var list = [];
    for (; i < danhsach.length && count < 1000; i++) {
      var item = danhsach[i];
      if (
        inputValue === "" ||
        item.label.toUpperCase().indexOf(inputValue) >= 0
      ) {
        list.push(item);
        count++;
      }
    }
    callback(list);
  };
  saveField = (fieldName, value, callback) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    if (callback) {
      this.setState(s, () => callback());
    } else {
      if (fieldName == "ScriptCode") {
        navigator.clipboard.writeText(value);
      }
      this.setState(s);
    }
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doGenerate = (procedure) => {
    //generate code cho bien after dung cho form edit trong Reactjs
    try {
      var check = validate_Data(this.state.after);

      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: procedure,
            ThamSo: {
              TableName: this.state.after.TableName,
            },
          })
          .then((response) => {
            this.setState(
              {
                after: {
                  ...this.state.after,
                  ScriptCode: response.DataList
                    ? response.DataList[0]["Script"]
                    : "",
                },
                saving: false,
              },
              () => {
                navigator.clipboard.writeText(this.state.after.ScriptCode);
              }
            );
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  loadFields = () => {
    //generate code cho bien after dung cho form edit trong Reactjs
    try {
      var check = validate_Data(this.state.after);

      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_Generate_CodeReact",
            ThamSo: {
              TableName: this.state.after.TableName,
            },
          })
          .then((response) => {
            this.setState({
              source_danhmuc: {
                ...this.state.source_danhmuc,
                DanhSachFields: response.DataList,
              },
              saving: false,
            });
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      handleServerError(error);
    }
  };
  doGenerate_ReactCode() {
    //sinh code reactjs cho column name hien thoi

    if (this.state.after.ColumnName !== "") {
      var findCol = this.state.source_danhmuc.DanhSachFields.find(
        (item) => item.ColumnName == this.state.after.ColumnName
      );

      if (findCol) {
        console.log(findCol);
        //dua vao thong tin column de tao code react
        if (findCol.LinkTableName == null) {
          if (findCol.DataType == "varchar" || findCol.DataType == "nvarchar") {
            this.saveField("ScriptCode", getCode_Textbox(findCol, this.state.after.casingUpper));
          }
          if (findCol.DataType == "int") {
            //number
            this.saveField("ScriptCode", getCode_Number_Int(findCol));
          }
          if (findCol.DataType == "decimal") {
            //number
            this.saveField("ScriptCode", getCode_Number_Decimal(findCol));
          }
          if (findCol.DataType == "datetime") {
            //number
            this.saveField("ScriptCode", getCode_DateTime(findCol));
          }
          if (findCol.DataType == "bit") {
            //number
            this.saveField("ScriptCode", getCode_CheckBox(findCol));
          }
        } else {
          //combobox
          if (this.state.after.usingAsync) {
            this.saveField("ScriptCode", getCode_ComboBox_Async(findCol));
          } else {
            this.saveField("ScriptCode", getCode_ComboBox(findCol));
          }
        }
      }
    }
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Generate code</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>Main table (*):</FormLabel>
              </Grid>
              <Grid item xs={6}>
                <SelectFilter
                  id="cboQG_T"
                  fullWidth
                  backspaceRemovesValue
                  options={this.state.source_danhmuc.DanhSachTables}
                  getOptionLabel={(item) => item.TABLE_NAME}
                  getOptionValue={(item) => item.TABLE_NAME}
                  placeholder=""
                  styles={filterStyles}
                  menuPosition={"fixed"}
                  value={{ TABLE_NAME: this.state.after.TableName }}
                  isClearable
                  onChange={(item) => {
                    this.saveField(
                      "TableName",
                      item ? item.TABLE_NAME : "",
                      () => this.loadFields()
                    );
                  }}
                  error={this.state.validateStatus.TableName !== undefined}
                ></SelectFilter>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    this.loadFields();
                  }}
                  variant="contained"
                  disabled={this.state.after.TableName == ""}
                >
                  Load
                </Button>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  label="Async"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.after.usingAsync}
                      onChange={(event) =>
                        this.saveField("usingAsync", event.target.checked, () =>
                          this.doGenerate_ReactCode()
                        )
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  label="Upper"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.after.casingUpper}
                      onChange={(event) =>
                        this.saveField(
                          "casingUpper",
                          event.target.checked,
                          () => this.doGenerate_ReactCode()
                        )
                      }
                    />
                  }
                />
                
              </Grid>
              <Grid item xs={10}>
                {this.state.after.TableName == "" ||
                this.state.source_danhmuc.DanhSachFields.length == 0 ? null : (
                  <SelectFilter
                    id="cboQG_T"
                    fullWidth
                    backspaceRemovesValue
                    options={this.state.source_danhmuc.DanhSachFields}
                    getOptionLabel={(item) => item.ColumnName}
                    getOptionValue={(item) => item.ColumnName}
                    placeholder=""
                    styles={filterStyles}
                    menuPosition={"fixed"}
                    value={this.state.after}
                    isClearable
                    onChange={(item) => {
                      console.log(item);
                      this.saveField(
                        "ColumnName",
                        item ? item.ColumnName : "",
                        this.doGenerate_ReactCode
                      );
                    }}
                    error={this.state.validateStatus.ColumnName !== undefined}
                  ></SelectFilter>
                )}
              </Grid>
              <Grid item xs={24}>
                <OutlinedInput
                  fullWidth
                  
                  multiline
                  value={this.state.after.ScriptCode}
                  onChange={(event) =>
                    this.saveField("ScriptCode", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
