import * as React from "react";
import { styled, useTheme, withStyles, ThemeProvider } from "@mui/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ExploreIcon from "@mui/icons-material/Explore";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ForumIcon from "@mui/icons-material/Forum";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CodeIcon from "@mui/icons-material/Code";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Navigation } from "react-minimal-side-navigation";
import "./ReactMinimalSideNavigation.css";
//cac form
import MenuDanhMuc from "../components/MenuDanhMuc";
import MenuUser from "../components/MenuUser";
import LoginDialog from "../phanquyen/LoginDialog";

import ManageDataPage from "../templates/ManageDataPage";
import TempReportPage from "../pages/TempReportPage";
import CEReportPage from "../pages/CEReportPage";
import CodePage from "../chiphi/pages/CodePage";

import FormDefinePage from "../pages/FormDefinePage";
import ReportDataChonDialog from "../danhmuc/ReportDataChonDialog";
import ReportFormChonDialog from "../danhmuc/ReportFormChonDialog";
import ShipConfigDialog from "../pages/ShipConfigDialog";
///khac
import PhanMemDialog from "../phanquyen/PhanMemDialog";
import NhomQuyenDialog from "../phanquyen/NhomQuyenDialog";
import ChangePassDialog from "../phanquyen/ChangePassDialog";

import localConfig from "local-storage";

import { Stack } from "@mui/material";
import EmailConfigDialog from "../pages/EmailConfigDialog";
import { Help, HighlightSharp, MyLocation } from "@mui/icons-material";
import AlertDialog from "../components/AlertDialog";
import MenuHelp from "../components/MenuHelp";
import ShipSelectDialog from "../danhmuc/ShipSelectDialog";
import RepairRequisitionPage from "../chiphi/pages/RepairRequisitionPage";
import MenuShip from "../components/MenuShip";

const server = require("../lib/server");
const myLib = require("../lib/MyLib");
const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  content: {
    height: `${window.innerHeight - 75}px`,
    backgroundColor: `rgb(227,245,255)`,
  },
  loadingModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  logo: {
    width: 65,
    height: 65,
  },
});

function getSavedConfig() {
  let currentPage = localConfig.get("currentPage");
  let open = localConfig.get("drawerOpening");
  let shipName = localConfig.get("shipName");
  console.log(currentPage, shipName);
  var df = {
    currentPage:
      currentPage === undefined ||
      currentPage === null ||
      currentPage == "undefined"
        ? buildNavMenu()[0]
        : currentPage,
    drawerOpening1: open === undefined || open === null || open === "true",
    shipName: shipName && shipName !== "undefined" ? shipName : "",
  };
  console.log(df);
  return df;
}
function clearConfig() {
  localConfig.set("currentPage", null);
  localConfig.set("shipName", null);
}
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(16)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== "open";
  },
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: () => {
      return drawerWidth;
    },
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
/* Danh sach form chinh */
const ListOfChiphiForms = [
  {
    title: "Repair Requisition",
    titleVI: "Yêu cầu sửa chữa",
    shortTitle: "YCSC",
    shortTitleEN: "Repair Req.",
    id: "chiphi/repairRequisition",
    icon: <CodeIcon color="primary" />,
    Form: "CodePage",
  },
  {
    title: "Code",
    titleVI: "Code",
    shortTitle: "Code",
    shortTitleEN: "Code",
    id: "chiphi/code",
    icon: <CodeIcon color="primary" />,
    Form: "CodePage",
  },
];

function buildNavMenu() {
  return [
    {
      titleVI: "Ship Reports",
      title: "Ship Reports",
      shortTitle: "Báo cáo",
      shortTitleEN: "Reports",
      itemId: "tempReport",
      elemBefore: () => <SummarizeIcon color="primary" />,
      icon: <SummarizeIcon color="primary" />,
      id: "tempReport",
      subNav: [],
    },
    {
      title: "Repair. Req",
      itemId: "chiphi",
      elemBefore: () => <AttachMoneyIcon color="primary" />,
      subNav: ListOfChiphiForms.map((form) => {
        return {
          title: englishUI ? form.title : form.titleVI,
          shortTitle: form.shortTitle,
          shortTitleEN: form.shortTitleEN,
          itemId: form.id,
          id: form.id,
          elemBefore: () => form.icon,
          Form: form,
        };
      }),
    },
  ];
}

const englishUI = true;

/////////////////////////////--------------------////////////////////////////////

class MainPageRaw extends React.Component {
  constructor(props) {
    super(props);
    this.mainDiv = React.createRef();
    this.setMainDiv = (element) => {
      this.mainDiv = element;
      if (this.mainDiv) {
        this.setState({
          parentHeight: this.mainDiv.clientHeight,
        });
      }
    };
    this.state = {
      ...getSavedConfig(),
      showForms: true,
      showDanhMuc: false,
      drawerOpening: true,
      parentHeight: 200,
      IsDataReady: false,
      CurrentModule: {
        ShipID: 0,
        ShipCode: "",
        ShipName: "",
      },

      DanhSachTau: [],
      DanhSachMenu: buildNavMenu(), //danh sach item cau menu theo danh sach tau
      ActiveMenu: null, //menu duoc chon
    };
  }
  componentDidMount() {
    if (!this.state.userChecked) {
      this.doQuery();
      this.setState({ userChecked: true });
      //callback neu nhu token expire
      window.onUnAuthorize = () => {
        if (!this.state.showLogin) {
          this.setState({ showLogin: true });
        }
      };
    }
  }
  doQuery = () => {
    this.setState({
      loading: true,
    });
    server
      .query("User/GetUser", {})
      .then((response) => {
        global.User = response;
        this.setState({ checkingUser: false, userName: response.UserName });
        //hien thi form default
        //this.autoShowMdiForm();

        return server.post("Data/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: "DM_Ship",
          },
        });
      })
      .then((response) => {
        console.log(localConfig.get("drawerOpening"));
        const params = new URL(window.location).searchParams;
        let ShipCode = params.get("ShipCode");
        let found = response.DM_Ship.find((item) => item.ShipCode == ShipCode);
        if (!found) {
          found = response.DM_Ship[0];
        }

        this.state.shipName = found.ShipName;
        this.state.ShipCode = found.ShipCode;
        this.state.DanhSachTau = response.DM_Ship;
        this.state.CurrentShip = found;

        localConfig.set("shipName", this.state.CurrentShip.ShipName);
        global.ShipInfor = this.state.CurrentShip;
        global.ShipInfor.FMEnable = JSON.parse(global.ShipInfor.FMEnable); //thong tin enable nhap flowmeter
        this.doLoadSettings();
      })
      .catch((error) => {
        console.log(error);
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          var code = obj.code;
          if (code === 401) {
            //request login
            this.setState({
              checkingUser: false,
              showLogin: window.myconfig.localLogin,
              loading: false,
            });
            //window.location.assign(server.getPortalServer());
          } else {
            log = obj.traceLog;
            var log_detail = JSON.parse(log);
            this.setState({ message: log_detail.Message, loading: false });
          }
        } catch (e) {
          console.log(e);
          message = error + "";
          this.setState({ message: message, loading: false });
        }
      });
  };
  //thuc hien load setting validate fields
  doLoadSettings() {
    const list = [
      "DepartureReport",
      "NoonAtSeaReport",
      "ArrivalReport",
      "AllfastReport",
      "NoonInPortReport",
      "ShiftingReport",
      "ShiftingStartReport",
      "ShiftingNoonReport",
      "ShiftingFinishReport",
    ];
    list.map((report) => {
      server
        .get("/validate/" + report + ".json", {})
        .then((response) => {
          let enable = global.ShipInfor.FMEnable; //thong so enable FM va Slop
          //flow ME
          if (enable.FlowME) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("FlowME") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //flow AE
          if (enable.FlowAE) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("FlowAE") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //flow BLR
          if (enable.FlowBLR) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("FlowBLR") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //flow ME+GE
          if (enable.Flow2) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("Flow2") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //flow All
          if (enable.Flow3) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("Flow3") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //IG
          if (enable.IG) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("IG") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //Runh BLR
          if (enable.RunhBLR) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("RunhBLR") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          //slop
          if (enable.Slop) {
            let keys = Object.keys(response);
            keys.map((field) => {
              if (field.indexOf("Slop") >= 0) {
                response[field].allowEmpty = "false";
              }
            });
          }
          global[report] = response;
          this.setState({
            IsDataReady: true,
            loading: false,
          });
          //hien thi tent au
          document.title =
            global.ShipInfor.ShipCode + " - " + global.ShipInfor.ShipName;
        })

        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    });
  }
  handleDrawerOpenClose = () => {
    this.setState({ drawerOpening: !this.state.drawerOpening });
    localConfig.set("drawerOpening", !this.state.drawerOpening);
  };
  render() {
    const { theme, classes } = this.props;

    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={this.state.drawerOpening}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpenClose}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {(englishUI
                ? this.state.currentPage.title
                : this.state.currentPage.titleVI) +
                " - " +
                this.state.shipName}
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              <MenuDanhMuc
                title={"LIST"}
                onClick={(item) => {
                  if (item.name === "baocao_01") {
                    this.setState({ showBaoCao_01: true });
                  }
                  if (item.name === "ReportData") {
                    this.setState({ showReportData: true });
                  }
                  if (item.name === "ReportForm") {
                    this.setState({ showReportForm: true });
                  }
                  if (item.name === "config") {
                    this.setState({ showConfigForm: true });
                  }
                  if (item.name === "email") {
                    this.setState({
                      showUpdateMail: true,
                    });
                  }
                  //////////////////
                }}
              />
              <MenuHelp />
              {!this.state.checkingUser && !this.state.showLogin ? (
                <MenuUser
                  UserName={this.state.userName}
                  onClick={(action) => {
                    if (action === "logout") {
                      window.localStorage.setItem(window.JwtTokenTag, "");
                      window.sessionStorage.setItem(window.JwtTokenTag, "");

                      this.setState({
                        tabList: [],
                        showLogin: window.myconfig.localLogin,
                      });
                      /*
                    var url = server.getPortalServer();
                    if(url) {
                      window.location.assign(url);
                    }*/
                    }
                    if (action === "phanquyen") {
                      this.showMdiTab({
                        name: "phanquyen",
                        title: "Quản lý người dùng",
                      });
                    }
                    if (action === "phanmem") {
                      this.setState({ showPhanMem: true });
                    }
                    if (action === "nhomquyen") {
                      this.setState({ showNhomQuyen: true });
                    }
                    if (action === "password") {
                      this.setState({ showChangePass: true });
                    }
                    if (action === "version") {
                      clearConfig();
                    }
                  }}
                />
              ) : null}
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={this.state.drawerOpening}>
          <DrawerHeader>
            {this.state.drawerOpening ? (
              <>
                <MenuShip
                  DM_Ship={this.state.DanhSachTau}
                  onClick={(ship) => {
                    window.location =
                      window.location.protocol +
                      "//" +
                      window.location.hostname +
                      ":" +
                      window.location.port +
                      "/?ShipCode=" +
                      ship.ShipCode;
                  }}
                />
                <Stack style={{ paddingLeft: 5 }}>
                  <Typography variant="h6" component="div">
                    {this.state.shipName}
                  </Typography>
                </Stack>
              </>
            ) : null}
          </DrawerHeader>
          <Divider />
          {this.state.drawerOpening ? (
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId={
                this.state.ActiveMenu ? this.state.ActiveMenu.itemId : ""
              }
              onSelect={(item) => {
                // maybe push to the route

                if (item.itemId.indexOf("/") >= 0) {
                  var ShipID = item.itemId.split("/")[0];
                  var find = this.state.DanhSachMenu.find(
                    (c) => c.itemId == ShipID
                  );
                  //tim sub nav
                  var findSub = find.subNav.find(
                    (sub) => sub.itemId == item.itemId
                  );
                  this.setState({
                    ActiveMenu: item,
                    currentPage: findSub.Form,
                  });
                } else {
                  //default subnav
                  var find = this.state.DanhSachMenu.find(
                    (c) => c.itemId == item.itemId
                  );
                  var page = find.subNav.length ? find.subNav[0].Form : find;
                  localConfig.set("currentPage", page);

                  this.setState({
                    ActiveMenu: find.subNav.length ? find.subNav[0] : find,
                    currentPage: page,
                  });
                }
              }}
              items={this.state.DanhSachMenu}
            />
          ) : (
            <List>
              {buildNavMenu().map((navItem, index) => {
                let item = navItem.subNav.length
                  ? { ...navItem.subNav[0] }
                  : { ...navItem };
                console.log(item);
                return (
                  <ListItemButton
                    key={item.id}
                    selected={item.id == this.state.currentPage.id}
                    style={{ paddingLeft: 0 }}
                    onClick={() => {
                      localConfig.set("currentPage", item);
                      console.log(item);
                      this.setState({ currentPage: item });
                    }}
                  >
                    <Box
                      style1={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                      }}
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <ListItemIcon
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        {item.icon ? item.icon : <MailIcon color="primary" />}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          englishUI ? item.shortTitleEN : item.shortTitle
                        }
                        primaryTypographyProps={{ fontSize: "smaller" }}
                      />
                    </Box>
                  </ListItemButton>
                );
              })}
            </List>
          )}
          <Divider />
        </Drawer>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            p: 1,
            height: window.innerHeight - 75,
          }}
        >
          <DrawerHeader />
          <div className="main" ref={this.setMainDiv}>
            {this.mainDiv ? (
              this.state.currentPage && this.state.currentPage.id ? (
                !this.state.showLogin && !myLib.isEmpty(this.state.ShipCode) ? (
                  this.state.currentPage.id == "tempReport" ? (
                    <TempReportPage
                      key={
                        this.state.currentPage.id +
                        "/" +
                        this.state.CurrentShip.ShipCode
                      }
                      parentHeight={this.state.parentHeight}
                      ShipData={this.state.CurrentShip}
                    />
                  ) : this.state.currentPage.id == "chiphi/code" ? (
                    <CodePage
                      key={
                        this.state.currentPage.id +
                        "/" +
                        this.state.CurrentShip.ShipCode
                      }
                      parentHeight={this.state.parentHeight}
                    />
                  ) : this.state.currentPage.id ==
                    "chiphi/repairRequisition" ? (
                    <RepairRequisitionPage
                      key={
                        this.state.currentPage.id +
                        "/" +
                        this.state.CurrentShip.ShipCode
                      }
                      ShipData={this.state.CurrentShip}
                      parentHeight={this.state.parentHeight}
                    />
                  ) : (
                    <></>
                  )
                ) : null
              ) : null
            ) : null}
            {this.state.showDanhMuc ? (
              <ReportDataChonDialog
                open={true}
                disableChon={true}
                close={() => this.setState({ showDanhMuc: false })}
              />
            ) : null}
            {this.state.showLogin ? (
              <LoginDialog
                open={this.state.showLogin}
                close={() => {
                  this.setState(
                    {
                      showLogin: false,
                      userName: global.User.UserName,
                    },
                    () => this.doQuery()
                  );
                  //this.autoShowMdiForm(); //bo qua autoshow neu login lai
                }}
              />
            ) : null}
            {this.state.showReportData ? (
              <ReportDataChonDialog
                open={this.state.showReportData}
                disableChon={true}
                close={(ok) => {
                  this.setState({ showReportData: false });
                }}
              />
            ) : null}
            {this.state.showConfigForm ? (
              <ShipConfigDialog
                open={this.state.showConfigForm}
                close={() => {
                  this.setState({ showConfigForm: false });
                }}
              />
            ) : null}
            {this.state.showUpdateMail ? (
              <EmailConfigDialog
                ShipCode={this.state.ShipCode}
                open={this.state.showUpdateMail}
                close={() => {
                  this.setState({ showUpdateMail: false });
                }}
              />
            ) : null}
            {this.state.showReportForm ? (
              <ReportFormChonDialog
                open={this.state.showReportForm}
                disableChon={true}
                close={(ok) => {
                  this.setState({ showReportForm: false });
                }}
              />
            ) : null}
            {this.state.showPhanMem ? (
              <PhanMemDialog
                open={this.state.showPhanMem}
                close={() => {
                  this.setState({ showPhanMem: false });
                }}
              />
            ) : null}
            {this.state.showNhomQuyen ? (
              <NhomQuyenDialog
                open={this.state.showNhomQuyen}
                close={() => {
                  this.setState({ showNhomQuyen: false });
                }}
              />
            ) : null}

            {this.state.showChangePass ? (
              <ChangePassDialog
                open={this.state.showChangePass}
                close={() => {
                  this.setState({ showChangePass: false });
                }}
              />
            ) : null}
            {!this.state.IsDataReady && !this.state.showLogin ? (
              <AlertDialog
                open={true}
                OKRunning={this.state.loading}
                message={
                  this.state.loading
                    ? "Kết nối chưa sẵn sàng ...đang xử lý"
                    : "Kết nối chưa sẵn sàng, vui lòng thử lại !"
                }
                close={(saved) => {
                  if (!this.state.loading) {
                    this.doQuery();
                  }
                }}
              />
            ) : null}
          </div>
        </Box>
      </Box>
    );
  }
}

//const MainPageRawStyles = withStyles(styles, { withTheme: true })(MiniDrawer);
export default function ShipMainPage(props) {
  return (
    <ThemeProvider theme={{}}>
      <MainPageRaw />
    </ThemeProvider>
  );
}
