import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import MySelect from "../components/MySelect";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";
import FieldNameDialog from "./FieldNameDialog";

import MySelectFilter from "../components/MySelectFilter";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Cargo Report" : "Báo cáo hàng hóa";
const fieldLabels_EN = {
  VoyageCode: "Voyage:",
  Captain: "Captain:",
  ReportTypeID: "ReportTypeID",
  ReportTime: "Report time:",
  Port: "Port:",
  CallingPurpose: "Calling purpose",
  CargoName: "Cargo name",
  DischargeLastHours: "CARGO LOADED:",
  DischargeLastMT: "DISCHARGE LAST:",
  CargoHoldWise: "HOLDWISE:",
  BalanceToDischarge: "BALANCE TO LOAD/DISCHARGE:",
  BalanceHoldWise: "HOLDWISE:",
  DRAF: "DRAFT F/A:",
  ETCD: "ETC/D:",
  Workinghours: "WORKING HOURS:",
  NoOfShipCraneUsedAvailable: "NO. OF SHIP'S CRANE USED/AVAILABLE:",
  DelayBreakdownDamage: "DELAY/BREAKDOWN/DAMAGE:",
  Weather: "*WEATHER:",
  Ship: "*SHIP:",
  Shore: "*SHORE:",
};
const fieldLabels_VN = {
  VoyageCode: "VOY.",
  ReportTypeID: "ReportTypeID",
  ReportTime: "Report time",
  Captain: "Captain",
  Port: "Port",
  CallingPurpose: "Calling purpose",
  CargoName: "Cargo name",
  DischargeLastHours: "CARGO LOADED/DISCHARGE LAST",
  DischargeLastMT: "MT",
  CargoHoldWise: "HOLDWISE",
  BalanceToDischarge: "BALANCE TO LOAD/DISCHARGE",
  BalanceHoldWise: "HOLDWISE",
  DRAF: "DRAFT F/A",
  ETCD: "ETC/D",
  Workinghours: "WORKING HOURS",
  NoOfShipCraneUsedAvailable: "No. OF SHIP'S CRANE USED/AVAILABLE",
  DelayBreakdownDamage: "DELAY/BREAKDOWN/DAMAGE",
  Weather: "WEATHER",
  Ship: "SHIP",
  Shore: "SHORE",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Dữ liệu ngẫu nhiên",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnExport: "Export",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class CargoReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    let cap = localStorage.getItem("Report.Captain");
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { OS_DailyReport: [] },
      validateStatus: { error: false }, //trang thai validate

      after: {
        DailyReportID: 0,
        VoyageCode: props.defaultVoyageCode,
        CodeTrip: null,
        ReportTypeID: 8,
        ReportTime: null,
        Captain: cap ? cap : "",
        Port: null,
        CallingPurpose: null,
        CargoName: null,
        DischargeLastHours: null,
        DischargeLastMT: null,
        CargoHoldWise1: null,
        CargoHoldWise2: null,
        CargoHoldWise3: null,
        CargoHoldWise4: null,
        CargoHoldWise5: null,
        BalanceToDischarge: null,
        BalanceHoldWise1: null,
        BalanceHoldWise2: null,
        BalanceHoldWise3: null,
        BalanceHoldWise4: null,
        BalanceHoldWise5: null,
        ForeDraft: null,
        AftDraft: null,
        ETCD: null,
        Workinghours: null,
        NoOfShipCraneUsed: null,
        NoOfShipCraneAvailable: null,
        WeatherHours: null,
        Weather: null,
        ShipHours: null,
        Ship: null,
        ShoreHours: null,
        Shore: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      isCaptVersion: props.isCaptVersion,
      reportStatus: props.reportStatus,
      FMEnable: global.ShipInfor.FMEnable,
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }
  componentDidMount() {
    this.loadRandomSample();
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 8,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 8; //cargo report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: { ...this.state.after, ...report },
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.ReportTime,
              ReportStatus: reportStatus,
              ReportContent: JSON.stringify(this.state.after),
              AttachedReportID: null, // TODO: select bunker port
              ParentReportID:
                this.props.edittingRecord !== undefined
                  ? this.props.edittingRecord.ParentReportID
                  : null, // when replace an existing report
            },
          })
          .then((response) => {
            if (
              response.OS_DailyReport == undefined ||
              response.OS_DailyReport.length === 0
            ) {
              this.props.close(); //de phong truong hop co loi bat thuong xay ra
              return;
            }
            this.state.after.DailyReportID =
              response.OS_DailyReport[0].DailyReportID;
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
                reportStatus: reportStatus,
              },
              () => {
                //upload
                if (reportStatus == 1) {
                  this.doUpload();
                } else {
                  alert("Cập nhật thành công!");
                }
              }
            );
            //request ve server
            //server.doRequestProcess();
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-CR";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}

              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}

              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>1.</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "VoyageCode",
                    })
                  }
                >
                  {fieldLabels.VoyageCode}
                </FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Captain",
                    })
                  }
                >
                  {fieldLabels.Captain}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField(
                      "Captain",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>2.</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Port",
                    })
                  }
                >
                  {fieldLabels.Port}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Port"
                  key="Port"
                  fullWidth
                  autoFocus
                  value={this.state.after.Port}
                  onChange={(event) => {
                    this.saveField(
                      "Port",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.Port !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ReportTime",
                    })
                  }
                >
                  {fieldLabels.ReportTime}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "CallingPurpose",
                    })
                  }
                >
                  {fieldLabels.CallingPurpose}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MySelect
                  id={"CallingPurpose"}
                  key={"CallingPurpose"}
                  fullWidth
                  options={[
                    { label: "LOADING", value: 1 },
                    { label: "DISCHARGING", value: -1 },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  value={this.state.after.CallingPurpose}
                  onChange={(event) => {
                    this.saveField("CallingPurpose", event.target.value);
                  }}
                ></MySelect>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "CargoName",
                    })
                  }
                >
                  {fieldLabels.CargoName}
                </FormLabel>
              </Grid>
              
              <Grid item xs={4}>
                <OutlinedInput
                  id="CargoName"
                  key="CargoName"
                  fullWidth
                  placeholder=""
                  value={this.state.after.CargoName}
                  onChange={(event) => {
                    this.saveField(
                      "CargoName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.CargoName !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>3.</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "DischargeLastHours",
                    })
                  }
                >
                  {fieldLabels.DischargeLastHours}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="DischargeLastHours"
                  key="DischargeLastHours"
                  fullWidth
                  placeholder="hours"
                  decimalScale={1}
                  value={this.state.after.DischargeLastHours}
                  onChange={(value) => {
                    this.saveField("DischargeLastHours", value);
                  }}
                  error={
                    this.state.validateStatus.DischargeLastHours !== undefined
                  }
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.DischargeLastMT}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="DischargeLastMT"
                  key="DischargeLastMT"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.DischargeLastMT}
                  onChange={(value) => {
                    this.saveField("DischargeLastMT", value);
                  }}
                  error={
                    this.state.validateStatus.DischargeLastMT !== undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel></FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "CargoHoldWise",
                    })
                  }
                >
                  {fieldLabels.CargoHoldWise}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoHoldWise1"
                  key="HoldWise1"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.CargoHoldWise1}
                  onChange={(value) => {
                    this.saveField("CargoHoldWise1", value);
                  }}
                  error={this.state.validateStatus.CargoHoldWise1 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoHoldWise2"
                  key="CargoHoldWise2"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.CargoHoldWise2}
                  onChange={(value) => {
                    this.saveField("CargoHoldWise2", value);
                  }}
                  error={this.state.validateStatus.CargoHoldWise2 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoHoldWise3"
                  key="CargoHoldWise3"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.CargoHoldWise3}
                  onChange={(value) => {
                    this.saveField("CargoHoldWise3", value);
                  }}
                  error={this.state.validateStatus.CargoHoldWise3 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoHoldWise4"
                  key="CargoHoldWise4"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.CargoHoldWise4}
                  onChange={(value) => {
                    this.saveField("CargoHoldWise4", value);
                  }}
                  error={this.state.validateStatus.CargoHoldWise4 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoHoldWise5"
                  key="CargoHoldWise5"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.CargoHoldWise5}
                  onChange={(value) => {
                    this.saveField("CargoHoldWise5", value);
                  }}
                  error={this.state.validateStatus.CargoHoldWise5 !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>4.</FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "BalanceToDischarge",
                    })
                  }
                >
                  {fieldLabels.BalanceToDischarge}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BalanceToDischarge"
                  key="BalanceToDischarge"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.BalanceToDischarge}
                  onChange={(value) => {
                    this.saveField("BalanceToDischarge", value);
                  }}
                  error={
                    this.state.validateStatus.BalanceToDischarge !== undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel></FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "BalanceHoldWise",
                    })
                  }
                >
                  {fieldLabels.BalanceHoldWise}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BalanceHoldWise1"
                  key="HoldWise1"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.BalanceHoldWise1}
                  onChange={(value) => {
                    this.saveField("BalanceHoldWise1", value);
                  }}
                  error={
                    this.state.validateStatus.BalanceHoldWise1 !== undefined
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BalanceHoldWise2"
                  key="BalanceHoldWise2"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.BalanceHoldWise2}
                  onChange={(value) => {
                    this.saveField("BalanceHoldWise2", value);
                  }}
                  error={
                    this.state.validateStatus.BalanceHoldWise2 !== undefined
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BalanceHoldWise3"
                  key="BalanceHoldWise3"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.BalanceHoldWise3}
                  onChange={(value) => {
                    this.saveField("BalanceHoldWise3", value);
                  }}
                  error={
                    this.state.validateStatus.BalanceHoldWise3 !== undefined
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BalanceHoldWise4"
                  key="BalanceHoldWise4"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.BalanceHoldWise4}
                  onChange={(value) => {
                    this.saveField("BalanceHoldWise4", value);
                  }}
                  error={
                    this.state.validateStatus.BalanceHoldWise4 !== undefined
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="BalanceHoldWise5"
                  key="BalanceHoldWise5"
                  fullWidth
                  placeholder="MT"
                  decimalScale={1}
                  value={this.state.after.BalanceHoldWise5}
                  onChange={(value) => {
                    this.saveField("BalanceHoldWise5", value);
                  }}
                  error={
                    this.state.validateStatus.BalanceHoldWise5 !== undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>5.</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "DRAF",
                    })
                  }
                >
                  {fieldLabels.DRAF}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder="Draf F"
                  decimalScale={1}
                  value={this.state.after.ForeDraft}
                  onChange={(value) => {
                    this.saveField("ForeDraft", value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder="Draf A"
                  decimalScale={1}
                  value={this.state.after.AftDraft}
                  onChange={(value) => {
                    this.saveField("AftDraft", value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>6.</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ETCD",
                    })
                  }
                >
                  {fieldLabels.ETCD}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  id="ETCD"
                  key="ETCD"
                  fullWidth
                  value={this.state.after.ETCD}
                  onChange={(value) => {
                    this.saveField("ETCD", value);
                  }}
                  error={this.state.validateStatus.ETCD !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>7.</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Workinghours",
                    })
                  }
                >
                  {fieldLabels.Workinghours}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Workinghours"
                  key="Workinghours"
                  fullWidth
                  placeholder="hours"
                  decimalScale={1}
                  value={this.state.after.Workinghours}
                  onChange={(value) => {
                    this.saveField("Workinghours", value);
                  }}
                  error={this.state.validateStatus.Workinghours !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>8.</FormLabel>
              </Grid>
              <Grid item xs={7}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NoOfShipCraneUsedAvailable",
                    })
                  }
                >
                  {fieldLabels.NoOfShipCraneUsedAvailable}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="NoOfShipCraneUsed"
                  key="NoOfShipCraneUsed"
                  fullWidth
                  placeholder="used"
                  decimalScale={1}
                  value={this.state.after.NoOfShipCraneUsed}
                  onChange={(value) => {
                    this.saveField("NoOfShipCraneUsed", value);
                  }}
                  error={
                    this.state.validateStatus.NoOfShipCraneUsed !== undefined
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="NoOfShipCraneAvailable"
                  key="NoOfShipCraneAvailable"
                  fullWidth
                  placeholder="available"
                  decimalScale={1}
                  value={this.state.after.NoOfShipCraneAvailable}
                  onChange={(value) => {
                    this.saveField("NoOfShipCraneAvailable", value);
                  }}
                  error={
                    this.state.validateStatus.NoOfShipCraneAvailable !==
                    undefined
                  }
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}>
                <FormLabel>9.</FormLabel>
              </Grid>
              <Grid item xs={7}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "DelayBreakdownDamage",
                    })
                  }
                >
                  {fieldLabels.DelayBreakdownDamage}
                </FormLabel>
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Weather",
                    })
                  }
                >
                  {fieldLabels.Weather}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="WeatherHours"
                  key="WeatherHours"
                  fullWidth
                  placeholder="hours"
                  decimalScale={1}
                  value={this.state.after.WeatherHours}
                  onChange={(value) => {
                    this.saveField("WeatherHours", value);
                  }}
                  error={this.state.validateStatus.WeatherHours !== undefined}
                />
              </Grid>
              <Grid item xs={16}>
                <OutlinedInput
                  id="Weather"
                  key="Weather"
                  fullWidth
                  value={this.state.after.Weather}
                  onChange={(event) => {
                    this.saveField("Weather", event.target.value);
                  }}
                  error={this.state.validateStatus.Weather !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Ship",
                    })
                  }
                >
                  {fieldLabels.Ship}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ShipHours"
                  key="ShipHours"
                  fullWidth
                  placeholder="hours"
                  decimalScale={1}
                  value={this.state.after.ShipHours}
                  onChange={(value) => {
                    this.saveField("ShipHours", value);
                  }}
                  error={this.state.validateStatus.ShipHours !== undefined}
                />
              </Grid>
              <Grid item xs={16}>
                <OutlinedInput
                  id="Ship"
                  key="Ship"
                  fullWidth
                  value={this.state.after.Ship}
                  onChange={(event) => {
                    this.saveField("Ship", event.target.value);
                  }}
                  error={this.state.validateStatus.Ship !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={22}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "Shore",
                    })
                  }
                >
                  {fieldLabels.Shore}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ShoreHours"
                  key="ShoreHours"
                  fullWidth
                  placeholder="hours"
                  decimalScale={1}
                  value={this.state.after.ShoreHours}
                  onChange={(value) => {
                    this.saveField("ShoreHours", value);
                  }}
                  error={this.state.validateStatus.ShoreHours !== undefined}
                />
              </Grid>
              <Grid item xs={16}>
                <OutlinedInput
                  id="Shore"
                  key="Shore"
                  fullWidth
                  value={this.state.after.Shore}
                  onChange={(event) => {
                    this.saveField("Shore", event.target.value);
                  }}
                  error={this.state.validateStatus.Shore !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="CargoReportEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CargoReportEditDialog
);
