import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
import Dropzone from "react-dropzone";
import SyncIcon from "@mui/icons-material/Sync";
import moment from "moment";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import uuid from "react-uuid";
import { ConstructionOutlined } from "@mui/icons-material";
import MySelect from "../components/MySelect";
import LoggerDataDialog from "./LoggerDataDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Noon At Port Report"
  : "Báo cáo buổi trưa tại cảng";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time:",
      TimeNP: "Time Noon:",
      Position: "Position:",
      ETB: "ETB/ETD:",
      PortName: "Port:",
      VoyCode: "Pre Voy.:",
      VoyTime: "Finished at:",
      VoyRobFO: "R.O.B:",
      VoyRobDO: "",
      LacanWater: "Lacan:",
      SlopWater: "Slop:",
      FlowMENP: "BF - N:",
      FlowAENP: "AE FM",
      FlowBLRNP: "BLR FM",
      Flow3NP: "ALL FM",
      Flow2NP: "ME+GE FM",
      FlowTempNP: "T.FM:",
      ConsFOAENP: "BF - N:",
      ConsFOMENP: "ME/FO",
      ConsDOAENP: "",
      ConsFOBLRNP: "BLR",
      ConsDOBLRNP: "ConsDOBLRNP",
      ConsCylNP: "LO:",
      ConsSysNP: "",
      ConsAENP: "",
      ConsHydNP: "",
      FWPureNP: "FW Pur:",
      RobFONP: "ROB - N:",
      RobDONP: "DO",
      RobFWNP: "FW",
      RunhMENP: "BF - N:",
      RunhAENP: "Rnh: AE",
      RunhAE1NP: "AE1",
      RunhAE2NP: "AE2",
      RunhAE3NP: "AE3",
      RunhIGNP: "IG",
      RunhBLRNP: "BLR",
      ReceiveDOFO: "Receive:",
      RepairLog: "Repair log:",
      Remark: "Remark:",
      BDN: "BDN file:",
    }
  : {
      VoyageCode: "REF",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeNP: "Time NP",
      Position: "Position",
      ETB: "ETB/ETD",
      PortName: "Port",
      VoyCode: "VOY No.",
      VoyTime: "Finished at",
      VoyRobFO: "ROB",
      VoyRobDO: "",
      LacanWater: "Lacan Water",
      SlopWater: "Slop Water",
      FlowMENP: "ME FM",
      FlowAENP: "AE FM",
      FlowBLRNP: "BLR FM",
      Flow3NP: "ALL FM",
      Flow2NP: "ME+GE FM",
      FlowTempNP: "T. FM",
      ConsFOAENP: "Cons BF-N: AE",
      ConsDOAENP: "",
      ConsFOBLRNP: "BLR",
      ConsDOBLRNP: "ConsDOBLRNP",
      ConsCylNP: "LO (Cyl/Sys/AE/Hyd)",
      ConsSysNP: "",
      ConsAENP: "",
      ConsHydNP: "",
      FWPureNP: "FW Pur",
      RobFONP: "ROB:",
      RobDONP: "DO",
      RobFWNP: "FW",
      RunhMENP: "Rnh: ME",
      RunhAENP: "Runh: AE",
      RunhAE1NP: "AE1",
      RunhAE2NP: "AE2",
      RunhAE3NP: "AE3",
      RunhIGNP: "IG",
      RunhBLRNP: "BLR",
      ReceiveDOFO: "Receive:",
      RepairLog: "Repair log",
      Remark: "Remark",
      BDN: "BDN file", //luu y validate BDN khi co nhan nhien lieu neu ko co thi ko cho save
    };

const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
function hasReceive(receives) {
  var has = false;
  receives.map((item) => {
    has =
      has ||
      (!myLib.isEmpty(item.ReceiveDate) &&
        (item.FO ||
          item.DO ||
          item.LOCyl ||
          item.LOSys ||
          item.LOAE ||
          item.LOHyd));
  });
  return has;
}
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts) {
  console.log(data);
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.NoonInPortReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
    if (data.ReceiveDOFO.length > 0 && hasReceive(data.ReceiveDOFO)) {
      if (atts.length == 0) {
        ret.BunkerFileName = true;
      }
    }
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class NoonAtPortReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //da save du lieu chua
      validateStatus: { error: false }, //trang thai validate
      BDNFile: null, //file dinh kem
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 5,
        ReportTime: null,
        TimeNP: null,
        PosLat: null,
        PosLon: null,
        ETB: null,
        PortName: null,
        VoyCode: null,
        VoyTime: null,
        VoyRobFO: null,
        VoyRobDO: null,
        LacanWater: null,
        SlopWater: null,
        FlowMENP: null,
        FlowAENP: null,
        FlowBLRNP: null,
        Flow3NP: null,
        Flow2NP: null,
        FlowTempNP: null,
        ConsFOAENP: null,
        ConsDOAENP: null,
        ConsFOBLRNP: null,
        ConsDOBLRNP: null,
        ConsCylNP: null,
        ConsSysNP: null,
        ConsAENP: null,
        ConsHydNP: null,
        FWPureNP: null,
        RobFONP: null,
        RobDONP: null,
        RobFWNP: null,
        RunhMENP: null,
        RunhAE1NP: null,
        RunhAE2NP: null,
        RunhAE3NP: null,
        RunhIGNP: null,
        RunhBLRNP: null,
        ReceiveDOFO: [this.newRecord()],
        RepairLog: null,
        Remark: null,
        BDNFileID: null,
        BDNFileName: null,
        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined || !props.edittingRecord.Attachs
          ? []
          : props.edittingRecord.Attachs, // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.NP")),
      open_logger_data_dialog: false,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tra ve luong nhap vao
  getReceive(name) {
    var num = 0;
    this.state.after.ReceiveDOFO.map(
      (item) => (num += item.ReceiveDate && item[name] ? item[name] : 0)
    );
    return isNaN(num) ? 0 : num;
  }
  //tinh lai ROB
  calculateROB() {
    var s = this.state;
    let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
    if (!myLib.isEmpty(robFO)) {
      s.after.RobFONP =
        robFO -
        this.getConsValue(s.after.ConsFOAENP) -
        this.getConsValue(s.after.ConsFOBLRNP) +
        this.getReceive("FO");
    }
    let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
    if (!myLib.isEmpty(robDO)) {
      s.after.RobDONP =
        robDO -
        this.getConsValue(s.after.ConsDOAENP) -
        this.getConsValue(s.after.ConsDOPPNP) -
        this.getConsValue(s.after.ConsDOIGNP) -
        this.getConsValue(s.after.ConsDOBLRNP) +
        this.getReceive("DO");
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.NP");
      if (json && json !== "") {
        var draft = JSON.parse(json);
        console.log(draft);
        if (draft.VoyageCode == this.state.after.VoyageCode) {
          this.state.after = draft;
          this.state.after.ReportID = uuid();
          this.state.after.DailyReportID = 0; //dam bao la report moi

          this.setState({ saving: false });
        }
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 5,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 5; //noon report in port
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            report.VoyCode = null;
            report.VoyTime = null;
            report.VoyRobDO = report.VoyRobFO = null;
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          } else {
            //report.TimeNP = myLib.calculateNoonBefore(             new Date(report.ReportTime)            );
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value
    s.after[fieldName] = value;

    switch (fieldName) {
      case "ReportTime":
        if (value) {
          //s.after.TimeNP = myLib.calculateNoonBefore(value);
        }
        break;
      case "VoyRobFO":
      case "ConsFOAENP":
      case "ConsFOBLRNP":
        let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
        if (!myLib.isEmpty(robFO)) {
          s.after.RobFONP =
            robFO -
            this.getConsValue(s.after.ConsFOAENP) -
            this.getConsValue(s.after.ConsFOBLRNP) +
            this.getReceive("FO");
          console.log(
            robFO,
            this.getConsValue(s.after.ConsFOAENP),
            this.getConsValue(s.after.ConsFOBLRNP),
            this.getReceive("FO")
          );
        }
        break;
      case "VoyRobDO":
      case "ConsDOAENP":
      case "ConsDOBLRNP":
      case "ConsDOPPNP":
      case "ConsDOIGNP":
        let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
        if (!myLib.isEmpty(robDO)) {
          s.after.RobDONP =
            robDO -
            this.getConsValue(s.after.ConsDOAENP) -
            this.getConsValue(s.after.ConsDOPPNP) -
            this.getConsValue(s.after.ConsDOIGNP) -
            this.getConsValue(s.after.ConsDOBLRNP) +
            this.getReceive("DO");
        }
        break;
    }
    s.dataChanged = true;
    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;
    if (field.indexOf("FO") >= 0 || field == "ReceiveDate") {
      console.log(record);
      this.saveField("ConsFOAENP", s.after.ConsFOAENP);
    } else if (field.indexOf("DO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsDOAENP", s.after.ConsDOAENP);
    } else this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      LOAE: null,
      LOHyd: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };
  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.NP", JSON.stringify(this.state.after));
      }
      var check = validate_Data(this.state.after, this.state.Attachs);
      console.log(this.state.after, check);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeNP,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-NP";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {
                <Button
                  color="success"
                  variant="contained"
                  disabled={myLib.isEmpty(this.state.after.TimeNP)}
                  onClick={() =>
                    this.setState({ open_logger_data_dialog: true })
                  }
                >
                  {buttonLabels.btnLogger}
                </Button>
              }
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeNP)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeNP}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeNP"
                  key="TimeNP"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeNP}
                  onChange={(value) => {
                    this.saveField("TimeNP", value);
                  }}
                  error={this.state.validateStatus.TimeNP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ETB}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ETB"
                  key="ETB"
                  fullWidth
                  value={this.state.after.ETB}
                  onChange={(value) => {
                    this.saveField("ETB", value);
                  }}
                  error={this.state.validateStatus.ETB !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  placeholder=""
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={26}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyCode"
                  key="VoyCode"
                  fullWidth
                  placeholder=""
                  value={this.state.after.VoyCode}
                  onChange={(event) => {
                    this.saveField(
                      "VoyCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.VoyCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="VoyTime"
                  key="VoyTime"
                  fullWidth
                  value={this.state.after.VoyTime}
                  onChange={(value) => {
                    this.saveField("VoyTime", value);
                  }}
                  error={this.state.validateStatus.VoyTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyRobFO}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="VoyRobFO"
                  key="VoyRobFO"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.VoyRobFO}
                  onChange={(value) => {
                    this.saveField("VoyRobFO", value);
                  }}
                  error={this.state.validateStatus.VoyRobFO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="VoyRobDO"
                  key="VoyRobDO"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.VoyRobDO}
                  onChange={(value) => {
                    this.saveField("VoyRobDO", value);
                  }}
                  error={this.state.validateStatus.VoyRobDO !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.after.ReceiveDOFO.map((item, index) => (
              <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
                {index == 0 ? (
                  <>
                    <Grid item xs={2}>
                      <FormLabel
                        className={classes.quantrong}
                        onClick={() =>
                          this.setState({
                            show_thongtin_field: true,
                            Show_FieldName: "ReceiveDOFO",
                          })
                        }
                      >
                        {fieldLabels.ReceiveDOFO}
                      </FormLabel>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={2}></Grid>
                )}

                <Grid item xs={4}>
                  <MyDateEditor
                    id="ReceiveDOFODate"
                    key="ReceiveDOFODate"
                    fullWidth
                    value={item.ReceiveDate}
                    onChange={(value) => {
                      this.saveRecord(item, index, "ReceiveDate", value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFO"
                    key="ReceiveFO"
                    fullWidth
                    placeholder="FO"
                    value={item.FO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FO", value);
                    }}
                    error={this.state.validateStatus.ReceiveFO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveDO"
                    key="ReceiveDO"
                    fullWidth
                    placeholder="DO"
                    value={item.DO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "DO", value);
                    }}
                    error={this.state.validateStatus.ReceiveDO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOCyl"
                    key="ReceiveLOCyl"
                    fullWidth
                    placeholder="LO-Cyl"
                    decimalScale={0}
                    value={item.LOCyl}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOCyl", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOCyl !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOSys"
                    key="ReceiveLOSys"
                    fullWidth
                    placeholder="LO-Sys"
                    decimalScale={0}
                    value={item.LOSys}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOSys", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOSys !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOAE"
                    key="ReceiveLOAE"
                    fullWidth
                    placeholder="LO-AE"
                    decimalScale={0}
                    value={item.LOAE}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOAE", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOAE !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOHyd"
                    key="ReceiveLOHyd"
                    fullWidth
                    placeholder="LO-Hyd"
                    decimalScale={0}
                    value={item.LOHyd}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOHyd", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOHyd !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFW"
                    key="ReceiveFW"
                    fullWidth
                    placeholder="FW"
                    decimalScale={0}
                    value={item.FW}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FW", value);
                    }}
                    error={this.state.validateStatus.ReceiveFW !== undefined}
                  />
                </Grid>

                {index == 0 ? (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.addRecord();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.removeRecord(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={26}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.LacanWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="LacanWater"
                  key="LacanWater"
                  fullWidth
                  readOnly={this.state.FMEnable.Slop}
                  value={this.state.after.LacanWater}
                  onChange={(value) => {
                    this.saveField("LacanWater", value);
                  }}
                  error={this.state.validateStatus.LacanWater !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlopWater}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="SlopWater"
                  key="SlopWater"
                  fullWidth
                  readOnly={!this.state.FMEnable.Slop}
                  value={this.state.after.SlopWater}
                  onChange={(value) => {
                    this.saveField("SlopWater", value);
                  }}
                  error={this.state.validateStatus.SlopWater !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  F.M
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMENP"
                  key="FlowMENP"
                  fullWidth
                  placeholder="ME"
                  readOnly={!this.state.FMEnable.FlowME}
                  value={this.state.after.FlowMENP}
                  onChange={(value) => {
                    this.saveField("FlowMENP", value);
                  }}
                  error={this.state.validateStatus.FlowMENP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAENP"
                  key="FlowAENP"
                  fullWidth
                  placeholder="AE"
                  readOnly={!this.state.FMEnable.FlowAE}
                  value={this.state.after.FlowAENP}
                  onChange={(value) => {
                    this.saveField("FlowAENP", value);
                  }}
                  error={this.state.validateStatus.FlowAENP !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2NP"
                      key="Flow2NP"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2NP}
                      onChange={(value) => {
                        this.saveField("Flow2NP", value);
                      }}
                      error={this.state.validateStatus.Flow2NP !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRNP"
                      key="FlowBLRNP"
                      fullWidth
                      placeholder="BLR"
                      readOnly={!this.state.FMEnable.FlowBLR}
                      value={this.state.after.FlowBLRNP}
                      onChange={(value) => {
                        this.saveField("FlowBLRNP", value);
                      }}
                      error={this.state.validateStatus.FlowBLRNP !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRNP"
                      key="FlowBLRNP"
                      fullWidth
                      placeholder="BLR"
                      readOnly={!this.state.FMEnable.FlowBLR}
                      value={this.state.after.FlowBLRNP}
                      onChange={(value) => {
                        this.saveField("FlowBLRNP", value);
                      }}
                      error={this.state.validateStatus.FlowBLRNP !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3NP"
                      key="Flow3NP"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3NP}
                      onChange={(value) => {
                        this.saveField("Flow3NP", value);
                      }}
                      error={this.state.validateStatus.Flow3NP !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempNP"
                  key="FlowTempNP"
                  fullWidth
                  value={this.state.after.FlowTempNP}
                  onChange={(value) => {
                    this.saveField("FlowTempNP", value);
                  }}
                  error={this.state.validateStatus.FlowTempNP !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhMENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMENP"
                  key="RunhMENP"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMENP}
                  onChange={(value) => {
                    this.saveField("RunhMENP", value);
                  }}
                  error={this.state.validateStatus.RunhMENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1NP"
                  key="RunhAE1NP"
                  fullWidth
                  placeholder={fieldLabels.RunhAE1NP}
                  value={this.state.after.RunhAE1NP}
                  onChange={(value) => {
                    this.saveField("RunhAE1NP", value);
                  }}
                  error={this.state.validateStatus.RunhAE1NP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2NP"
                  key="RunhAE2NP"
                  fullWidth
                  placeholder={fieldLabels.RunhAE2NP}
                  value={this.state.after.RunhAE2NP}
                  onChange={(value) => {
                    this.saveField("RunhAE2NP", value);
                  }}
                  error={this.state.validateStatus.RunhAE2NP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3NP"
                  key="RunhAE3NP"
                  fullWidth
                  placeholder={fieldLabels.RunhAE3NP}
                  value={this.state.after.RunhAE3NP}
                  onChange={(value) => {
                    this.saveField("RunhAE3NP", value);
                  }}
                  error={this.state.validateStatus.RunhAE3NP !== undefined}
                />
              </Grid>

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1NP"
                    key="RunhPP1NP"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1NP}
                    onChange={(value) => {
                      this.saveField("RunhPP1NP", value);
                    }}
                    error={this.state.validateStatus.RunhPP1NP !== undefined}
                  />
                </Grid>
              ) : null}

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2NP"
                    key="RunhPP2NP"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2NP}
                    onChange={(value) => {
                      this.saveField("RunhPP2NP", value);
                    }}
                    error={this.state.validateStatus.RunhPP2NP !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGNP"
                    key="RunhIGNP"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGNP}
                    onChange={(value) => {
                      this.saveField("RunhIGNP", value);
                    }}
                    error={this.state.validateStatus.RunhIGNP !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRNP"
                    key="RunhBLRNP"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRNP}
                    onChange={(value) => {
                      this.saveField("RunhBLRNP", value);
                    }}
                    error={this.state.validateStatus.RunhBLRNP !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAENP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMENP"
                  key="ConsFOMENP"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMENP}
                  onChange={(value) => {
                    this.saveField("ConsFOMENP", value);
                  }}
                  error={this.state.validateStatus.ConsFOMENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMENP"
                  key="ConsDOMENP"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMENP}
                  onChange={(value) => {
                    this.saveField("ConsDOMENP", value);
                  }}
                  error={this.state.validateStatus.ConsDOMENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAENP"
                  key="ConsFOAENP"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAENP}
                  onChange={(value) => {
                    this.saveField("ConsFOAENP", value);
                  }}
                  error={this.state.validateStatus.ConsFOAENP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAENP"
                  key="ConsDOAENP"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAENP}
                  onChange={(value) => {
                    this.saveField("ConsDOAENP", value);
                  }}
                  error={this.state.validateStatus.ConsDOAENP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRNP"
                  key="ConsFOBLRNP"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRNP}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRNP", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRNP"
                  key="ConsDOBLRNP"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRNP}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRNP", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRNP !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                {(this.state.FMEnable.IG &&
                  myLib.isEmpty(this.state.FMEnable.PP)) ||
                this.state.FMEnable.PP ? (
                  <MyNumberEditor
                    id="ConsDOPPNP"
                    key="ConsDOPPNP"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPNP}
                    onChange={(value) => {
                      this.saveField("ConsDOPPNP", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPNP !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                {this.state.FMEnable.IG ? (
                  <MyNumberEditor
                    id="ConsDOIGNP"
                    key="ConsDOIGNP"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGNP}
                    onChange={(value) => {
                      this.saveField("ConsDOIGNP", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGNP !== undefined}
                  />
                ) : null}
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylNP}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylNP"
                  key="ConsCylNP"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylNP}
                  onChange={(value) => {
                    this.saveField("ConsCylNP", value);
                  }}
                  error={this.state.validateStatus.ConsCylNP !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysNP"
                  key="ConsSysNP"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysNP}
                  onChange={(value) => {
                    this.saveField("ConsSysNP", value);
                  }}
                  error={this.state.validateStatus.ConsSysNP !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAENP"
                  key="ConsAENP"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAENP}
                  onChange={(value) => {
                    this.saveField("ConsAENP", value);
                  }}
                  error={this.state.validateStatus.ConsAENP !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydNP"
                  key="ConsHydNP"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydNP}
                  onChange={(value) => {
                    this.saveField("ConsHydNP", value);
                  }}
                  error={this.state.validateStatus.ConsHydNP !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFONP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFONP"
                  key="RobFONP"
                  fullWidth
                  placeholder={"FO"}
                  value={this.state.after.RobFONP}
                  onChange={(value) => {
                    this.saveField("RobFONP", value);
                  }}
                  error={this.state.validateStatus.RobFONP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDONP"
                  key="RobDONP"
                  fullWidth
                  placeholder={"DO"}
                  value={this.state.after.RobDONP}
                  onChange={(value) => {
                    this.saveField("RobDONP", value);
                  }}
                  error={this.state.validateStatus.RobDONP !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWNP"
                  key="RobFWNP"
                  fullWidth
                  placeholder={"FW"}
                  value={this.state.after.RobFWNP}
                  onChange={(value) => {
                    this.saveField("RobFWNP", value);
                  }}
                  error={this.state.validateStatus.RobFWNP !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureNP}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureNP"
                  key="FWPureNP"
                  fullWidth
                  decimalScale={1}
                  value={this.state.after.FWPureNP}
                  onChange={(value) => {
                    this.saveField("FWPureNP", value);
                  }}
                  error={this.state.validateStatus.FWPureNP !== undefined}
                />
              </Grid>
              <Grid item xs={20}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RepairLog}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="RepairLog"
                  key="RepairLog"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.RepairLog}
                  onChange={(event) => {
                    this.saveField("RepairLog", event.target.value);
                  }}
                  error={this.state.validateStatus.RepairLog !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  multiline
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.BDN}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={(event) => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.open_logger_data_dialog ? (
          <LoggerDataDialog
            open={true}
            Data={{
              ThoiGian1: null,
              ThoiGian2: this.state.after.TimeNP,
            }}
            close={(data) => {
              if (data) {
                //ghi nhan gia tri lay tu logger

                var after = this.state.after;
                after.Position =
                  data.Lat2 != 0 && data.Lon2 != 0
                    ? geoLib.latToDMS(data.Lat2) +
                      ", " +
                      geoLib.lonToDMS(data.Lon2)
                    : "";
                after.RpmCounterMENP = data.RpmCounter2;
                after.FlowMENP = data.FlowmeterME2;
                after.FlowAENP = data.FlowmeterAE2;
                after.FlowBLRNP = data.FlowmeterBLR2;
                after.FlowTempNP = data.FlowTemp2;
                after.Distance = data.Distance ? data.Distance : after.Distance;
              }
              this.setState({ open_logger_data_dialog: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  NoonAtPortReportEditDialog
);
