import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import Dropzone from "react-dropzone";
import uuid from "react-uuid";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
import SelectFilter from "react-select";
import moment from "moment";
/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import SyncIcon from "@mui/icons-material/Sync";
import MySelect from "../components/MySelect";
import LoggerDataDialog from "./LoggerDataDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Shifting Report" : "Báo cáo tàu di chuyển";
const fieldLabels = englishUI
  ? {
      Captain: "CAPT:",
      VoyageCode: "Voyage:",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time:",
      TimeSS: "Time SS:",
      TimeFS: "Time FS:",
      Position: "Position:",
      VoyCode: "Pre Voy.:",
      VoyTime: "Finished at:",
      VoyRobFO: "R.O.B:",
      VoyRobDO: "",
      ReceiveDOFO: "Receive:",
      Tugs: "Tug:",
      TugTime: "Hrs:",
      TugPower: "Power:",
      PortName: "From:",
      PortName2: "To:",
      ForeDraft: "Draft:",
      AftDraft: "",
      CargoMT: "Cargo",
      CargoTon: "Cargo:",
      CargoName: "name",
      PilotTime: "Pilot on:",
      PilotTime2: "Pilot Of:",
      ShiftDistance: "Distance:",
      RobSS: "ROB - SS:",
      RobFOSS: "FO",
      RobDOSS: "DO",
      RobFWSS: "FW",

      RobFS: "ROB - FS:",
      RobFOFS: "FO",
      RobDOFS: "DO",
      RobFWFS: "FW",
      RpmCounterMESS: "BF - SS:",
      FlowMESS: "F.M:",
      FlowAESS: "",
      FlowBLRSS: "",
      Flow3SS: "",
      FlowTempSS: "T.FM/ME:",
      METempSS: "T.ME:",
      RpmCounterMEFS: "SS - FS:",
      FlowMEFS: "F.M:",
      FlowAEFS: "",
      FlowBLRFS: "",
      Flow3FS: "",
      FlowTempFS: "T.FM/ME:",
      METempFS: "T.ME:",
      ConsFOAESS: "BF - SS:",
      ConsDOAESS: "",
      ConsFOBLRSS: "BLR",
      ConsDOBLRSS: "",
      ConsCylSS: "LO:",
      ConsSysSS: "",
      ConsAESS: "",
      ConsHydSS: "",
      FWPureSS: "FW Pur:",
      ConsFOMEFS: "SS - FS:",
      ConsDOMEFS: "DO/ME",
      ConsFOAEFS: "AE",
      ConsDOAEFS: "AE/DO",
      ConsFOBLRFS: "BLR",
      ConsDOBLRFS: "BLR/DO",
      FWPureFS: "FW Pur:",
      ConsCylFS: "LO:",
      ConsSysFS: "",
      ConsAEFS: "",
      ConsHydFS: "",
      FWPureFS: "FW Pur:",
      RunhAE1SS: "BF - SS:",
      RunhAE2SS: "",
      RunhAE3SS: "",
      RunhIGSS: "IG",
      RunhBLRSS: "BLR",
      RunhMEFS: "ME",
      RunhAE1FS: "SS - FS:",
      RunhAE2FS: "",
      RunhAE3FS: "",
      RunhIGFS: "IG",
      RunhBLRFS: "BLR",
      Remark: "Remark:",
    }
  : {
      VoyageCode: "REF",
      ReportTypeID: "ReportTypeID",
      ReportTime: "Time",
      TimeSS: "Time SS",
      TimeFS: "Time FS",
      Position: "Position",
      Tugs: "Tugs used/hours/power",
      TugTime: "",
      TugPower: "",
      PortName: "From",
      PortName2: "to",
      VoyCode: "VOY No.",
      VoyTime: "Finished at",
      VoyRobFO: "ROB",
      VoyRobDO: "",
      ForeDraft: "Draft F/A",
      AftDraft: "",
      CargoMT: "Cargo",
      ShiftDistance: "Shift Distance",
      RobSS: "ROB-SS:",
      RobFOSS: "FO",
      RobDOSS: "DO",
      RobFWSS: "FW",
      RobFS: "ROB-S.FN:",
      RobFOFS: "FO",
      RobDOFS: "DO",
      RobFWFS: "FW",
      RpmCounterMESS: "M/E RPM CTR SS",
      FlowMESS: "F.M ME/AE/BLR/ALL",
      FlowAESS: "",
      FlowBLRSS: "",
      Flow3SS: "",
      FlowTempSS: "Temp F.M/ME",
      METempSS: "",
      RpmCounterMEFS: "M/E RPM CTR FS",
      FlowMEFS: "F.M ME/AE/BLR/ALL",
      FlowAEFS: "",
      FlowBLRFS: "",
      Flow3FS: "",
      FlowTempFS: "Temp F.M/ME",
      METempFS: "",
      ConsFOAESS: "Cons(BF-S.ST): AE",
      ConsDOAESS: "",
      ConsFOBLRSS: "BLR",
      ConsDOBLRSS: "",
      ConsCylSS: "LO",
      ConsSysSS: "",
      ConsAESS: "",
      ConsHydSS: "",
      FWPureSS: "FW Pur",
      ConsFOMEFS: "Cons(S.ST-S.FN):ME",
      ConsDOMEFS: "DO/ME",
      ConsFOAEFS: "AE",
      ConsDOAEFS: "AE/DO",
      ConsFOBLRFS: "BLR",
      ConsDOBLRFS: "BLR/DO",
      ConsCylFS: "LO:",
      ConsSysFS: "",
      ConsAEFS: "",
      ConsHydFS: "",
      FWPureFS: "FW Pur",
      RunhAE1SS: "Running.Hr(BF-S.ST): AE1/AE2/AE3",
      RunhAE2SS: "",
      RunhAE3SS: "",
      RunhIGSS: "IG",
      RunhBLRSS: "BLR",
      RunhMEFS: "ME",
      RunhAE1FS: "Running.Hr(S.ST-S.FN): ME/AE1/AE2/AE3",
      RunhAE2FS: "",
      RunhAE3FS: "",
      RunhIGFS: "IG",
      RunhBLRFS: "BLR",
      Remark: "Remark",
    };
const buttonLabels = englishUI
  ? {
      btnLoadRandomSample: "Random sample",
      btnSave: "Save",
      btnSend: "Send",
      btnClose: "Close",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    }
  : {
      btnLoadRandomSample: "Random sample",
      btnSave: "Lưu",
      btnSend: "Gửi",
      btnClose: "Thoát",
      btnRob: "Load ROB",
      btnDraft: "Load Draft",
      btnLogger: "From Logger",
      btnExport: "Export",
      btnEmail: "Email",
    };
const CodeTripList = [
  {
    label: "N",
    value: "N",
  },
  {
    label: "S",
    value: "S",
  },
];
//ham filter su dung cho combobox
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, global.ShiftingReport);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }
  if (Object.keys(ret).length == 0) {
    //kiem tra thoi gian
    if (new Date(data.TimeFS).getTime() <= new Date(data.TimeSS).getTime()) {
      ret.TimeFS = true;
    }
  }
  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class ShiftingReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        Captain: localStorage.getItem("Report.Captain"),
        VoyageCode: null,
        CodeTrip: null,
        ReportTypeID: 6,
        ReportTime: null,
        TimeSS: null,
        TimeFS: null,
        Position: null,
        Tugs: null,
        TugTime: null,
        TugPower: null,
        PortName: null,
        PortName2: null,
        VoyCode: null,
        VoyTime: null,
        VoyRobFO: null,
        VoyRobDO: null,
        ForeDraft: null,
        AftDraft: null,
        CargoMT: null,
        ShiftDistance: null,
        RobFOFS: null,
        RobDOFS: null,
        RobLOFS: null,
        RobFWFS: null,
        RpmCounterMESS: null,
        FlowMESS: null,
        FlowAESS: null,
        FlowBLRSS: null,
        Flow3SS: null,
        Flow2SS: null,
        FlowTempSS: null,
        METempSS: null,
        RpmCounterMEFS: null,
        FlowMEFS: null,
        FlowAEFS: null,
        FlowBLRFS: null,
        Flow3FS: null,
        Flow2FS: null,
        FlowTempFS: null,
        METempFS: null,
        ConsFOMESS: null,
        ConsDOMESS: null,
        ConsFOAESS: null,
        ConsDOAESS: null,
        ConsFOBLRSS: null,
        ConsDOBLRSS: null,
        ConsCylSS: null,
        ConsSysSS: null,
        ConsAESS: null,
        ConsHydSS: null,
        FWPureSS: null,
        ConsFOMEFS: null,
        ConsDOMEFS: null,
        ConsFOAEFS: null,
        ConsDOAEFS: null,
        ConsFOBLRFS: null,
        ConsDOBLRFS: null,
        ConsCylFS: null,
        ConsSysFS: null,
        ConsAEFS: null,
        ConsHydFS: null,
        FWPureFS: null,
        RunhMESS: null,
        RunhAE1SS: null,
        RunhAE2SS: null,
        RunhAE3SS: null,
        RunhIGSS: null,
        RunhMEFS: null,
        RunhAE1FS: null,
        RunhAE2FS: null,
        RunhAE3FS: null,
        RunhIGFS: null,
        Remark: null,

        ReceiveDOFO: [this.newRecord()],

        Container: global.ShipInfor.FMEnable.Container ? "Cont" : "", //cho biet co phai tau container khong, phuc vu cho validate
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
      Attachs:
        props.edittingRecord == undefined
          ? []
          : props.edittingRecord.Attachs
          ? props.edittingRecord.Attachs
          : [], // danh sach attach file
      FMEnable: global.ShipInfor.FMEnable, //thong tin cau hinh cua danh muc tau
      LastROB: {
        RobFO: 0,
        RobDO: 0,
        RobCyl: 0,
        RobSys: 0,
        RobAE: 0,
        RobHyd: 0,
      },
      HasLocalData: !myLib.isEmpty(localStorage.getItem("Report.SR")),
      open_logger_data_dialog: false, //hien thi form hien thi data tu logger
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //load rob tu bao cao truoc
  loadROB(time) {
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_OS_DailyReport_GetRobTruoc",
          ThamSo: {
            VoyageCode: this.props.defaultVoyageCode,
            ReportDataTime: time,
          },
        })
        .then((response) => {
          let rob = response.ROB.length ? response.ROB[0] : undefined;
          if (rob) {
            this.state.LastROB = rob;
            this.calculateROB();
            this.setState({
              LastROB: rob,
            });
          }
        });
    }
  }
  //tra ve luong nhap vao
  getReceive(name) {
    var num = 0;
    this.state.after.ReceiveDOFO.map(
      (item) => (num += item.ReceiveDate && item[name] ? item[name] : 0)
    );
    return isNaN(num) ? 0 : num;
  }
  //tinh lai rob
  calculateROB() {
    var s = this.state;
    let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
    if (!myLib.isEmpty(robFO)) {
      s.after.RobFOSS =
        robFO -
        this.getConsValue(s.after.ConsFOAESS) -
        this.getConsValue(s.after.ConsFOBLRSS) +
        this.getReceive("FO");

      s.after.RobFOFS =
        s.after.RobFOSS -
        this.getConsValue(s.after.ConsFOMEFS) -
        this.getConsValue(s.after.ConsFOAEFS) -
        this.getConsValue(s.after.ConsFOBLRFS);
    }
    let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
    if (!myLib.isEmpty(robDO)) {
      s.after.RobDOSS =
        robDO -
        this.getConsValue(s.after.ConsDOAESS) -
        this.getConsValue(s.after.ConsDOPPSS) -
        this.getConsValue(s.after.ConsDOIGSS) -
        this.getConsValue(s.after.ConsDOBLRSS) +
        this.getReceive("DO");

      s.after.RobDOFS =
        s.after.RobDOSS -
        this.getConsValue(s.after.ConsDOMEFS) -
        this.getConsValue(s.after.ConsDOAEFS) -
        this.getConsValue(s.after.ConsDOPPFS) -
        this.getConsValue(s.after.ConsDOIGFS) -
        this.getConsValue(s.after.ConsDOBLRFS);
    }
    if (!myLib.isEmpty(s.after.RobFOSS)) {
      s.after.RobFOFS =
        s.after.RobFOSS -
        this.getConsValue(s.after.ConsFOMEFS) -
        this.getConsValue(s.after.ConsFOAEFS) -
        this.getConsValue(s.after.ConsFOBLRFS);
    }
    if (!myLib.isEmpty(s.after.RobDOSS)) {
      s.after.RobDOFS =
        s.after.RobDOSS -
        this.getConsValue(s.after.ConsDOMEFS) -
        this.getConsValue(s.after.ConsDOAEFS) -
        this.getConsValue(s.after.ConsDOPPFS) -
        this.getConsValue(s.after.ConsDOIGFS) -
        this.getConsValue(s.after.ConsDOBLRFS);
    }
  }
  getConsValue(value) {
    return value == undefined || value == null ? 0 : value;
  }
  //load draft tu local storeage
  loadDraft() {
    try {
      let json = localStorage.getItem("Report.SR");
      if (json && json !== "") {
        var draft = JSON.parse(json);
        console.log(draft);
        if (draft.VoyageCode == this.state.after.VoyageCode) {
          this.state.after = draft;
          this.state.after.ReportID = uuid();
          this.state.after.DailyReportID = 0; //dam bao la report moi

          this.setState({ saving: false });
        }
      }
    } catch (e) {
      showError(e);
    }
  }
  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 6,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 6; //shifting report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          if (demo) {
            report.VoyCode = null;
            report.VoyTime = null;
            report.VoyRobDO = report.VoyRobFO = null;
            //xu ly thogn tin FM
            let keys = Object.keys(report);
            let enables = Object.keys(this.state.FMEnable);
            keys.map((k) => {
              if (k.indexOf("Flow") == 0) {
                let found = enables.find((en) => k.indexOf(en) == 0);
                if (found && !this.state.FMEnable[found]) {
                  report[k] = null;
                }
              }
            });
          }
          this.setState(
            {
              after: { ...this.state.after, ...report },
              dataChanged: true,
            },
            () => this.loadROB()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = this.state; //new value
    s.after[fieldName] = value;
    switch (fieldName) {
      case "VoyRobFO":
      case "ConsFOAESS":
      case "ConsFOBLRSS":
        let robFO = s.after.VoyRobFO ? s.after.VoyRobFO : s.LastROB.RobFO;
        if (!myLib.isEmpty(robFO)) {
          s.after.RobFOSS =
            robFO -
            this.getConsValue(s.after.ConsFOAESS) -
            this.getConsValue(s.after.ConsFOBLRSS) +
            this.getReceive("FO");

          s.after.RobFOFS =
            s.after.RobFOSS -
            this.getConsValue(s.after.ConsFOMEFS) -
            this.getConsValue(s.after.ConsFOAEFS) -
            this.getConsValue(s.after.ConsFOBLRFS);
        }
        break;
      case "VoyRobDO":
      case "ConsDOAESS":
      case "ConsDOBLRSS":
      case "ConsDOPPSS":
      case "ConsDOIGSS":
        let robDO = s.after.VoyRobDO ? s.after.VoyRobDO : s.LastROB.RobDO;
        if (!myLib.isEmpty(robDO)) {
          s.after.RobDOSS =
            robDO -
            this.getConsValue(s.after.ConsDOAESS) -
            this.getConsValue(s.after.ConsDOPPSS) -
            this.getConsValue(s.after.ConsDOIGSS) -
            this.getConsValue(s.after.ConsDOBLRSS) +
            this.getReceive("DO");

          s.after.RobDOFS =
            s.after.RobDOSS -
            this.getConsValue(s.after.ConsDOMEFS) -
            this.getConsValue(s.after.ConsDOAEFS) -
            this.getConsValue(s.after.ConsDOPPFS) -
            this.getConsValue(s.after.ConsDOIGFS) -
            this.getConsValue(s.after.ConsDOBLRFS);
        }
        break;
    }
    switch (fieldName) {
      case "ConsFOMEFS":
      case "ConsFOAEFS":
      case "ConsFOBLRFS":
        if (!myLib.isEmpty(s.after.RobFOSS)) {
          s.after.RobFOFS =
            s.after.RobFOSS -
            this.getConsValue(s.after.ConsFOMEFS) -
            this.getConsValue(s.after.ConsFOAEFS) -
            this.getConsValue(s.after.ConsFOBLRFS);
        }
        break;
      case "ConsDOMEFS":
      case "ConsDOAEFS":
      case "ConsDOBLRFS":
      case "ConsDOPPFS":
      case "ConsDOIGFS":
        if (!myLib.isEmpty(s.after.RobDOSS)) {
          s.after.RobDOFS =
            s.after.RobDOSS -
            this.getConsValue(s.after.ConsDOMEFS) -
            this.getConsValue(s.after.ConsDOAEFS) -
            this.getConsValue(s.after.ConsDOPPFS) -
            this.getConsValue(s.after.ConsDOIGFS) -
            this.getConsValue(s.after.ConsDOBLRFS);
        }
        break;
    }
    s.dataChanged = true;

    this.setState({ saving: false });
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  saveRecord = (record, index, field, value) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.ReceiveDOFO[index] = record;
    s.dataChanged = true;
    if (field.indexOf("FO") >= 0 || field == "ReceiveDate") {
      console.log(record);
      this.saveField("ConsFOAESS", s.after.ConsFOAESS);
    } else if (field.indexOf("DO") >= 0 || field == "ReceiveDate") {
      this.saveField("ConsDOAESS", s.after.ConsDOAESS);
    } else this.setState(s);
  };

  newRecord() {
    return {
      ReceiveDate: null,
      FO: null,
      DO: null,
      LOCyl: null,
      LOSys: null,
      LOAE: null,
      LOHyd: null,
      FW: null,
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.push(this.newRecord());
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.ReceiveDOFO.splice(index, 1);
    s.dataChanged = true;
    this.setState(s);
  }
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }
  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };
  doSave = (reportStatus) => {
    try {
      if (this.state.after.DailyReportID === 0) {
        localStorage.setItem("Report.SR", JSON.stringify(this.state.after));
      }
      var check = validate_Data(this.state.after);
      var action = this.state.after.undefined > 0 ? "update" : "insert";
      console.log(this.state.after, check);
      if (!check.error) {
        localStorage.setItem("Report.Captain", this.state.after.Captain);
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          let attachs = []; //du lieu ve file attach
          atts.map((f) =>
            attachs.push({ FileID: f.FileID, FileName: f.FileName })
          );
          this.state.after.Attachs = attachs;
          server
            .post("Data/DoRequest", {
              Function: "Proc_OS_DailyReport_Update",
              ThamSo: {
                ReportID: this.state.after.ReportID,
                DailyReportID: this.state.after.DailyReportID,
                IsEngine: 0, //Captain
                VoyageCode: this.state.after.VoyageCode,
                ReportTypeID: this.state.after.ReportTypeID,
                ReportTime: this.state.after.ReportTime,
                ReportDataTime: this.state.after.TimeSS,
                ReportDataTime2: this.state.after.TimeFS,
                ReportStatus: reportStatus,
                ReportContent: JSON.stringify(this.state.after),
                AttachedReportID: null, // TODO: select bunker port
                ParentReportID:
                  this.props.edittingRecord !== undefined
                    ? this.props.edittingRecord.ParentReportID
                    : null, // when replace an existing report
                Attachs: atts,
              },
            })
            .then((response) => {
              if (
                response.OS_DailyReport == undefined ||
                response.OS_DailyReport.length === 0
              ) {
                this.props.close(); //de phong truong hop co loi bat thuong xay ra
                return;
              }
              this.state.after.DailyReportID =
                response.OS_DailyReport[0].DailyReportID;
              this.setState(
                {
                  dataSaved: true,
                  dataChanged: false,
                  canSaved: false,
                  saving: false,
                  errorMessage: "",
                  errorLog: "",
                  reportStatus: reportStatus,
                },
                () => {
                  //upload
                  if (reportStatus == 1) {
                    this.doUpload();
                  } else {
                    alert("Cập nhật thành công!");
                  }
                }
              );
              //request ve server
              //server.doRequestProcess();
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  doExport() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_GetUpload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then((response) => {
        //xuat ra file
        var data = JSON.parse(response.ReportContent[0].NoiDungReport);
        var report_string = data.ReportContent;
        var report = JSON.parse(report_string);
        report.Attachs = []; //bo di phan attachs
        report_string = JSON.stringify(report);
        data.ReportContent = report_string;
        console.log(data);
        var json = JSON.stringify(data);
        const blob = new Blob([json], {
          type: "application/json;charset=utf-8;",
        });
        var fileName = this.state.after.VoyageCode;
        fileName = fileName.replace("/", "-") + "-SR";
        fileName =
          global.ShipInfor.ShipCode +
          "-" +
          moment(this.state.after.ReportTime).format("HHmm-DDMMYY");
        myLib.downloadBlob(blob, fileName);
      })
      .catch((error) => {
        handleServerError(error);
      });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              {
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    myLib.isEmpty(this.state.after.TimeSS) ||
                    myLib.isEmpty(this.state.after.TimeFS)
                  }
                  onClick={() =>
                    this.setState({ open_logger_data_dialog: true })
                  }
                >
                  {buttonLabels.btnLogger}
                </Button>
              }
              {this.state.after.DailyReportID === 0 ? (
                <Button
                  color="success"
                  variant="contained"
                  disabled={
                    !this.state.HasLocalData ||
                    this.state.after.DailyReportID > 0
                  }
                  onClick={() => this.loadDraft()}
                >
                  {buttonLabels.btnDraft}
                </Button>
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadROB(this.state.after.TimeSS)}
                >
                  {buttonLabels.btnRob}
                </Button>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doExport()}
              >
                {buttonLabels.btnExport}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => {
                  myLib.sendEmail(this.state.after);
                  this.state.dataSaved = true;
                }}
              >
                {buttonLabels.btnEmail}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={this.state.FMEnable.Container ? 2 : 4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  readOnly
                  placeholder=""
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              {this.state.FMEnable.Container ? (
                <Grid item xs={2}>
                  <MySelect
                    id="CodeTrip"
                    key="CodeTrip"
                    fullWidth
                    options={CodeTripList}
                    optionValue="value"
                    optionLabel="label"
                    value={this.state.after.CodeTrip}
                    onChange={(event) => {
                      this.saveField("CodeTrip", event.target.value);
                    }}
                    error={this.state.validateStatus.CodeTrip !== undefined}
                  ></MySelect>
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeSS}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeSS"
                  key="TimeSS"
                  fullWidth
                  autoFocus
                  value={this.state.after.TimeSS}
                  onChange={(value) => {
                    this.saveField("TimeSS", value);
                  }}
                  error={this.state.validateStatus.TimeSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TimeFS}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="TimeFS"
                  key="TimeFS"
                  fullWidth
                  value={this.state.after.TimeFS}
                  onChange={(value) => {
                    this.saveField("TimeFS", value);
                  }}
                  error={this.state.validateStatus.TimeFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime"
                  key="PilotTime"
                  fullWidth
                  value={this.state.after.PilotTime}
                  onChange={(value) => {
                    this.saveField("PilotTime", value);
                  }}
                  error={this.state.validateStatus.PilotTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PilotTime2}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="PilotTime2"
                  key="PilotTime2"
                  fullWidth
                  value={this.state.after.PilotTime2}
                  onChange={(value) => {
                    this.saveField("PilotTime2", value);
                  }}
                  error={this.state.validateStatus.PilotTime2 !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName"
                  key="PortName"
                  fullWidth
                  value={this.state.after.PortName}
                  onChange={(event) => {
                    this.saveField(
                      "PortName",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.PortName2}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="PortName2"
                  key="PortName2"
                  fullWidth
                  value={this.state.after.PortName2}
                  onChange={(event) => {
                    this.saveField(
                      "PortName2",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.PortName2 !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Tugs}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Tugs"
                  key="Tugs"
                  fullWidth
                  value={this.state.after.Tugs}
                  onChange={(event) => {
                    this.saveField("Tugs", event.target.value);
                  }}
                  error={this.state.validateStatus.Tugs !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyNumberEditor
                  id="TugTime"
                  key="TugTime"
                  fullWidth
                  placeholder="hours"
                  value={this.state.after.TugTime}
                  onChange={(value) => {
                    this.saveField("TugTime", value);
                  }}
                  error={this.state.validateStatus.TugTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TugPower}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="TugPower"
                  key="TugPower"
                  fullWidth
                  value={this.state.after.TugPower}
                  onChange={(event) => {
                    this.saveField("TugPower", event.target.value);
                  }}
                  error={this.state.validateStatus.TugPower !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Position}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Position"
                  key="Position"
                  fullWidth
                  value={this.state.after.Position}
                  onChange={(event) => {
                    this.saveField("Position", event.target.value);
                  }}
                  error={this.state.validateStatus.Position !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.VoyCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyCode"
                  key="VoyCode"
                  fullWidth
                  placeholder=""
                  value={this.state.after.VoyCode}
                  onChange={(event) => {
                    this.saveField(
                      "VoyCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.VoyCode !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="VoyTime"
                  key="VoyTime"
                  fullWidth
                  value={this.state.after.VoyTime}
                  onChange={(value) => {
                    this.saveField("VoyTime", value);
                  }}
                  error={this.state.validateStatus.VoyTime !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.VoyRobFO}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="VoyRobFO"
                  key="VoyRobFO"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.VoyRobFO}
                  onChange={(value) => {
                    this.saveField("VoyRobFO", value);
                  }}
                  error={this.state.validateStatus.VoyRobFO !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="VoyRobDO"
                  key="VoyRobDO"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.VoyRobDO}
                  onChange={(value) => {
                    this.saveField("VoyRobDO", value);
                  }}
                  error={this.state.validateStatus.VoyRobDO !== undefined}
                />
              </Grid>
            </Grid>
            {this.state.after.ReceiveDOFO.map((item, index) => (
              <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
                {index == 0 ? (
                  <>
                    <Grid item xs={2}>
                      <FormLabel
                        className={classes.quantrong}
                        onClick={() =>
                          this.setState({
                            show_thongtin_field: true,
                            Show_FieldName: "ReceiveDOFO",
                          })
                        }
                      >
                        {fieldLabels.ReceiveDOFO}
                      </FormLabel>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={2}></Grid>
                )}

                <Grid item xs={4}>
                  <MyDateEditor
                    id="ReceiveDOFODate"
                    key="ReceiveDOFODate"
                    fullWidth
                    value={item.ReceiveDate}
                    onChange={(value) => {
                      this.saveRecord(item, index, "ReceiveDate", value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFO"
                    key="ReceiveFO"
                    fullWidth
                    placeholder="FO"
                    value={item.FO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FO", value);
                    }}
                    error={this.state.validateStatus.ReceiveFO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveDO"
                    key="ReceiveDO"
                    fullWidth
                    placeholder="DO"
                    value={item.DO}
                    onChange={(value) => {
                      this.saveRecord(item, index, "DO", value);
                    }}
                    error={this.state.validateStatus.ReceiveDO !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOCyl"
                    key="ReceiveLOCyl"
                    fullWidth
                    placeholder="LO-Cyl"
                    decimalScale={0}
                    value={item.LOCyl}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOCyl", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOCyl !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOSys"
                    key="ReceiveLOSys"
                    fullWidth
                    placeholder="LO-Sys"
                    decimalScale={0}
                    value={item.LOSys}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOSys", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOSys !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOAE"
                    key="ReceiveLOAE"
                    fullWidth
                    placeholder="LO-AE"
                    decimalScale={0}
                    value={item.LOAE}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOAE", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOAE !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveLOHyd"
                    key="ReceiveLOHyd"
                    fullWidth
                    placeholder="LO-Hyd"
                    decimalScale={0}
                    value={item.LOHyd}
                    onChange={(value) => {
                      this.saveRecord(item, index, "LOHyd", value);
                    }}
                    error={this.state.validateStatus.ReceiveLOHyd !== undefined}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ReceiveFW"
                    key="ReceiveFW"
                    fullWidth
                    placeholder="FW"
                    decimalScale={0}
                    value={item.FW}
                    onChange={(value) => {
                      this.saveRecord(item, index, "FW", value);
                    }}
                    error={this.state.validateStatus.ReceiveFW !== undefined}
                  />
                </Grid>

                {index == 0 ? (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        this.addRecord();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.removeRecord(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={26}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ForeDraft}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ForeDraft"
                  key="ForeDraft"
                  fullWidth
                  placeholder="Fore Draft"
                  value={this.state.after.ForeDraft}
                  onChange={(value) => {
                    this.saveField("ForeDraft", value);
                  }}
                  error={this.state.validateStatus.ForeDraft !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AftDraft"
                  key="AftDraft"
                  fullWidth
                  placeholder="Aft. Draft"
                  value={this.state.after.AftDraft}
                  onChange={(value) => {
                    this.saveField("AftDraft", value);
                  }}
                  error={this.state.validateStatus.AftDraft !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.CargoTon}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="CargoTon"
                  key="CargoTon"
                  fullWidth
                  placeholder="MTs"
                  value={this.state.after.CargoTon}
                  onChange={(value) => {
                    this.saveField("CargoTon", value);
                  }}
                  error={this.state.validateStatus.CargoTon !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <OutlinedInput
                  id="CargoName"
                  key="CargoName"
                  fullWidth
                  placeholder="name"
                  value={this.state.after.CargoName}
                  onChange={(event) => {
                    this.saveField("CargoName", event.target.value);
                  }}
                  error={this.state.validateStatus.CargoName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ShiftDistance}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ShiftDistance"
                  key="ShiftDistance"
                  fullWidth
                  value={this.state.after.ShiftDistance}
                  onChange={(value) => {
                    this.saveField("ShiftDistance", value);
                  }}
                  error={this.state.validateStatus.ShiftDistance !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RPM & FM
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RpmCounterMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMESS"
                  key="RpmCounterMESS"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMESS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMESS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMESS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMESS"
                  key="FlowMESS"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMESS}
                  onChange={(value) => {
                    this.saveField("FlowMESS", value);
                  }}
                  error={this.state.validateStatus.FlowMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAESS"
                  key="FlowAESS"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAESS}
                  onChange={(value) => {
                    this.saveField("FlowAESS", value);
                  }}
                  error={this.state.validateStatus.FlowAESS !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2SS"
                      key="Flow2SS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2SS}
                      onChange={(value) => {
                        this.saveField("Flow2SS", value);
                      }}
                      error={this.state.validateStatus.Flow2SS !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSS"
                      key="FlowBLRSS"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRSS}
                      onChange={(value) => {
                        this.saveField("FlowBLRSS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSS !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRSS"
                      key="FlowBLRSS"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRSS}
                      onChange={(value) => {
                        this.saveField("FlowBLRSS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRSS !== undefined}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3SS"
                      key="Flow3SS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3SS}
                      onChange={(value) => {
                        this.saveField("Flow3SS", value);
                      }}
                      error={this.state.validateStatus.Flow3SS !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempSS"
                  key="FlowTempSS"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempSS}
                  onChange={(value) => {
                    this.saveField("FlowTempSS", value);
                  }}
                  error={this.state.validateStatus.FlowTempSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempSS"
                  key="METempSS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempSS}
                  onChange={(value) => {
                    this.saveField("METempSS", value);
                  }}
                  error={this.state.validateStatus.METempSS !== undefined}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.RpmCounterMEFS}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEFS"
                  key="RpmCounterMEFS"
                  fullWidth
                  placeholder="RPM CTR"
                  value={this.state.after.RpmCounterMEFS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEFS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEFS"
                  key="FlowMEFS"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowME}
                  placeholder="ME"
                  value={this.state.after.FlowMEFS}
                  onChange={(value) => {
                    this.saveField("FlowMEFS", value);
                  }}
                  error={this.state.validateStatus.FlowMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEFS"
                  key="FlowAEFS"
                  fullWidth
                  readOnly={!this.state.FMEnable.FlowAE}
                  placeholder="AE"
                  value={this.state.after.FlowAEFS}
                  onChange={(value) => {
                    this.saveField("FlowAEFS", value);
                  }}
                  error={this.state.validateStatus.FlowAEFS !== undefined}
                />
              </Grid>

              {this.state.FMEnable.Flow2 ? (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow2FS"
                      key="Flow2FS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow2}
                      placeholder="ME+GE"
                      value={this.state.after.Flow2FS}
                      onChange={(value) => {
                        this.saveField("Flow2FS", value);
                      }}
                      error={this.state.validateStatus.Flow2FS !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRFS"
                      key="FlowBLRFS"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRFS}
                      onChange={(value) => {
                        this.saveField("FlowBLRFS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRFS !== undefined}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="FlowBLRFS"
                      key="FlowBLRFS"
                      fullWidth
                      readOnly={!this.state.FMEnable.FlowBLR}
                      placeholder="BLR"
                      value={this.state.after.FlowBLRFS}
                      onChange={(value) => {
                        this.saveField("FlowBLRFS", value);
                      }}
                      error={this.state.validateStatus.FlowBLRFS !== undefined}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MyNumberEditor
                      id="Flow3FS"
                      key="Flow3FS"
                      fullWidth
                      readOnly={!this.state.FMEnable.Flow3}
                      placeholder="ALL"
                      value={this.state.after.Flow3FS}
                      onChange={(value) => {
                        this.saveField("Flow3FS", value);
                      }}
                      error={this.state.validateStatus.Flow3FS !== undefined}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempFS"
                  key="FlowTempFS"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempFS}
                  onChange={(value) => {
                    this.saveField("FlowTempFS", value);
                  }}
                  error={this.state.validateStatus.FlowTempFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempFS"
                  key="METempFS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempFS}
                  onChange={(value) => {
                    this.saveField("METempFS", value);
                  }}
                  error={this.state.validateStatus.METempFS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  RNH.HR
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1SS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMESS"
                  key="RunhMESS"
                  fullWidth
                  placeholder="ME"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1SS"
                  key="RunhAE1SS"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1SS}
                  onChange={(value) => {
                    this.saveField("RunhAE1SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2SS"
                  key="RunhAE2SS"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2SS}
                  onChange={(value) => {
                    this.saveField("RunhAE2SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3SS"
                  key="RunhAE3SS"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3SS}
                  onChange={(value) => {
                    this.saveField("RunhAE3SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3SS !== undefined}
                />
              </Grid>
              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1SS"
                    key="RunhPP1SS"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1SS}
                    onChange={(value) => {
                      this.saveField("RunhPP1SS", value);
                    }}
                    error={this.state.validateStatus.RunhPP1SS !== undefined}
                  />
                </Grid>
              ) : null}

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2SS"
                    key="RunhPP2SS"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2SS}
                    onChange={(value) => {
                      this.saveField("RunhPP2SS", value);
                    }}
                    error={this.state.validateStatus.RunhPP2SS !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGSS"
                    key="RunhIGSS"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGSS}
                    onChange={(value) => {
                      this.saveField("RunhIGSS", value);
                    }}
                    error={this.state.validateStatus.RunhIGSS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRSS"
                    key="RunhBLRSS"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRSS}
                    onChange={(value) => {
                      this.saveField("RunhBLRSS", value);
                    }}
                    error={this.state.validateStatus.RunhBLRSS !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhAE1FS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEFS"
                  key="RunhMEFS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMEFS}
                  onChange={(value) => {
                    this.saveField("RunhMEFS", value);
                  }}
                  error={this.state.validateStatus.RunhMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1FS"
                  key="RunhAE1FS"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1FS}
                  onChange={(value) => {
                    this.saveField("RunhAE1FS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2FS"
                  key="RunhAE2FS"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2FS}
                  onChange={(value) => {
                    this.saveField("RunhAE2FS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3FS"
                  key="RunhAE3FS"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3FS}
                  onChange={(value) => {
                    this.saveField("RunhAE3FS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3FS !== undefined}
                />
              </Grid>

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP1FS"
                    key="RunhPP1FS"
                    fullWidth
                    placeholder="PP1"
                    value={this.state.after.RunhPP1FS}
                    onChange={(value) => {
                      this.saveField("RunhPP1FS", value);
                    }}
                    error={this.state.validateStatus.RunhPP1FS !== undefined}
                  />
                </Grid>
              ) : null}

              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhPP2FS"
                    key="RunhPP2FS"
                    fullWidth
                    placeholder="PP2"
                    value={this.state.after.RunhPP2FS}
                    onChange={(value) => {
                      this.saveField("RunhPP2FS", value);
                    }}
                    error={this.state.validateStatus.RunhPP2FS !== undefined}
                  />
                </Grid>
              ) : null}

              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhIGFS"
                    key="RunhIGFS"
                    fullWidth
                    placeholder="IGG"
                    readOnly={!this.state.FMEnable.IG}
                    value={this.state.after.RunhIGFS}
                    onChange={(value) => {
                      this.saveField("RunhIGFS", value);
                    }}
                    error={this.state.validateStatus.RunhIGFS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.RunhBLR ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="RunhBLRFS"
                    key="RunhBLRFS"
                    fullWidth
                    placeholder="BLR"
                    readOnly={!this.state.FMEnable.RunhBLR}
                    value={this.state.after.RunhBLRFS}
                    onChange={(value) => {
                      this.saveField("RunhBLRFS", value);
                    }}
                    error={this.state.validateStatus.RunhBLRFS !== undefined}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  CONS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMESS"
                  key="ConsFOMESS"
                  fullWidth
                  placeholder="ME/FO"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEFS"
                  key="ConsDOMEFS"
                  fullWidth
                  placeholder="ME/DO"
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAESS"
                  key="ConsFOAESS"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAESS}
                  onChange={(value) => {
                    this.saveField("ConsFOAESS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAESS"
                  key="ConsDOAESS"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAESS}
                  onChange={(value) => {
                    this.saveField("ConsDOAESS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRSS"
                  key="ConsFOBLRSS"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRSS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRSS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRSS"
                  key="ConsDOBLRSS"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRSS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRSS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRSS !== undefined}
                />
              </Grid>
              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOPPSS"
                    key="ConsDOPPSS"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPSS}
                    onChange={(value) => {
                      this.saveField("ConsDOPPSS", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPSS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOIGSS"
                    key="ConsDOIGSS"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGSS}
                    onChange={(value) => {
                      this.saveField("ConsDOIGSS", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGSS !== undefined}
                  />
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylSS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylSS"
                  key="ConsCylSS"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylSS}
                  onChange={(value) => {
                    this.saveField("ConsCylSS", value);
                  }}
                  error={this.state.validateStatus.ConsCylSS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysSS"
                  key="ConsSysSS"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysSS}
                  onChange={(value) => {
                    this.saveField("ConsSysSS", value);
                  }}
                  error={this.state.validateStatus.ConsSysSS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAESS"
                  key="ConsAESS"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAESS}
                  onChange={(value) => {
                    this.saveField("ConsAESS", value);
                  }}
                  error={this.state.validateStatus.ConsAESS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydSS"
                  key="ConsHydSS"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydSS}
                  onChange={(value) => {
                    this.saveField("ConsHydSS", value);
                  }}
                  error={this.state.validateStatus.ConsHydSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.ConsFOMEFS}
                </FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEFS"
                  key="ConsFOMEFS"
                  fullWidth
                  placeholder="ME/FO"
                  value={this.state.after.ConsFOMEFS}
                  onChange={(value) => {
                    this.saveField("ConsFOMEFS", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEFS"
                  key="ConsDOMEFS"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMEFS}
                  onChange={(value) => {
                    this.saveField("ConsDOMEFS", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEFS"
                  key="ConsFOAEFS"
                  fullWidth
                  placeholder="AE/FO"
                  value={this.state.after.ConsFOAEFS}
                  onChange={(value) => {
                    this.saveField("ConsFOAEFS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEFS"
                  key="ConsDOAEFS"
                  fullWidth
                  placeholder="AE/DO"
                  value={this.state.after.ConsDOAEFS}
                  onChange={(value) => {
                    this.saveField("ConsDOAEFS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRFS"
                  key="ConsFOBLRFS"
                  fullWidth
                  placeholder="BLR/FO"
                  value={this.state.after.ConsFOBLRFS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRFS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRFS"
                  key="ConsDOBLRFS"
                  fullWidth
                  placeholder="BLR/DO"
                  value={this.state.after.ConsDOBLRFS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRFS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRFS !== undefined}
                />
              </Grid>
              {(this.state.FMEnable.IG &&
                myLib.isEmpty(this.state.FMEnable.PP)) ||
              this.state.FMEnable.PP ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOPPFS"
                    key="ConsDOPPFS"
                    fullWidth
                    placeholder="PP/DO"
                    value={this.state.after.ConsDOPPFS}
                    onChange={(value) => {
                      this.saveField("ConsDOPPFS", value);
                    }}
                    error={this.state.validateStatus.ConsDOPPFS !== undefined}
                  />
                </Grid>
              ) : null}
              {this.state.FMEnable.IG ? (
                <Grid item xs={2}>
                  <MyNumberEditor
                    id="ConsDOIGFS"
                    key="ConsDOIGFS"
                    fullWidth
                    placeholder="IGG/DO"
                    value={this.state.after.ConsDOIGFS}
                    onChange={(value) => {
                      this.saveField("ConsDOIGFS", value);
                    }}
                    error={this.state.validateStatus.ConsDOIGFS !== undefined}
                  />
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsCylFS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsCylFS"
                  key="ConsCylFS"
                  fullWidth
                  placeholder="Cyl"
                  decimalScale={0}
                  value={this.state.after.ConsCylFS}
                  onChange={(value) => {
                    this.saveField("ConsCylFS", value);
                  }}
                  error={this.state.validateStatus.ConsCylFS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsSysFS"
                  key="ConsSysFS"
                  fullWidth
                  placeholder="Sys"
                  decimalScale={0}
                  value={this.state.after.ConsSysFS}
                  onChange={(value) => {
                    this.saveField("ConsSysFS", value);
                  }}
                  error={this.state.validateStatus.ConsSysFS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsAEFS"
                  key="ConsAEFS"
                  fullWidth
                  placeholder="AE"
                  decimalScale={0}
                  value={this.state.after.ConsAEFS}
                  onChange={(value) => {
                    this.saveField("ConsAEFS", value);
                  }}
                  error={this.state.validateStatus.ConsAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="ConsHydFS"
                  key="ConsHydFS"
                  fullWidth
                  placeholder="Hyd"
                  decimalScale={0}
                  value={this.state.after.ConsHydFS}
                  onChange={(value) => {
                    this.saveField("ConsHydFS", value);
                  }}
                  error={this.state.validateStatus.ConsHydFS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  R.O.B
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobSS}</FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOSS"
                  key="RobFOSS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOSS}
                  onChange={(value) => {
                    this.saveField("RobFOSS", value);
                  }}
                  error={this.state.validateStatus.RobFOSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOSS"
                  key="RobDOSS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOSS}
                  onChange={(value) => {
                    this.saveField("RobDOSS", value);
                  }}
                  error={this.state.validateStatus.RobDOSS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWSS"
                  key="RobFWSS"
                  fullWidth
                  placeholder={fieldLabels.RobFWSS}
                  value={this.state.after.RobFWSS}
                  onChange={(value) => {
                    this.saveField("RobFWSS", value);
                  }}
                  error={this.state.validateStatus.RobFWSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={2}>
                <FormLabel className={classes.quantrong}>
                  {fieldLabels.RobFS}
                </FormLabel>
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOFS"
                  key="RobFOFS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOFS}
                  onChange={(value) => {
                    this.saveField("RobFOFS", value);
                  }}
                  error={this.state.validateStatus.RobFOFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOFS"
                  key="RobDOFS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOFS}
                  onChange={(value) => {
                    this.saveField("RobDOFS", value);
                  }}
                  error={this.state.validateStatus.RobDOFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWFS"
                  key="RobFWFS"
                  fullWidth
                  decimalScale={0}
                  placeholder={fieldLabels.RobFWFS}
                  value={this.state.after.RobFWFS}
                  onChange={(value) => {
                    this.saveField("RobFWFS", value);
                  }}
                  error={this.state.validateStatus.RobFWFS !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={1} columns={24}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  OTHERS
                </Typography>
              </Grid>

              <Grid item xs={11}>
                <hr />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureFS"
                  key="FWPureFS"
                  fullWidth
                  placeholder="FW"
                  decimalScale={1}
                  value={this.state.after.FWPureFS}
                  onChange={(value) => {
                    this.saveField("FWPureFS", value);
                  }}
                  error={this.state.validateStatus.FWPureFS !== undefined}
                />
              </Grid>
              <Grid item xs={20}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Remark}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="Remark"
                  key="Remark"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Remark}
                  onChange={(event) => {
                    this.saveField("Remark", event.target.value);
                  }}
                  error={this.state.validateStatus.Remark !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>Attachs:</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  id="Attachs"
                  key="Attachs"
                  fullWidth
                  value={this.getAttachs()}
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true });
                  }}
                  placeholder=""
                  error={this.state.validateStatus.BunkerFileName !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={5}
                  minSize={1}
                  maxSize={500 * 1024}
                  multiple={true}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 500 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileID: uuid(),
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">
                          Upload
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{"(Max size: 500KB)"}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Captain}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="Captain"
                  key="Captain"
                  fullWidth
                  placeholder=""
                  value={this.state.after.Captain}
                  onChange={(event) => {
                    this.saveField("Captain", event.target.value);
                  }}
                  error={this.state.validateStatus.Captain !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.open_logger_data_dialog ? (
          <LoggerDataDialog
            open={true}
            Data={{
              ThoiGian1: this.state.after.TimeSS,
              ThoiGian2: this.state.after.TimeFS,
              PickDistanceFromTime: true,
            }}
            close={(data) => {
              if (data) {
                //ghi nhan gia tri lay tu logger
                var after = this.state.after;
                after.Position =
                  data.Lat2 != 0 && data.Lon2 != 0
                    ? geoLib.latToDMS(data.Lat2) +
                      ", " +
                      geoLib.lonToDMS(data.Lon2)
                    : "";
                after.RpmCounterMESS = data.RpmCounter1;
                after.RpmCounterMEFS = data.RpmCounter2;
                after.FlowMESS = data.FlowmeterME1;
                after.FlowMEFS = data.FlowmeterME2;
                after.FlowAESS = data.FlowmeterAE1;
                after.FlowAEFS = data.FlowmeterAE2;
                after.FlowBLRSS = data.FlowmeterBLR1;
                after.FlowBLRFS = data.FlowmeterBLR2;
                after.FlowTempSS = data.FlowTemp1;
                after.FlowTempFS = data.FlowTemp2;
                after.METempSS = data.TempOilME1;
                after.METempFS = data.TempOilME2;
                after.DistanceSS = data.Distance1
                  ? data.Distance1
                  : after.DistanceSS;

                after.DistanceFS = data.Distance2
                  ? data.Distance2
                  : after.DistanceFS;
              }
              this.setState({ open_logger_data_dialog: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  ShiftingReportEditDialog
);
