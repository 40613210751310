/*Luu tru dinh nghia columns trong CSDL de customize cho ung dung*/
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import { filterStyles, handleServerError } from "../components/common";
import AsyncSelect from "react-select/async";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const validator = require("../data/DangKy_Validator");

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["ColumnsJson"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

export default class SearchFormColumnsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate

      after: {
        ColumnsJson: JSON.stringify(props.columns_default, null, 2), //hien thi columns mac dinh
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }

  //lay du lieu danh muc
  loadDanhMuc() {
    //lay danh sach table
    server
      .post("Data/DoRequest", {
        Function: "Proc_Tool_GridColumns_Get",
        ThamSo: {
          FormName: this.props.formName,
        },
      })
      .then((response) => {
        this.setState({
          after: {
            ...this.state.after,
            ColumnsJson:
              response.DataList && response.DataList.length > 0
                ? response.DataList[0].ColumnsJson
                : this.state.after.ColumnsJson,
          },
        });
      })
      .catch((error) => handleServerError(error));
  }

  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      var action = this.state.after.MaCT > 0 ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        var columns = JSON.parse(this.state.after.ColumnsJson);
        server
          .post("Data/DoRequest", {
            Function: "Proc_Tool_GridColumns_Save",
            ThamSo: {
              FormName: this.props.formName,
              ColumnsJson: this.state.after.ColumnsJson,
            },
          })
          .then((response) => {
            this.setState({
              after: {
                ...this.state.after,
                ScriptCode: response.DanhSachCodes
                  ? response.DanhSachCodes[0]["Script"]
                  : "",
              },
              saving: false,
            });
            this.props.close(columns);
          })
          .catch((error) => handleServerError(error));
      } else {
        this.setState({ validateStatus: check });
        myLib.showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      myLib.showError(error);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Định nghĩa columns trong grid
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>Columns (*):</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  fullWidth
                  multiline
                  value={this.state.after.ColumnsJson}
                  onChange={(event) =>
                    this.saveField("ColumnsJson", event.target.value)
                  }
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.doSave} variant="contained">
            Lưu
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
