import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Shifting Report" : "Báo cáo tàu di chuyển";
const fieldLabels = englishUI
  ? {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RobFS: "ROB-FS:",
    RobFOFS: "FO",
    RobDOFS: "DO",
    RobFWFS: "FW",
    RpmCounterMESS: "M/E RPM CTR SS",
    FlowMESS: "F.M ME/AE/BLR/ALL",
    FlowAESS: "",
    FlowBLRSS: "",
    Flow3SS: "",
    FlowTempSS: "Temp F.M/ME",
    METempSS: "",
    RpmCounterMEFS: "M/E RPM CTR FS",
    FlowMEFS: "F.M ME/AE/BLR/ALL",
    FlowAEFS: "",
    FlowBLRFS: "",
    Flow3FS: "",
    FlowTempFS: "Temp F.M/ME",
    METempFS: "",
    ConsFOAESS: "Cons(BF-SS): AE(FO/DO)",
    ConsDOAESS: "",
    ConsFOBLRSS: "BLR(FO/DO)",
    ConsDOBLRSS: "",
    ConsCylSS: "LO",
    ConsSysSS: "",
    ConsAESS: "",
    ConsHydSS: "",
    FWPureSS: "FW Pur",
    ConsFOMEFS: "Cons(SS-FS): ME(FO/DO)",
    ConsDOMEFS: "DO/ME",
    ConsFOAEFS: "AE(FO/DO)",
    ConsDOAEFS: "AE/DO",
    ConsFOBLRFS: "BLR(FO/DO)",
    ConsDOBLRFS: "BLR/DO",
    FWPureFS: "FW Pure",
    ConsCylFS: "LO",
    ConsSysFS: "",
    ConsAEFS: "",
    ConsHydFS: "",
    FWPureFS: "FW Pur",
    RunhAE1SS: "Running.Hr(BF-SS): AE1/AE2/AE3",
    RunhAE2SS: "",
    RunhAE3SS: "",
    RunhIGSS: "IG",
    RunhMEFS: "ME",
    RunhAE1FS: "Running.Hr(SS-FS): ME/AE1/AE2/AE3",
    RunhAE2FS: "",
    RunhAE3FS: "",
    RunhIGFS: "IG"
  }
  : {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RobFS: "ROB-S.FN:",
    RobFOFS: "FO",
    RobDOFS: "DO",
    RobFWFS: "FW",
    RpmCounterMESS: "M/E RPM CTR SS",
    FlowMESS: "F.M ME/AE/BLR/ALL",
    FlowAESS: "",
    FlowBLRSS: "",
    Flow3SS: "",
    FlowTempSS: "Temp F.M/ME",
    METempSS: "",
    RpmCounterMEFS: "M/E RPM CTR FS",
    FlowMEFS: "F.M ME/AE/BLR/ALL",
    FlowAEFS: "",
    FlowBLRFS: "",
    Flow3FS: "",
    FlowTempFS: "Temp F.M/ME",
    METempFS: "",
    ConsFOAESS: "Cons(BF-SS): AE(FO/DO)",
    ConsDOAESS: "",
    ConsFOBLRSS: "BLR(FO/DO)",
    ConsDOBLRSS: "",
    ConsCylSS: "LO",
    ConsSysSS: "",
    ConsAESS: "",
    ConsHydSS: "",
    FWPureSS: "FW Pur",
    ConsFOMEFS: "Cons(S.ST-S.FN): ME",
    ConsDOMEFS: "DO/ME",
    ConsFOAEFS: "AE(FO/DO)",
    ConsDOAEFS: "AE/DO",
    ConsFOBLRFS: "BLR(FO/DO)",
    ConsDOBLRFS: "BLR/DO",
    FWPureFS: "FW Pure",
    ConsCylFS: "LO",
    ConsSysFS: "",
    ConsAEFS: "",
    ConsHydFS: "",
    FWPureFS: "FW Pur",
    RunhAE1SS: "Running.Hr(BF-SS): AE1/AE2/AE3",
    RunhAE2SS: "",
    RunhAE3SS: "",
    RunhIGSS: "IG",
    RunhMEFS: "ME",
    RunhAE1FS: "Running.Hr(S.ST-S.FN): ME/AE1/AE2/AE3",
    RunhAE2FS: "",
    RunhAE3FS: "",
    RunhIGFS: "IG"
  };
const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnLoadRandomSample: "Random sample",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}
/*------------------------------------FORM--------------------------------------*/
class CEShiftingReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 6,
        ReportTime: null,
        RobFOFS: null,
        RobDOFS: null,
        RobLOFS: null,
        RobFWFS: null,
        RpmCounterMESS: null,
        FlowMESS: null,
        FlowAESS: null,
        FlowBLRSS: null,
        Flow3SS: null,
        FlowTempSS: null,
        METempSS: null,
        RpmCounterMEFS: null,
        FlowMEFS: null,
        FlowAEFS: null,
        FlowBLRFS: null,
        Flow3FS: null,
        FlowTempFS: null,
        METempFS: null,
        ConsFOMESS: null,
        ConsDOMESS: null,
        ConsFOAESS: null,
        ConsDOAESS: null,
        ConsFOBLRSS: null,
        ConsDOBLRSS: null,
        ConsCylSS: null,
        ConsSysSS: null,
        ConsAESS: null,
        ConsHydSS: null,
        FWPureSS: null,
        ConsFOMEFS: null,
        ConsDOMEFS: null,
        ConsFOAEFS: null,
        ConsDOAEFS: null,
        ConsFOBLRFS: null,
        ConsDOBLRFS: null,
        ConsCylFS: null,
        ConsSysFS: null,
        ConsAEFS: null,
        ConsHydFS: null,
        FWPureFS: null,
        RunhMESS: null,
        RunhAE1SS: null,
        RunhAE2SS: null,
        RunhAE3SS: null,
        RunhIGSS: null,
        RunhMEFS: null,
        RunhAE1FS: null,
        RunhAE2FS: null,
        RunhAE3FS: null,
        RunhIGFS: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 6,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 6; //shifting report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.TimeSS,
              ReportStatus: reportStatus,
              ReportContent: JSON.stringify(this.state.after),
              AttachedReportID: null, // TODO: select bunker port
              ParentReportID:
                this.props.edittingRecord !== undefined
                  ? this.props.edittingRecord.ParentReportID
                  : null, // when replace an existing report
            },
          })
          .then((response) => {
            this.state.after.DailyReportID =
              response.OS_DailyReport[0].DailyReportID;
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
                reportStatus: reportStatus,
              },
              () => {
                //upload
                if (reportStatus == 1) {
                  this.doUpload();
                } else {
                  alert("Cập nhật thành công!");
                }
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  readOnly
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>C-</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFS}</FormLabel>
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RobFOFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFOFS"
                  key="RobFOFS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.RobFOFS}
                  onChange={(value) => {
                    this.saveField("RobFOFS", value);
                  }}
                  error={this.state.validateStatus.RobFOFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDOFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDOFS"
                  key="RobDOFS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.RobDOFS}
                  onChange={(value) => {
                    this.saveField("RobDOFS", value);
                  }}
                  error={this.state.validateStatus.RobDOFS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWFS"
                  key="RobFWFS"
                  fullWidth
                  placeholder={fieldLabels.RobFWFS}
                  value={this.state.after.RobFWFS}
                  onChange={(value) => {
                    this.saveField("RobFWFS", value);
                  }}
                  error={this.state.validateStatus.RobFWFS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMESS"
                  key="RpmCounterMESS"
                  fullWidth
                  value={this.state.after.RpmCounterMESS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMESS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMESS !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowMESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMESS"
                  key="FlowMESS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.FlowMESS}
                  onChange={(value) => {
                    this.saveField("FlowMESS", value);
                  }}
                  error={this.state.validateStatus.FlowMESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAESS"
                  key="FlowAESS"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAESS}
                  onChange={(value) => {
                    this.saveField("FlowAESS", value);
                  }}
                  error={this.state.validateStatus.FlowAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRSS"
                  key="FlowBLRSS"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLRSS}
                  onChange={(value) => {
                    this.saveField("FlowBLRSS", value);
                  }}
                  error={this.state.validateStatus.FlowBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3SS"
                  key="Flow3SS"
                  fullWidth
                  placeholder="ALL"
                  value={this.state.after.Flow3SS}
                  onChange={(value) => {
                    this.saveField("Flow3SS", value);
                  }}
                  error={this.state.validateStatus.Flow3SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempSS"
                  key="FlowTempSS"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempSS}
                  onChange={(value) => {
                    this.saveField("FlowTempSS", value);
                  }}
                  error={this.state.validateStatus.FlowTempSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempSS"
                  key="METempSS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempSS}
                  onChange={(value) => {
                    this.saveField("METempSS", value);
                  }}
                  error={this.state.validateStatus.METempSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMEFS"
                  key="RpmCounterMEFS"
                  fullWidth
                  value={this.state.after.RpmCounterMEFS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMEFS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMEFS"
                  key="FlowMEFS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.FlowMEFS}
                  onChange={(value) => {
                    this.saveField("FlowMEFS", value);
                  }}
                  error={this.state.validateStatus.FlowMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAEFS"
                  key="FlowAEFS"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.FlowAEFS}
                  onChange={(value) => {
                    this.saveField("FlowAEFS", value);
                  }}
                  error={this.state.validateStatus.FlowAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRFS"
                  key="FlowBLRFS"
                  fullWidth
                  placeholder="BLR"
                  value={this.state.after.FlowBLRFS}
                  onChange={(value) => {
                    this.saveField("FlowBLRFS", value);
                  }}
                  error={this.state.validateStatus.FlowBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3FS"
                  key="Flow3FS"
                  fullWidth
                  placeholder="ALL"
                  value={this.state.after.Flow3FS}
                  onChange={(value) => {
                    this.saveField("Flow3FS", value);
                  }}
                  error={this.state.validateStatus.Flow3FS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowTempFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempFS"
                  key="FlowTempFS"
                  fullWidth
                  placeholder="F.M"
                  value={this.state.after.FlowTempFS}
                  onChange={(value) => {
                    this.saveField("FlowTempFS", value);
                  }}
                  error={this.state.validateStatus.FlowTempFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempFS"
                  key="METempFS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.METempFS}
                  onChange={(value) => {
                    this.saveField("METempFS", value);
                  }}
                  error={this.state.validateStatus.METempFS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAESS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAESS"
                  key="ConsFOAESS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAESS}
                  onChange={(value) => {
                    this.saveField("ConsFOAESS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAESS"
                  key="ConsDOAESS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAESS}
                  onChange={(value) => {
                    this.saveField("ConsDOAESS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRSS"
                  key="ConsFOBLRSS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRSS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRSS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRSS"
                  key="ConsDOBLRSS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRSS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRSS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRSS !== undefined}
                />
              </Grid>

            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={3}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ConsCylSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylSS"
                  key="ConsCylSS"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylSS}
                  onChange={(value) => {
                    this.saveField("ConsCylSS", value);
                  }}
                  error={this.state.validateStatus.ConsCylSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysSS"
                  key="ConsSysSS"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysSS}
                  onChange={(value) => {
                    this.saveField("ConsSysSS", value);
                  }}
                  error={this.state.validateStatus.ConsSysSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAESS"
                  key="ConsAESS"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAESS}
                  onChange={(value) => {
                    this.saveField("ConsAESS", value);
                  }}
                  error={this.state.validateStatus.ConsAESS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydSS"
                  key="ConsHydSS"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydSS}
                  onChange={(value) => {
                    this.saveField("ConsHydSS", value);
                  }}
                  error={this.state.validateStatus.ConsHydSS !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureSS"
                  key="FWPureSS"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.FWPureSS}
                  onChange={(value) => {
                    this.saveField("FWPureSS", value);
                  }}
                  error={this.state.validateStatus.FWPureSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ConsFOMEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMEFS"
                  key="ConsFOMEFS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOMEFS}
                  onChange={(value) => {
                    this.saveField("ConsFOMEFS", value);
                  }}
                  error={this.state.validateStatus.ConsFOMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMEFS"
                  key="ConsDOMEFS"
                  fullWidth
                  placeholder="ME/DO"
                  value={this.state.after.ConsDOMEFS}
                  onChange={(value) => {
                    this.saveField("ConsDOMEFS", value);
                  }}
                  error={this.state.validateStatus.ConsDOMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOAEFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAEFS"
                  key="ConsFOAEFS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAEFS}
                  onChange={(value) => {
                    this.saveField("ConsFOAEFS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAEFS"
                  key="ConsDOAEFS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAEFS}
                  onChange={(value) => {
                    this.saveField("ConsDOAEFS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRFS"
                  key="ConsFOBLRFS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRFS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRFS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRFS"
                  key="ConsDOBLRFS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRFS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRFS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRFS !== undefined}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.ConsCylFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylFS"
                  key="ConsCylFS"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylFS}
                  onChange={(value) => {
                    this.saveField("ConsCylFS", value);
                  }}
                  error={this.state.validateStatus.ConsCylFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysFS"
                  key="ConsSysFS"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysFS}
                  onChange={(value) => {
                    this.saveField("ConsSysFS", value);
                  }}
                  error={this.state.validateStatus.ConsSysFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAEFS"
                  key="ConsAEFS"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAEFS}
                  onChange={(value) => {
                    this.saveField("ConsAEFS", value);
                  }}
                  error={this.state.validateStatus.ConsAEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydFS"
                  key="ConsHydFS"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydFS}
                  onChange={(value) => {
                    this.saveField("ConsHydFS", value);
                  }}
                  error={this.state.validateStatus.ConsHydFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureFS"
                  key="FWPureFS"
                  fullWidth
                  placeholder="FW"
                  value={this.state.after.FWPureFS}
                  onChange={(value) => {
                    this.saveField("FWPureFS", value);
                  }}
                  error={this.state.validateStatus.FWPureFS !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel>{fieldLabels.RunhAE1SS}</FormLabel>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1SS"
                  key="RunhAE1SS"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1SS}
                  onChange={(value) => {
                    this.saveField("RunhAE1SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2SS"
                  key="RunhAE2SS"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2SS}
                  onChange={(value) => {
                    this.saveField("RunhAE2SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2SS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3SS"
                  key="RunhAE3SS"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3SS}
                  onChange={(value) => {
                    this.saveField("RunhAE3SS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3SS !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGSS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGSS"
                  key="RunhIGSS"
                  fullWidth
                  value={this.state.after.RunhIGSS}
                  onChange={(value) => {
                    this.saveField("RunhIGSS", value);
                  }}
                  error={this.state.validateStatus.RunhIGSS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormLabel>{fieldLabels.RunhAE1FS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMEFS"
                  key="RunhMEFS"
                  fullWidth
                  placeholder="ME"
                  value={this.state.after.RunhMEFS}
                  onChange={(value) => {
                    this.saveField("RunhMEFS", value);
                  }}
                  error={this.state.validateStatus.RunhMEFS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1FS"
                  key="RunhAE1FS"
                  fullWidth
                  placeholder="AE1"
                  value={this.state.after.RunhAE1FS}
                  onChange={(value) => {
                    this.saveField("RunhAE1FS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2FS"
                  key="RunhAE2FS"
                  fullWidth
                  placeholder="AE2"
                  value={this.state.after.RunhAE2FS}
                  onChange={(value) => {
                    this.saveField("RunhAE2FS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2FS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3FS"
                  key="RunhAE3FS"
                  fullWidth
                  placeholder="AE3"
                  value={this.state.after.RunhAE3FS}
                  onChange={(value) => {
                    this.saveField("RunhAE3FS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3FS !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                <FormLabel>{fieldLabels.RunhIGFS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGFS"
                  key="RunhIGFS"
                  fullWidth
                  value={this.state.after.RunhIGFS}
                  onChange={(value) => {
                    this.saveField("RunhIGFS", value);
                  }}
                  error={this.state.validateStatus.RunhIGFS !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CEShiftingReportEditDialog
);
