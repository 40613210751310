/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  Menu,
  MenuItem,
  getDrawerUtilityClass,
  RadioGroup,
  Radio,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
  Stack,
  AppBar,
  Switch,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectFilter from "react-select";
import AsyncSelect from "react-select/async";
import { filterStyles, showError } from "../components/common";
import moment from "moment";
import SimpleDialog from "../components/SimpleDialog";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
const validator = require("../data/DangKy_Validator");
const fields = ["TenTau", "TenCang_Tu", "LoaiTau"];

const styles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  quantrong2: {
    fontWeight: "bold !important",
    lineHeight: 3,
  },
  colorBlue: {
    color: "blue",
  },
});

const englishUI = window.location.href.indexOf("english") >= 0;
const default_FormConfig = {
  Title: "Dữ liệu",
  TitleEN: "Data",
  GridLayoutCount: 24,
  EditFields: [],
};
const filterDanhSach = (danhsach, fieldName, inputValue, callback) => {
  inputValue = inputValue ? inputValue.toUpperCase() : "";
  var i = 0;
  var count = 0;
  var list = [];
  for (; i < danhsach.length && count < 10; i++) {
    var item = danhsach[i];
    if (
      inputValue === "" ||
      item[fieldName].toUpperCase().indexOf(inputValue) >= 0
    ) {
      list.push(item);
      count++;
    }
  }
  callback(list);
};
function createEditField(field, props, state, saveField, saveData) {
  const danhsach = myLib.isEmpty(field.SourceName)
    ? []
    : state.DanhMuc[field.SourceName]
    ? state.DanhMuc[field.SourceName]
    : [];
  return (
    <>
      {field.LabelWidth ? (
        <Grid item xs={field.LabelWidth}>
          <FormLabel>{englishUI ? field.LabelEN : field.Label}</FormLabel>
        </Grid>
      ) : null}
      {myLib.isEmpty(field.FieldName) ? null : (
        <Grid item xs={field.InputWidth}>
          {!myLib.isEmpty(field.SourceName) ? (
            danhsach.length < 100 ? (
              <SelectFilter
                id={field.FieldName}
                key={field.FieldName}
                fullWidth
                autoFocus={field.AutoFocus}
                options={danhsach}
                getOptionLabel={(item) => item[field.SourceLabel]}
                getOptionValue={(item) => item[field.SourceValue]}
                placeholder=""
                styles={filterStyles}
                menuPosition={"fixed"}
                value={state.after[field.FieldName]}
                isMulti={field.Compare.indexOf("in") >= 0}
                isClearable
                onChange={(item) => {
                  if (field.Compare.indexOf("in") >= 0) {
                    saveField(field.FieldName, item ? item : []);
                  } else {
                    saveField(field.FieldName, item ? item : undefined);
                  }
                }}
              ></SelectFilter>
            ) : (
              <AsyncSelect
                id={field.FieldName}
                key={field.FieldName}
                fullWidth
                cacheOptions
                defaultOptions
                isClearable
                placeholder=""
                styles={filterStyles}
                menuPosition={"fixed"}
                getOptionLabel={(item) => item[field.SourceLabel]}
                getOptionValue={(item) => item[field.SourceValue]}
                value={danhsach}
                isMulti={field.Compare.indexOf("in") >= 0}
                loadOptions={(i, c) =>
                  filterDanhSach(
                    state.DanhMuc[field.SourceName],
                    field.SourceLabel,
                    i,
                    c
                  )
                }
                onChange={(item) => {
                  if (field.Compare.indexOf("in") >= 0) {
                    saveField(field.FieldName, item ? item : []);
                  } else {
                    saveField(field.FieldName, item ? item : undefined);
                  }
                }}
              ></AsyncSelect>
            )
          ) : field.DataType == "string" ? (
            <OutlinedInput
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              readOnly={field.ReadOnly}
              autoFocus={field.AutoFocus}
              placeholder={ englishUI ? field.PlaceHolderEN : field.PlaceHolder}
              value={state.after[field.FieldName]}
              onChange={(event) => {
                saveField(field.FieldName, event.target.value);
              }}
            />
          ) : field.DataType == "date" ? (
            <MyDateEditor
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              readOnly={field.ReadOnly}
              autoFocus={field.AutoFocus}
              value={state.after[field.FieldName]}
              onChange={(value) => {
                saveField(field.FieldName, value);
              }}
            />
          ) : field.DataType == "datetime" ? (
            <MyDateTimeEditor
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              readOnly={field.ReadOnly}
              autoFocus={field.AutoFocus}
              value={state.after[field.FieldName]}
              onChange={(value) => {
                saveField(field.FieldName, value);
              }}
            />
          ) : field.DataType == "bit" ? (
            <FormControlLabel
              id={field.FieldName}
              key={field.FieldName}
              label={""}
              labelPlacement="end"
              fullWidth
              control={
                <Checkbox
                  size="medium"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                  checked={state.after[field.FieldName]}
                  onChange={(event) => {
                    saveField(field.FieldName, event.target.checked);
                  }}
                />
              }
            />
          ) : field.DataType == "int" ? (
            <MyNumberEditor
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              decimalScale={0}
              readOnly={field.ReadOnly}
              value={state.after[field.FieldName]}
              onValueChange={(value) => saveField(field.FieldName, value)}
            />
          ) : field.DataType == "decimal" ? (
            <MyNumberEditor
              id={field.FieldName}
              key={field.FieldName}
              fullWidth
              decimalScale={2}
              readOnly={field.ReadOnly}
              value={state.after[field.FieldName]}
              onValueChange={(value) => saveField(field.FieldName, value)}
            />
          ) : field.DataType == "lat" ? (
            <MyLatLonEditor
              id={field.FieldName}
              key={field.FieldName}
              latlon={field.DataType}
              fullWidth
              readOnly={field.ReadOnly}
              value={state.after[field.FieldName]}
              onValueChange={(value) => saveField(field.FieldName, value)}
            />
          ) : field.DataType == "lon" ? (
            <MyLatLonEditor
              id={field.FieldName}
              key={field.FieldName}
              latlon={field.DataType}
              fullWidth
              readOnly={field.ReadOnly}
              value={state.after[field.FieldName]}
              onValueChange={(value) => saveField(field.FieldName, value)}
            />
          ) : null}
        </Grid>
      )}
      {field.EmptySpace ? <Grid item xs={field.EmptySpace}></Grid> : null}
    </>
  );
}
//------------------------------------------------------------------------
class DataEditDialog extends React.Component {
  constructor(props) {
    super(props);
    var now = new Date();

    this.state = {
      //thong tin kahi bao ve form
      FormName: props.FormName,
      FormConfig: default_FormConfig,
      validateStatus: { error: false }, //trang thai validate
      saving: false,
      DanhMuc: {},
      selectedField: "", //dang lua chon truong nao
      rowSelected: undefined,
      after: {
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }
  componentDidMount() {
    this.loadForm(true);
  }
  loadForm(force) {
    //load cau hinh form
    if (force || this.state.FormConfig.DataSetName == undefined) {
      //chua co thi tien hanh load tu may chu
      server
        .query("Data/GetFormConfig", {
          FormName: this.state.FormName,
        })
        .then((response) => {
          //lay danh sach danh muc can thiet lap
          var danhsach = "";
          var after = this.state.after; //gan them gia tri after
          response.EditFields.map((field) => {
            if (!myLib.isEmpty(field.SourceName)) {
              danhsach += (danhsach != "" ? "," : "") + field.SourceName;
            }
            //gan gia tri tieu chi tim kiem
            after[field.FieldName] = field.DefaultValue;
          });
          //tao lai grid theo du lieu nhan duoc
          if (danhsach != "") {
            this.loadDanhMuc(danhsach, () =>
              this.setState({ FormConfig: response })
            );
          } else {
            this.setState({ FormConfig: response });
          }
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    server
      .post("Data/DoRequest", {
        Function: "Proc_Table_GetAll",
        ThamSo: {
          TableNames: danhsach,
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    //vi tri den' trung voi vi tri hien tai
    s.after.MaCang_Den = s.after.MaCang;
    s.after.MaVT_Den = s.after.MaVT;
    s.after.ViTri_Den = s.after.ViTri;
    s.dataChanged = true;

    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = { after: this.state.after };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close();
    }
  };
  render() {
    const { classes, theme } = this.props;
    var stt = 1;
    console.log(this.state);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {englishUI
                ? this.state.FormConfig.TitleEN
                : this.state.FormConfig.Title}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              <Button
                color="success"
                variant="contained"
                onClick={() => this.loadForm()}
              >
                {englishUI ? "Reload" : "Nạp lại"}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={this.doSave}
              >
                {englishUI ? "Save" : "Lưu"}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {englishUI ? "Close" : "Thoát"}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid
              container
              spacing={1}
              columns={this.state.FormConfig.GridLayoutCount}
            >
              {this.state.FormConfig.EditFields.map((field) =>
                createEditField(
                  field,
                  this.props,
                  this.state,
                  this.saveField,
                  this.saveData
                )
              )}
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close();
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(DataEditDialog);
