import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI
  ? "Noon At Sea Report"
  : "Báo cáo buổi trưa trên biển";
const fieldLabels = englishUI
  ? {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RpmMENS: "RPM ME",
    BCANS: "Pr",
    LoadNS: "Hand load",
    TminNS: "Tmin",
    TmaxNS: "Tmax",
    PaMENS: "Pa",
    AirMENS: "Air drop",
    TerNS: "Ter",
    SlipNS: "Slip",
    RunhMENS: "Rnh: ME",
    RunhAENS: "AE:",
    RunhAE1NS: "AE1",
    RunhAE2NS: "AE2",
    RunhAE3NS: "AE3",
    RunhIGNS: "IG",
    RpmCounterMENS: "M/E RPM CTR",
    FlowMENS: "ME FM",
    FlowAENS: "AE FM",
    FlowBLRNS: "BLR FM",
    Flow3NS: "ALL FM",
    FlowTempNS: "T. FM",
    METempNS: "T. ME",
    ConsFOMENS: "ME(FO/DO)",
    ConsDOMENS: "",
    ConsFOAENS: "AE(FO/DO)",
    ConsDOAENS: "",
    ConsFOBLRNS: "BLR(FO/DO)",
    ConsDOBLRNS: "",
    ConsCylNS: "LO(Cyl/Sys/AE/Hyd)",
    ConsSysNS: "",
    ConsAENS: "",
    ConsHydNS: "",
    FWPureNS: "FW Pur",
    RobFONS: "ROB: FO",
    RobDONS: "DO",
    RobFWNS: "FW",
    Floating: "Floating"
  }
  : {
    VoyageCode: "REF",
    ReportTypeID: "ReportTypeID",
    ReportTime: "Time",
    RpmMENS: "RPM ME",
    BCANS: "Pr",
    LoadNS: "Hand load",
    TminNS: "Tmin",
    TmaxNS: "Tmax",
    PaMENS: "Pa",
    AirMENS: "Air drop",
    TerNS: "Ter",
    SlipNS: "Slip",
    RunhMENS: "Rnh: ME",
    RunhAENS: "AE:",
    RunhAE1NS: "AE1",
    RunhAE2NS: "AE2",
    RunhAE3NS: "AE3",
    RunhIGNS: "IG",
    RpmCounterMENS: "M/E RPM CTR",
    FlowMENS: "ME FM",
    FlowAENS: "AE FM",
    FlowBLRNS: "BLR FM",
    Flow3NS: "ALL FM",
    FlowTempNS: "T. FM",
    METempNS: "T. ME",
    ConsFOMENS: "ME(FO/DO)",
    ConsDOMENS: "",
    ConsFOAENS: "AE(FO/DO)",
    ConsDOAENS: "",
    ConsFOBLRNS: "BLR(FO/DO)",
    ConsDOBLRNS: "",
    ConsCylNS: "LO(Cyl/Sys/AE/Hyd)",
    ConsSysNS: "",
    ConsAENS: "",
    ConsHydNS: "",
    FWPureNS: "FW Pur",
    RobFONS: "ROB: FO",
    RobDONS: "DO",
    RobFWNS: "FW",
    Floating: "Floating"
  };
const buttonLabels = englishUI
  ? {
    btnLoadRandomSample: "Random sample",
    btnSave: "Save",
    btnSend: "Send",
    btnClose: "Close",
  }
  : {
    btnLoadRandomSample: "Random sample",
    btnSave: "Lưu",
    btnSend: "Gửi",
    btnClose: "Thoát",
  };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, []);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

const TimeAdjusmentTypes = [
  {
    value: 0,
    label: "NIL",
  },
  {
    value: -1,
    label: "Retard",
  },
  {
    value: 1,
    label: "Add",
  },
];


/*------------------------------------FORM--------------------------------------*/
class CENoonAtSeaReportEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      validateStatus: { error: false }, //trang thai validate
      after: {
        DailyReportID: 0,
        VoyageCode: null,
        ReportTypeID: 2,
        ReportTime: null,
        RpmMENS: null,
        BCANS: null,
        LoadNS: null,
        TminNS: null,
        TmaxNS: null,
        PaMENS: null,
        AirMENS: null,
        TerNS: null,
        SlipNS: null,
        RunhMENS: null,
        RunhAE1NS: null,
        RunhAE2NS: null,
        RunhAE3NS: null,
        RunhIGNS: null,
        RpmCounterMENS: null,
        FlowMENS: null,
        FlowAENS: null,
        FlowBLRNS: null,
        Flow3NS: null,
        FlowTempNS: null,
        ConsFOMENS: null,
        ConsDOMENS: null,
        ConsFOAENS: null,
        ConsDOAENS: null,
        ConsFOBLRNS: null,
        ConsDOBLRNS: null,
        ConsCylNS: null,
        ConsSysNS: null,
        ConsAENS: null,
        FWPure: null,
        RobFONS: null,
        RobDONS: null,
        RobFWNS: null,
        Floating: null,
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      reportStatus: props.reportStatus,
    };
  }

  componentDidMount() {
    this.loadRandomSample();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  //load du lieu randomm
  loadRandomSample(demo) {
    //load danh muc
    if (this.props.edittingRecord == undefined) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Test_RandomReport",
          ThamSo: {
            ReportTypeID: 2,
            Demo: demo,
          },
        })
        .then((response) => {
          var report = JSON.parse(response.ReportList[0].ReportContent);
          report.ReportID = response.ReportList[0].ReportID;
          report.ReportTypeID = 2; // noon at sea report
          report.DailyReportID = 0; // 0 = create new, not 0: update
          //report.TimeChanged = ParseTimeAdjusmentType(report.TimeChanged);
          report.VoyageCode = myLib.isEmpty(this.props.defaultVoyageCode)
            ? report.VoyageCode
            : this.props.defaultVoyageCode;
          this.setState({
            after: report,
            dataChanged: true,
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  saveField = (fieldName, value) => {
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doUpload() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_OS_DailyReport_Upload",
        ThamSo: {
          DailyReportID: this.state.after.DailyReportID,
        },
      })
      .then(() => { })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.close) {
      this.props.close(this.state.dataSaved);
    }
  }
  doSave = (reportStatus) => {
    try {
      var check = validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_OS_DailyReport_Update",
            ThamSo: {
              ReportID: this.state.after.ReportID,
              DailyReportID: this.state.after.DailyReportID,
              IsEngine: 0, //Captain
              VoyageCode: this.state.after.VoyageCode,
              ReportTypeID: this.state.after.ReportTypeID,
              ReportTime: this.state.after.ReportTime,
              ReportDataTime: this.state.after.TimeNS,
              ReportStatus: reportStatus,
              ReportContent: JSON.stringify(this.state.after),
              AttachedReportID: null, // TODO: select bunker port
              ParentReportID:
                this.props.edittingRecord !== undefined
                  ? this.props.edittingRecord.ParentReportID
                  : null, // when replace an existing report
            },
          })
          .then((response) => {
            this.state.after.DailyReportID =
              response.OS_DailyReport[0].DailyReportID;
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
                reportStatus: reportStatus,
              },
              () => {
                //upload
                if (reportStatus == 1) {
                  this.doUpload();
                } else {
                  alert("Cập nhật thành công!");
                }
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };
  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    console.log(fieldLabels.VoyageCode);
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"lg"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {myLib.isLocalhost ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => this.loadRandomSample(true)}
                >
                  {buttonLabels.btnLoadRandomSample}
                </Button>
              ) : null}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave(0)}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={
                  this.state.after.DailyReportID == 0 || this.state.saving
                }
                onClick={() => this.doSave(1)}
              >
                {buttonLabels.btnSend}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="VoyageCode"
                  key="VoyageCode"
                  fullWidth
                  placeholder=""
                  readOnly
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField("VoyageCode", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ReportTime}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateTimeEditor
                  id="ReportTime"
                  key="ReportTime"
                  fullWidth
                  autoFocus
                  value={this.state.after.ReportTime}
                  onChange={(value) => {
                    this.saveField("ReportTime", value);
                  }}
                  error={this.state.validateStatus.ReportTime !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>D-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmMENS"
                  key="RpmMENS"
                  fullWidth
                  value={this.state.after.RpmMENS}
                  onChange={(value) => {
                    this.saveField("RpmMENS", value);
                  }}
                  error={this.state.validateStatus.RpmMENS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.BCANS}</FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  id="BCANS"
                  key="BCANS"
                  fullWidth
                  value={this.state.after.BCANS}
                  onChange={(value) => {
                    this.saveField("BCANS", value);
                  }}
                  error={this.state.validateStatus.BCANS !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.LoadNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="LoadNS"
                  key="LoadNS"
                  fullWidth
                  value={this.state.after.LoadNS}
                  onChange={(value) => {
                    this.saveField("LoadNS", value);
                  }}
                  error={this.state.validateStatus.LoadNS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TmaxNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TmaxNS"
                  key="TmaxNS"
                  fullWidth
                  value={this.state.after.TmaxNS}
                  onChange={(value) => {
                    this.saveField("TmaxNS", value);
                  }}
                  error={this.state.validateStatus.TmaxNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TminNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TminNS"
                  key="TminNS"
                  fullWidth
                  value={this.state.after.TminNS}
                  onChange={(value) => {
                    this.saveField("TminNS", value);
                  }}
                  error={this.state.validateStatus.TminNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.PaMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="PaMENS"
                  key="PaMENS"
                  fullWidth
                  value={this.state.after.PaMENS}
                  onChange={(value) => {
                    this.saveField("PaMENS", value);
                  }}
                  error={this.state.validateStatus.PaMENS !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.AirMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="AirMENS"
                  key="AirMENS"
                  fullWidth
                  value={this.state.after.AirMENS}
                  onChange={(value) => {
                    this.saveField("AirMENS", value);
                  }}
                  error={this.state.validateStatus.AirMENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.TerNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="TerNS"
                  key="TerNS"
                  fullWidth
                  value={this.state.after.TerNS}
                  onChange={(value) => {
                    this.saveField("TerNS", value);
                  }}
                  error={this.state.validateStatus.TerNS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.SlipNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="SlipNS"
                  key="SlipNS"
                  fullWidth
                  value={this.state.after.SlipNS}
                  onChange={(value) => {
                    this.saveField("SlipNS", value);
                  }}
                  error={this.state.validateStatus.SlipNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>E-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhMENS"
                  key="RunhMENS"
                  fullWidth
                  value={this.state.after.RunhMENS}
                  onChange={(value) => {
                    this.saveField("RunhMENS", value);
                  }}
                  error={this.state.validateStatus.RunhMENS !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RunhAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE1NS"
                  key="RunhAE1NS"
                  fullWidth
                  placeholder={fieldLabels.RunhAE1NS}
                  value={this.state.after.RunhAE1NS}
                  onChange={(value) => {
                    this.saveField("RunhAE1NS", value);
                  }}
                  error={this.state.validateStatus.RunhAE1NS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE2NS"
                  key="RunhAE2NS"
                  fullWidth
                  placeholder={fieldLabels.RunhAE2NS}
                  value={this.state.after.RunhAE2NS}
                  onChange={(value) => {
                    this.saveField("RunhAE2NS", value);
                  }}
                  error={this.state.validateStatus.RunhAE2NS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhAE3NS"
                  key="RunhAE3NS"
                  fullWidth
                  placeholder={fieldLabels.RunhAE3NS}
                  value={this.state.after.RunhAE3NS}
                  onChange={(value) => {
                    this.saveField("RunhAE3NS", value);
                  }}
                  error={this.state.validateStatus.RunhAE3NS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RunhIGNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RunhIGNS"
                  key="RunhIGNS"
                  fullWidth
                  value={this.state.after.RunhIGNS}
                  onChange={(value) => {
                    this.saveField("RunhIGNS", value);
                  }}
                  error={this.state.validateStatus.RunhIGNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>F-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RpmCounterMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RpmCounterMENS"
                  key="RpmCounterMENS"
                  fullWidth
                  value={this.state.after.RpmCounterMENS}
                  onChange={(value) => {
                    this.saveField("RpmCounterMENS", value);
                  }}
                  error={this.state.validateStatus.RpmCounterMENS !== undefined}
                />
              </Grid>

              <Grid item xs={1}>
                <FormLabel>{fieldLabels.FlowMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowMENS"
                  key="FlowMENS"
                  fullWidth
                  value={this.state.after.FlowMENS}
                  onChange={(value) => {
                    this.saveField("FlowMENS", value);
                  }}
                  error={this.state.validateStatus.FlowMENS !== undefined}
                />
              </Grid>

              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowAENS"
                  key="FlowAENS"
                  fullWidth
                  value={this.state.after.FlowAENS}
                  onChange={(value) => {
                    this.saveField("FlowAENS", value);
                  }}
                  error={this.state.validateStatus.FlowAENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FlowBLRNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowBLRNS"
                  key="FlowBLRNS"
                  fullWidth
                  value={this.state.after.FlowBLRNS}
                  onChange={(value) => {
                    this.saveField("FlowBLRNS", value);
                  }}
                  error={this.state.validateStatus.FlowBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Flow3NS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="Flow3NS"
                  key="Flow3NS"
                  fullWidth
                  value={this.state.after.Flow3NS}
                  onChange={(value) => {
                    this.saveField("Flow3NS", value);
                  }}
                  error={this.state.validateStatus.Flow3NS !== undefined}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.FlowTempNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FlowTempNS"
                  key="FlowTempNS"
                  fullWidth
                  value={this.state.after.FlowTempNS}
                  onChange={(value) => {
                    this.saveField("FlowTempNS", value);
                  }}
                  error={this.state.validateStatus.FlowTempNS !== undefined}
                />
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.METempNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="METempNS"
                  key="METempNS"
                  fullWidth
                  value={this.state.after.METempNS}
                  onChange={(value) => {
                    this.saveField("METempNS", value);
                  }}
                  error={this.state.validateStatus.METempNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOMENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOMENS"
                  key="ConsFOMENS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOMENS}
                  onChange={(value) => {
                    this.saveField("ConsFOMENS", value);
                  }}
                  error={this.state.validateStatus.ConsFOMENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOMENS"
                  key="ConsDOMENS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOMENS}
                  onChange={(value) => {
                    this.saveField("ConsDOMENS", value);
                  }}
                  error={this.state.validateStatus.ConsDOMENS !== undefined}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsFOAENS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOAENS"
                  key="ConsFOAENS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOAENS}
                  onChange={(value) => {
                    this.saveField("ConsFOAENS", value);
                  }}
                  error={this.state.validateStatus.ConsFOAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOAENS"
                  key="ConsDOAENS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOAENS}
                  onChange={(value) => {
                    this.saveField("ConsDOAENS", value);
                  }}
                  error={this.state.validateStatus.ConsDOAENS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.ConsFOBLRNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsFOBLRNS"
                  key="ConsFOBLRNS"
                  fullWidth
                  placeholder="FO"
                  value={this.state.after.ConsFOBLRNS}
                  onChange={(value) => {
                    this.saveField("ConsFOBLRNS", value);
                  }}
                  error={this.state.validateStatus.ConsFOBLRNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsDOBLRNS"
                  key="ConsDOBLRNS"
                  fullWidth
                  placeholder="DO"
                  value={this.state.after.ConsDOBLRNS}
                  onChange={(value) => {
                    this.saveField("ConsDOBLRNS", value);
                  }}
                  error={this.state.validateStatus.ConsDOBLRNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.ConsCylNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsCylNS"
                  key="ConsCylNS"
                  fullWidth
                  placeholder="Cyl"
                  value={this.state.after.ConsCylNS}
                  onChange={(value) => {
                    this.saveField("ConsCylNS", value);
                  }}
                  error={this.state.validateStatus.ConsCylNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsSysNS"
                  key="ConsSysNS"
                  fullWidth
                  placeholder="Sys"
                  value={this.state.after.ConsSysNS}
                  onChange={(value) => {
                    this.saveField("ConsSysNS", value);
                  }}
                  error={this.state.validateStatus.ConsSysNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsAENS"
                  key="ConsAENS"
                  fullWidth
                  placeholder="AE"
                  value={this.state.after.ConsAENS}
                  onChange={(value) => {
                    this.saveField("ConsAENS", value);
                  }}
                  error={this.state.validateStatus.ConsAENS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="ConsHydNS"
                  key="ConsHydNS"
                  fullWidth
                  placeholder="Hyd"
                  value={this.state.after.ConsHydNS}
                  onChange={(value) => {
                    this.saveField("ConsHydNS", value);
                  }}
                  error={this.state.validateStatus.ConsHydNS !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.FWPureNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="FWPureNS"
                  key="FWPureNS"
                  fullWidth
                  value={this.state.after.FWPureNS}
                  onChange={(value) => {
                    this.saveField("FWPureNS", value);
                  }}
                  error={this.state.validateStatus.FWPureNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>G-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.RobFONS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFONS"
                  key="RobFONS"
                  fullWidth
                  value={this.state.after.RobFONS}
                  onChange={(value) => {
                    this.saveField("RobFONS", value);
                  }}
                  error={this.state.validateStatus.RobFONS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobDONS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobDONS"
                  key="RobDONS"
                  fullWidth
                  value={this.state.after.RobDONS}
                  onChange={(value) => {
                    this.saveField("RobDONS", value);
                  }}
                  error={this.state.validateStatus.RobDONS !== undefined}
                />
              </Grid>

              <Grid item xs={2}>
                <FormLabel>{fieldLabels.RobFWNS}</FormLabel>
              </Grid>
              <Grid item xs={2}>
                <MyNumberEditor
                  id="RobFWNS"
                  key="RobFWNS"
                  fullWidth
                  value={this.state.after.RobFWNS}
                  onChange={(value) => {
                    this.saveField("RobFWNS", value);
                  }}
                  error={this.state.validateStatus.RobFWNS !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={24}>
              <Grid item xs={1}>
                <FormLabel>I-</FormLabel>
              </Grid>
              <Grid item xs={3}>
                <FormLabel>{fieldLabels.Floating}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="Floating"
                  key="Floating"
                  fullWidth
                  multiline
                  placeholder=""
                  value={this.state.after.Floating}
                  onChange={(event) => {
                    this.saveField("Floating", event.target.value);
                  }}
                  error={this.state.validateStatus.Floating !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  CENoonAtSeaReportEditDialog
);
