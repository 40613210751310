import {
  CheckBox,
  CheckBoxOutlineBlank,
  Explicit,
  Language,
} from "@mui/icons-material";
import { Button, FormLabel, Grid, Paper, TextField } from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError,
  showSuccess,
} from "../../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../../components/ButtonTimKiem";
import MyDateEditor from "../../components/MyDateEditor";
import MySelectFilter from "../../components/MySelectFilter";
import SimpleDialog from "../../components/SimpleDialog";
import RepairRequisitionEditDialog from "./RepairRequisitionEditDialog";
import MenuButtons from "../../components/MenuButtons";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskIcon from "@mui/icons-material/Task";
import uuid from "react-uuid";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
      MaYeuCau: "Repair Requisition:",
      NgayYeuCau1: "Created date:",
      IsKetThuc: "Completed:",
    }
  : {
      MaYeuCau: "YCSC:",
      NgayYeuCau1: "Ngày yêu cầu:",
      IsKetThuc: "Đã kết thúc:",
    };

const buttonLabels = englishUI
  ? {
      btnTimKiem: "Search",
      btnThem: "Add",
      btnEdit: "Edit",
      btnDelete: "Delete",
      btnReplace: "Replace",
      btnSend: "Send",
      btnMark: "Cancel",
      btnMarkSend: "Done",
      btnEmail: "Email",
      btnImport: "Import",
      btnStatus: "Update status",
      btnTemplate: "File Template",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnThem: "Thêm",
      btnEdit: "Sửa",
      btnDelete: "Xóa",
      btnReplace: "Thay thế",
      btnSend: "Gửi",
      btnMark: "Cancel",
      btnEmail: "Email",
      btnImport: "Nhập",
      btnStatus: "Trạng thái",
      btnTemplate: "File Template",
    };
const buttonUpdateStatuses = [
  {
    ID: 0,
    Label: "Nháp",
    LabelEN: "Draft",
    Icon: <TextSnippetIcon />,
  },
  {
    ID: 1,
    Label: "Đã gửi",
    LabelEN: "Reported",
    Icon: <MarkEmailReadIcon />,
  },
  {
    ID: 2,
    Label: "Đang XL",
    LabelEN: "Doing",
    Icon: <EngineeringIcon />,
  },
  {
    ID: 3,
    Label: "Hủy",
    LabelEN: "Cancelled",
    Icon: <DeleteIcon />,
  },
  {
    ID: 4,
    Label: "Đã xong",
    LabelEN: "Finished",
    Icon: <TaskIcon />,
  },
];
const buttonExports = [
  {
    ID: "TiengViet",
    Label: "Tiếng Việt",
    LabelEN: "Tiếng Việt",
    Icon: <Language fontSize="small" />,
  },
  {
    ID: "TiengAnh",
    Label: "English",
    LabelEN: "English",
    Icon: <Explicit fontSize="small" />,
  },
];
/*--------
---------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "MaYeuCau",
    type: "string",
    headerAlign: "center",
    defaultWidth: 170,
    header: englishUI ? "Repair Requisition" : "YCSC",
    render: ({ data }) => data.MaYeuCau,
  },
  {
    name: "TenBoPhan",
    type: "string",
    defaultWidth: 110,
    headerAlign: "center",
    header: englishUI ? "Department" : "Bộ phận",
  },
  {
    name: "NgayYeuCau",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Created date" : "Ngày yêu cầu",
    render: ({ data }) =>
      data.NgayYeuCau ? moment(data.NgayYeuCau).format("DD/MM/YYYY") : null,
  },
  {
    name: "Docking",
    type: "string",
    defaultWidth: 90,
    headerAlign: "center",
    header: englishUI ? "Docking" : "Docking",
    render: ({ data }) => (data.IsDocking ? "Docking" : "Normal"),
  },
  {
    name: "TenTrangThai",
    type: "string",
    defaultWidth: 90,
    headerAlign: "center",
    header: englishUI ? "Status" : "Trạng thái",
    render: ({ data }) => data.TenTrangThai,
  },
  {
    name: "GhiChu",
    type: "string",
    headerAlign: "center",
    defaultWidth: 190,

    header: englishUI ? "Remark" : "Ghi chú",
    render: ({ data }) => (
      <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {data.GhiChu}
      </div>
    ),
  },
  {
    name: "NguoiDeNghi",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Created by" : "Người đề nghị",
    render: ({ data }) => data.NguoiDeNghi,
  },
  {
    name: "ThuyenTruong",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Captain" : "Thuyền trưởng",
    render: ({ data }) => data.ThuyenTruong,
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    defaultWidth: 150,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "NgayYeuCau", dir: 0, type: "date" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class RepairRequisitionPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [
          { value: 0, label: "No" },
          { value: 1, label: "Yes" },
        ],
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 25,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
              //danh sach cac tieu chi tim kiem
              MaYeuCau: props.ShipData.ShipCode,
              NgayYeuCau1: null,
              NgayYeuCau2: null,
              IsKetThuc: 0,
              
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy" &&
      fieldName !== "IsKetThuc"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(props, state) {}
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_YeuCauSC_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      rowSelected: null,
    });
  };

  loadPreview() {}

  edit() {
    server
      .post("Data/DoRequest", {
        Function: "Proc_CP_YeuCauSC_Get",
        ThamSo: {
          YeuCauID: this.state.rowSelected.YeuCauID,
        },
      })
      .then((response) => {
        let data = {
          edittingRecord: {
            ...response.CP_YeuCauSC[0],
            RepairJobs: response.CP_YeuCauSC_ND,
          },
          open_edit: true,
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }
  doUpdateState(trangthai) {
    //thuc hien cap nhat trang thai
    server
      .post("Data/DoRequest", {
        Function: "Proc_CP_YeuCauSC_UpdateTrangThai",
        ThamSo: {
          YeuCauID: this.state.rowSelected.YeuCauID,
          TrangThai: trangthai,
        },
      })
      .then((response) => {
        this.doSearch();
      })
      .catch((error) => {
        showError(error);
      });
  }
  doSendEmail = (language) => {
    var thamso = {
      YeuCauID: this.state.rowSelected.YeuCauID,
      Form: language,
    };
    server
      .post("ChiPhi/Outlook", thamso)
      .then((response) => {
        //done
      })
      .catch((error) => {
        showError(error);
      });
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.MaYeuCau}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                value={this.state.after.MaYeuCau}
                onChange={(e) => {
                  this.saveField("MaYeuCau", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.NgayYeuCau1}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayYeuCau1"
                key="NgayYeuCau1"
                fullWidth
                value={this.state.after.NgayYeuCau1}
                onChange={(value) => {
                  this.saveField("NgayYeuCau1", value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.IsKetThuc}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"IsKetThuc"}
                key={"IsKetThuc"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Complete}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_Complete.find(
                  (c) => c.value == this.state.after.IsKetThuc
                )}
                onChange={(item) => {
                  this.saveField("IsKetThuc", item.value);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="MaYeuCau"
              headerHeight={30}
              rowHeight={null}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" }, () =>
                    this.loadPreview()
                  );
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true,
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.edit();
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <MenuButtons
                  variant="contained"
                  fullWidth
                  title={buttonLabels.btnEmail}
                  disabled={!this.state.rowSelected}
                  SubActions={buttonExports}
                  onClick={(button) => {
                    this.doSendEmail(button.ID);
                  }}
                >
                  {buttonLabels.btnEmail}
                </MenuButtons>
              </Grid>
              <Grid item xs={4}>
                <MenuButtons
                  variant="contained"
                  fullWidth
                  title={buttonLabels.btnStatus}
                  disabled={!this.state.rowSelected}
                  SubActions={buttonUpdateStatuses}
                  onClick={(button) => {
                    this.doUpdateState(button.ID);
                  }}
                >
                  {buttonLabels.btnStatus}
                </MenuButtons>
              </Grid>
              <Grid item xs={3}>
                <MenuButtons
                  color="success"
                  englishUI={englishUI}
                  title={buttonLabels.btnTemplate}
                  SubActions={buttonExports}
                  onClick={(subButton) => {
                    window.location.href = server.getRequest(
                      `ChiPhi/GetTemplateYeuCauSC?Form=${subButton.ID}`
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />

        {this.state.open_edit ? (
          <RepairRequisitionEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={"Bạn muốn xóa: " + this.state.rowSelected.MaYeuCau + " ?"}
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("Data/DoRequest", {
                      Function: "Proc_CP_YeuCauSC_Delete",
                      ThamSo: {
                        YeuCauID: this.state.rowSelected.YeuCauID,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  RepairRequisitionPage
);
