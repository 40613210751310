import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import { parse } from "date-fns/esm";
import moment from "moment";
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const geoLib = require("../lib/geoLib");

function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateEmpty(data, ["VoyageCode"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

export default class LoggerDataDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      after: {
        Lat2: 0,
        Lon2: 0,
      },
      _localVersion: 0,
      dataChanged: false,
      validateStatus: {},
      loading: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.loadDataLogger();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  //lay so lieu tu data logger
  loadDataLogger() {
    try {
      this.setState({
        loading: true,
      });
      server
        .post("Data/DoRequest", {
          Function: "Proc_GS_LaySoLieu",
          ThamSo: {
            FromTime: this.props.Data.ThoiGian1,
            ToTime: this.props.Data.ThoiGian2,
            PickDistanceFromTime: this.props.Data.PickDistanceFromTime ?? false, //co tinh distance tu thoi diem bao cao truoc khong
          },
        })
        .then((response) => {
          var data = response.DataLogger[0];
          if (data) {
            this.setState({
              after: data,
              dataChanged: true,
              loading: false,
              _localVersion: this.state._localVersion + 1,
            });
          }
        })
        .catch((error) => {
          showError(error);
          this.setState({
            loading: false,
          });
        });
    } catch (e) {
      showError(e);
      this.setState({
        loading: false,
      });
    }
  }
  doSave = () => {
    try {
      var check = validate_Data(this.state);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_Voyage_Update",
            ThamSo: {
              VoyageCode: this.state.VoyageCode,
            },
          })
          .then((response) => {
            alert("Cập nhật thành công!");
            this.props.close(this.state.VoyageCode);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      }
    } catch (ex2) {
      showError(ex2);
    }
  };
  render() {
    let hasTime1 = this.props.Data.ThoiGian1;
    return (
      <Dialog
        open={this.props.open}
        onClose={() => {}}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Data from logger</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 450,
              minHeight: 30,
              padding: 5,
              wordWrap: "break-word",
            }}
          >
            <Grid container columns={12}>
              <Grid item xs={4}>
                <FormLabel>Time:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={
                      this.state.after.ThoiGian1
                        ? moment(this.state.after.ThoiGian1).format(
                            "HH:mm DD/MM/YYYY"
                          )
                        : ""
                    }
                  />
                ) : null}
              </Grid>

              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={
                    this.state.after.ThoiGian2
                      ? moment(this.state.after.ThoiGian2).format(
                          "HH:mm DD/MM/YYYY"
                        )
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>RPM-ME Counter:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.RpmCounter1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.RpmCounter2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>RPM-ME:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.Rpm1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.Rpm2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Flowmeter-ME:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.FlowmeterME1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.FlowmeterME2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Flowmeter-AE:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.FlowmeterAE1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.FlowmeterAE2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Flowmeter-Boiler:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.FlowmeterBLR1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.FlowmeterBLR2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Flowmeter Temp:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.FlowTemp1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.FlowTemp2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>ME-Oil Temp:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.TempOilME1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.TempOilME2}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Position:</FormLabel>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={
                    this.state.after.Lat2 != 0 && this.state.after.Lon2 != 0
                      ? geoLib.latToDMS(this.state.after.Lat2) +
                        "," +
                        geoLib.lonToDMS(this.state.after.Lon2)
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>Distance:</FormLabel>
              </Grid>
              <Grid item xs={4}>
                {hasTime1 ? (
                  <OutlinedInput
                    fullWidth
                    readOnly
                    value={this.state.after.Distance1}
                  />
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  fullWidth
                  readOnly
                  value={this.state.after.Distance2}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {this.state.loading ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              onClick={() => {
                if (!this.state.loading) {
                  this.loadDataLogger();
                }
              }}
              variant="contained"
            >
              Load
            </Button>
          )}
          <Button
            onClick={() => {
              this.props.close(this.state.after);
            }}
            variant="contained"
            disabled={!this.state.dataChanged}
          >
            OK
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
